import React, { useEffect, useRef, useState } from 'react';
import { IntusInput } from '@/shared/elements';
import { InputProps as AntdInputProps } from 'antd/lib';
import tabIcon from '@/images/tab-icon.svg';
import enterIcon from '@/images/enterIcon.svg';
import { Form, InputRef } from 'antd';
import { publish } from '@/core/events';
import {
  DIRECTIONAL_INPUT__ESCAPE,
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import {
  DistanceInput,
  EditModes,
  NumberedInput,
  UnitSystemTypes,
} from '@/models';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import {
  DirectionalInputEntity,
  getDirectionalInputValues,
  getProcessingEntity,
  setDirectionalInputValues,
} from '@/store/slices/canvasExternalElementsSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  convertInputValueToMillimeters,
  directionalInputFontSize,
} from '@/shared/helpers/directional-input';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import {
  addSpacesToThousands,
  processInputChange,
  removeSpacesFromThousands,
  sanitizeInputMetricValue,
} from '@/shared/helpers/format-data';
import { limitsValidator } from '@/shared/form/validators';
import useMouseOverCanvas from '@/shared/hooks/useMouseOverCanvas';
import { getExtrudeNode } from '@/store/slices/canvasBuildingSlice';
import { getEditMode } from '@/store/slices/canvasModesSlice';

interface DirectionalInputProps extends AntdInputProps {
  userUnitType?: UnitSystemTypes;
}

const DirectionalInput: React.FC<DirectionalInputProps> = ({
  userUnitType,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const allInputValuesToDisplay = useAppSelector(
    getDirectionalInputValues
  ).filter((v) => v.display);
  const activeValueIdx = allInputValuesToDisplay.findIndex((v) => v.active);
  const processingEntity = useAppSelector(getProcessingEntity)!;
  const [inputWrapperWidth, setInputWrapperWidth] = useState(0);
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );
  const extrudeNode = useAppSelector(getExtrudeNode);
  const ghostRef = useRef<HTMLSpanElement>(null);
  const [showEnterHint, setShowEnterHint] = useState(false);
  const editMode = useAppSelector(getEditMode);

  const convertInputValue =
    userUnitType === UnitSystemTypes.Metric ||
    processingEntity?.type === NumberedInput.Floors;

  const convertInputValueBasedOnSystem = (
    entity: DirectionalInputEntity
  ): string => {
    switch (entity?.type) {
      // For some reason marked as unused code. it is used :-)
      case DistanceInput.Distance:
      case DistanceInput.Length:
      case DistanceInput.Width:
      case DistanceInput.BuildingWidth:
      case DistanceInput.LeftEdgeDistance:
      case DistanceInput.RightEdgeDistance:
      case DistanceInput.FloorHeight:
        return userUnitType === UnitSystemTypes.Metric
          ? parseFloat(Number(entity?.value).toFixed(2)).toString() ?? 0
          : convertMillimetersToFtInch(entity.value, true);
      default:
        return entity?.value.toString();
    }
  };

  const [position, setPosition] = useState<{ x: number; y: number }>(null!);

  const inputRef = useRef<InputRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isAvailableToShowEnterHint =
    extrudeNode !== null ||
    editMode === EditModes.Cut ||
    editMode === EditModes.Split;

  useMouseOverCanvas(setPosition);

  useEffect(() => {
    const externalValue = convertInputValueBasedOnSystem(processingEntity);
    externalValue !== form.getFieldValue('directionalInput') &&
      !processingEntity?.active &&
      form.setFieldValue('directionalInput', externalValue);
    // We need to hide enter hint, when value is changed
    isAvailableToShowEnterHint && setShowEnterHint(false);
  }, [processingEntity?.value]);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab' && wrapperRef.current) {
      setShowEnterHint(false);
      setValidationMessage(null);
      // In future -> Use 'locked' prop to ignore values, that are locked to being active
      let nextActiveIdx;
      if (
        (activeValueIdx === -1 ||
          activeValueIdx === allInputValuesToDisplay.length - 1) &&
        !allInputValuesToDisplay[0].locked
      ) {
        nextActiveIdx = 0;
      } else if (!allInputValuesToDisplay[activeValueIdx + 1].locked) {
        nextActiveIdx = activeValueIdx + 1;
      } else {
        nextActiveIdx = activeValueIdx;
      }
      const directionalInputValue = addSpacesToThousands(
        convertInputValueBasedOnSystem(allInputValuesToDisplay[nextActiveIdx]),
        isImperialUnits
      );
      form.setFieldsValue({
        directionalInput: directionalInputValue,
      });
      ghostRef.current!.textContent = directionalInputValue;
      inputRef.current?.focus({ cursor: 'all' });

      if (inputRef.current?.input) {
        calculateInputWidth();
      }

      dispatch(
        setDirectionalInputValues([
          {
            ...allInputValuesToDisplay[nextActiveIdx],
            active: true,
            processing: true,
          },
        ])
      );

      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onKeyActionsWithInput = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (processingEntity.active) {
        event.preventDefault();
        event.stopPropagation();
        form
          .validateFields(['directionalInput'])
          .then(() => {
            isAvailableToShowEnterHint && setShowEnterHint(true);

            dispatch(
              setDirectionalInputValues([
                { ...processingEntity, active: false },
              ])
            );
            publish(
              DIRECTIONAL_INPUT__SET,
              convertInputValueToMillimeters(
                removeSpacesFromThousands(
                  form.getFieldValue('directionalInput')?.toString(),
                  isImperialUnits
                ),
                convertInputValue
              )
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else if (event.key === 'Escape') {
      if (processingEntity.active) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(
          setDirectionalInputValues([{ ...processingEntity, active: false }])
        );
        publish(DIRECTIONAL_INPUT__ESCAPE);
      }
    }
  };

  const calculateInputWidth = () => {
    const { paddingLeft, paddingRight, borderLeftWidth, borderRightWidth } =
      window.getComputedStyle(inputRef.current!.input!);
    const extraWidth =
      parseFloat(paddingLeft) +
      parseFloat(paddingRight) +
      parseFloat(borderLeftWidth) +
      parseFloat(borderRightWidth);
    const minInputWrapperWidth = 9;
    setInputWrapperWidth(
      Math.max(minInputWrapperWidth, ghostRef.current!.offsetWidth + extraWidth)
    );
  };

  useEffect(() => {
    if (processingEntity?.active) {
      inputRef.current!.focus({ cursor: 'all' });
      calculateInputWidth();
    } else {
      setValidationMessage(null);
    }
  }, [processingEntity?.active, processingEntity?.type]);

  useEffect(() => {
    inputRef.current?.input?.addEventListener('keydown', onKeyActionsWithInput);
    return () => {
      inputRef.current?.input?.removeEventListener(
        'keydown',
        onKeyActionsWithInput
      );
    };
  }, [inputRef.current, processingEntity]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [processingEntity]);

  const handleInputChange = () => {
    const input = inputRef.current?.input;

    if (!input) return;

    const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
      removeSpacesFromThousands(input.value, isImperialUnits),
      isImperialUnits,
      2,
      processingEntity?.type === NumberedInput.Floors
    );

    const formattedValue = processInputChange(
      input,
      isImperialUnits,
      processingEntity?.type === NumberedInput.Floors
    );

    form.setFieldValue('directionalInput', formattedValue);
    ghostRef.current!.textContent = formattedValue;

    calculateInputWidth();

    form
      .validateFields(['directionalInput'])
      .then(() => {
        dispatch(
          setDirectionalInputValues([
            {
              ...processingEntity,
              value: convertInputValueToMillimeters(
                sanitizedValueWithoutSpaces,
                convertInputValue
              ),
            },
          ])
        );

        publish(
          DIRECTIONAL_INPUT__UPDATE,
          convertInputValueToMillimeters(
            sanitizedValueWithoutSpaces,
            convertInputValue
          )
        );
        setValidationMessage(null);
      })
      .catch((e) => {
        const error = e.errorFields[0].errors[0];
        setValidationMessage(error);
      });
  };

  if (!position) return <></>;

  return (
    <div
      ref={wrapperRef}
      className={'absolute'}
      style={{
        top: position.y - 35,
        left: position.x + 17,
      }}
    >
      <div className="flex flex-col gap-1">
        <div
          className={
            'text-xs p-2.5 pb-1 rounded-lg border border-solid border-light-gray-40 shadow-toolbar ' +
            (processingEntity?.active ? 'bg-white' : 'bg-light-gray-10')
          }
        >
          <span
            ref={ghostRef}
            className={`absolute invisible pointer-events-none whitespace-pre top-0 left-0 text-[${directionalInputFontSize}px]`}
          />
          <div className="flex flex-col gap-1">
            {showEnterHint && (
              <div className="flex items-center">
                <img src={enterIcon} alt="tab icon" className="pr-1" />
                <span className="leading-5">to apply</span>
              </div>
            )}
            <div className="flex items-center mb-3">
              <img src={tabIcon} alt="tab icon" className="pr-1" />
              <span className="leading-5">to input</span>
            </div>
          </div>
          <div>
            {allInputValuesToDisplay.map((v) => (
              <div key={v.type} className="flex items-start mb-1 min-h-6">
                {v.prependIcon ? (
                  <img
                    src={v.prependIcon}
                    alt={v.type}
                    className={`pr-1 ${v.active ? 'pt-1' : ''}`}
                  />
                ) : (
                  <span className={`pr-1 ${v.active ? 'pt-1' : ''}`}>
                    {v.type}:{' '}
                  </span>
                )}
                {v.active ? (
                  <Form form={form}>
                    <Form.Item
                      name="directionalInput"
                      className="mb-0 invisible-form-item"
                      // We set with for parent element, in order to avoid jumping of input

                      style={{ width: inputWrapperWidth }}
                      rules={
                        processingEntity.min !== null &&
                        processingEntity.max !== null
                          ? [
                              limitsValidator({
                                min: processingEntity.min,
                                max: processingEntity.max,
                                isImperialUnits,
                                staticValue:
                                  processingEntity.type ===
                                  NumberedInput.Floors,
                                customValidationMessage:
                                  processingEntity.validationMessage,
                              }),
                            ]
                          : []
                      }
                    >
                      <IntusInput
                        ref={inputRef}
                        autoFocus
                        autoComplete="off"
                        onChange={handleInputChange}
                        className="flex-none"
                        {...rest}
                      />
                    </Form.Item>
                  </Form>
                ) : (
                  <span className="font-medium">
                    {addSpacesToThousands(
                      convertInputValueBasedOnSystem(v),
                      isImperialUnits
                    )}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
        {validationMessage && (
          <div className="py-0.5 box-border px-2.5 text-[11px] h-5 text-white leading-4 bg-red min-w-[153px] rounded">
            {validationMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectionalInput;
