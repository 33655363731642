import React, { useState } from 'react';
import IntusProgressBar from '@/shared/elements/ProgressBar/ProgressBar';
import { QuoteProcessingIcon } from '@/shared/icons';
import { Quote } from '@/components/Quote/Quote';

export const QuoteProcessingContent: React.FC = () => {
  const estimatedTime = 2;
  const [percent, setPercent] = useState(0);

  return (
    <div className={'h-[365px] flex flex-col items-center justify-center'}>
      <div className="mb-10">
        <QuoteProcessingIcon />
      </div>
      <div className="flex flex-col gap items-center mb-[86px]">
        <p className="m-0 mb-1 text-xs leading-5 text-dark-gray-100">
          {percent}%
        </p>
        <IntusProgressBar
          strokeColor={'#65BD51'}
          percent={percent}
          showInfo={false}
          className="h-[6px]"
        />
        <p className="m-0 mt-2 text-dark-gray-80 text-xs leading-5">
          Estimated waiting time {estimatedTime} minutes
        </p>
      </div>
      <Quote setPercent={setPercent} />
    </div>
  );
};
