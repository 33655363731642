import React, { forwardRef, Ref, useEffect, useState } from 'react';
import moment from 'moment';
import ReportCover from './Pages/ReportCover/ReportCover';
import BuildingRenders from './Pages/BuildingRenders/BuildingRenders';
import BuildingProperties from './Pages/BuildingProperties/BuildingProperties';
import ReportWrapper from './Pages/ReportWrapper';
import { useParams } from 'react-router';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import PanelProperties from './Pages/PanelProperties/PanelProperties';
import PanelMetrics from './Pages/PanelMetrics/PanelMetrics';
import WindowPropertiesTable from './Pages/PanelMetrics/WindowsPropertiesTable/WindowsPropertiesTable';
import useBuildingMetrics from '@/shared/hooks/useBuildingMetrics';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { NodeType, UserBuildingWall } from '@/models';
import Konva from 'konva';
import { FACADE_VIEW } from '@/shared/constants';
import FacadeDesignerContainer from '../FacadeDesigner/FacadeDesignerContainer';
import { sleep } from '@/shared/helpers/sleep';
import { chunk } from 'lodash';
import { ReportStatus } from '@/models/report';
import { setStatusReport } from '@/store/slices/reportSlice';
import { useAppDispatch } from '@/store/hooks';

interface ReportProps {
  generatedImages: string[];
}

const Report = forwardRef<HTMLDivElement, ReportProps>(
  ({ generatedImages }: ReportProps, ref: Ref<HTMLDivElement>) => {
    const creationDate = moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const isReportRoute = location.pathname.endsWith('/report');
    const { id } = useParams();
    const { data: fetchedPanels } = useGetAllPanelsQuery(id!);
    const { panelCount } = useBuildingMetrics();
    const dispatch = useAppDispatch();
    const projectData = useFetchProjectQuery(id!).data!;
    const [konvaStage, setKonvaStage] = useState<Konva.Stage>(null!);
    const { facadesData } = useFacadeData();
    const [facadeData, setFacadeData] = useState<UserBuildingWall[]>(
      facadesData![0]
    );
    const [facadeSnapshots, setFacadeSnapshots] = useState<string[]>([]);
    const facadeSnapshotsChunks = chunk(facadeSnapshots, 4);
    let currentPageIndex = 3;

    const initialPanelImages: Record<number, string | null> =
      fetchedPanels!.reduce(
        (acc, panel) => {
          acc[panel.id] = null;
          return acc;
        },
        {} as Record<number, string | null>
      );
    const [panelImages, setPanelImages] = useState(initialPanelImages);

    const handleStageInit = (stage: Konva.Stage) => setKonvaStage(stage);

    const totalPages =
      2 +
      facadeSnapshotsChunks.length +
      (fetchedPanels ?? []).reduce(
        // For now we don't control windows count on additional windows page
        (acc, panel) => acc + 1 + (panel.panelWindows.length > 1 ? 1 : 0),
        0
      );

    const makeSnapshots = async () => {
      if (!konvaStage || !facadesData) return;
      const panelSnapshots = {
        ...panelImages,
      };
      const facadeSnapshots: string[] = [];

      for (let i = 0; i < facadesData.length; i++) {
        if (i !== 0) {
          setFacadeData(facadesData[i]);
          await sleep(100);
        }

        const targetNode = konvaStage.find(`#${FACADE_VIEW}`)[0];

        targetNode &&
          (await targetNode.toImage({
            pixelRatio: 2,
            callback: (img) => {
              facadeSnapshots.push(img.src);
            },
          }));

        for (const panel of fetchedPanels!) {
          if (panelSnapshots[panel.id]) continue;
          const panelNode = konvaStage.find(`#panel-${panel.id}`)[0];
          panelNode &&
            (await panelNode.toImage({
              pixelRatio: 5,
              callback: (img) => {
                panelSnapshots[panel.id] = img.src;
              },
            }));
        }
      }

      setFacadeSnapshots(facadeSnapshots);
      setPanelImages(panelSnapshots);

      await sleep(1000);

      dispatch(
        setStatusReport({
          id: id!,
          status: ReportStatus.PROCESSING_CREATE_REPORT,
        })
      );
    };

    useEffect(() => {
      konvaStage && makeSnapshots();
    }, [konvaStage]);

    return (
      <div
        className={`left-0 right-0 top-0 mr-auto ml-auto w-fit z-[-9999] ${
          isReportRoute ? 'flex flex-col gap-5' : 'fixed bg-light-gray-20'
        }`}
        ref={ref}
      >
        <div className="absolute h-full w-full -z-10  hidden">
          {facadeData && (
            <FacadeDesignerContainer
              buildingGUID={projectData.buildings![0].guid}
              selectedWalls={facadeData.map((wall) => ({
                guid: wall.guid,
                type: NodeType.Wall,
              }))}
              placementHeight={window.innerHeight}
              placementWidth={window.innerWidth}
              onKonvaInit={handleStageInit}
              reportView
            />
          )}
        </div>
        <ReportCover backgroundImageUrl={generatedImages[0]} />
        <ReportWrapper
          pageIndex={1}
          reportCreationDate={creationDate}
          countOfPages={totalPages}
          pageName="Renders"
        >
          <BuildingRenders renderLinks={generatedImages} />
        </ReportWrapper>

        <ReportWrapper
          pageIndex={2}
          reportCreationDate={creationDate}
          countOfPages={totalPages}
          pageName="Building properties"
        >
          <BuildingProperties />
        </ReportWrapper>
        {facadeSnapshotsChunks.map((snapshots, index) => (
          <ReportWrapper
            pageIndex={currentPageIndex++}
            key={index}
            reportCreationDate={creationDate}
            countOfPages={totalPages}
            pageName="Panelization properties"
          >
            <PanelProperties snapshots={snapshots} />
          </ReportWrapper>
        ))}
        {fetchedPanels?.map((panel, index) => {
          const hasMoreThanOneWindow = panel.panelWindows.length > 1;
          return (
            <React.Fragment key={`panel-${panel.id}`}>
              <ReportWrapper
                key={panel.id}
                pageIndex={currentPageIndex++}
                countOfPages={totalPages}
                reportCreationDate={creationDate}
                pageName="panel list"
              >
                <PanelMetrics
                  panelIndex={index}
                  panelData={panel}
                  panelCount={panelCount}
                  panelImages={panelImages}
                />
              </ReportWrapper>
              {hasMoreThanOneWindow && (
                <ReportWrapper
                  pageIndex={currentPageIndex++}
                  countOfPages={totalPages}
                  reportCreationDate={creationDate}
                  pageName="panel list"
                >
                  <div className="w-full px-3 py-3">
                    <WindowPropertiesTable panelData={panel} />
                  </div>
                </ReportWrapper>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
);

Report.displayName = 'Report';

export default Report;
