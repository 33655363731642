import {
  DistanceInput,
  FlatVector3,
  MetricLimits,
  ProjectStructure,
  UnitSystemTypes,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
  UserBuildingPanel,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import { BlockSearchResults, WallSearchResults } from './useFindNodeData';
import {
  calculateCommonArea,
  calculateCommonMetric,
  formatAreaValue,
  getCommonValue,
} from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import {
  getAreaInMeters,
  getFacadesArea,
  getObjectDimensions,
  getStoreyHeight,
  getWallArea,
  getWallWidth,
} from '../helpers/metrics';
import {
  convertFtInchToMillimeters,
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
  convertMillimetersToMeters,
  convertSquareMillimetersToSquareMeters,
} from '../helpers/distance';
import {
  convertFlatVector3ToVectors,
  generateWalls,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import * as THREE from 'three';
import { convertInputValueToMillimeters } from '@/shared/helpers/directional-input';
import {
  addSpacesToThousands,
  getAlphabetIndex,
  isMultipleValue,
  limitValue,
  removeSpacesFromThousands,
} from '../helpers/format-data';
import { uuidv7 } from 'uuidv7';
import { cloneDeep, omit, round } from 'lodash';
import {
  convertBufferGeometryTo3DVectorList,
  getBBoxCenter,
  getExtendedVector,
  getXYZ,
  GPSRelativePosition,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { area, polygon, Position } from '@turf/turf';
import { getBuildingsCoordinates } from '../helpers';
import { GeolibGeoJSONPoint } from 'geolib/es/types';
import clipping, { Ring } from 'polygon-clipping';
import { ShapeUtils, Vector2 } from 'three';
import { updateSections } from '@/shared/helpers/rectangle-mode';
import { Line2 } from 'three/examples/jsm/lines/Line2';

interface framePropertiesProps {
  metricOnly?: boolean;
}
const useFrameProperties = (props?: framePropertiesProps) => {
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const getBuildingDimensionsMetric = (
    blocks: BlockSearchResults[],
    dimension: DistanceInput.Width | DistanceInput.Length
  ) => {
    const buildingDimensions = blocks.map((block) =>
      getObjectDimensions(
        block.groundFloorPoints,
        multiplyRate,
        isImperialUnits
      )
    );

    return dimension === DistanceInput.Width
      ? calculateCommonMetric({
          nodes: buildingDimensions,
          calculateMetricFunction: (block) => block.width,
        })
      : calculateCommonMetric({
          nodes: buildingDimensions,
          calculateMetricFunction: (block) => block.length,
        });
  };

  const getBlockStoreysCountMetric = (blocks: UserBuildingBlock[]) =>
    getCommonValue(blocks.map((block) => block.storeys.length.toString()));

  const formatHeight = (heightInMillimeters: string) => {
    return addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(heightInMillimeters)
        : heightInMillimeters,
      isImperialUnits
    );
  };

  const getFormattedValue = (heights: string[]) => {
    const commonValue = getCommonValue(heights);
    return isMultipleValue(commonValue)
      ? commonValue
      : formatHeight(commonValue);
  };

  const getStoreyHeightInMillimeters = (storey: UserBuildingStorey) =>
    getStoreyHeight(storey, multiplyRate);

  const getStoreysHeight = (storeys: UserBuildingStorey[]) =>
    storeys.map((storey) => getStoreyHeight(storey, multiplyRate));

  const getFloorHeightMetric = (storeys: UserBuildingStorey[]) =>
    getFormattedValue(getStoreysHeight(storeys));

  const getFloorHeightInBlock = (blocks: UserBuildingBlock[]) =>
    getFormattedValue(
      blocks.flatMap((block) => getStoreysHeight(block.storeys))
    );

  const getBlockHeightMetric = (blocks: UserBuildingBlock[]) => {
    const blocksHeight = blocks.map((block) =>
      block.storeys
        .map((storey) => getStoreyHeight(storey, multiplyRate))
        .reduce((total, height) => total + Number(height), 0)
        .toFixed(2)
    );

    const commonValue = getCommonValue(blocksHeight);

    if (isMultipleValue(commonValue)) return commonValue;

    return isImperialUnits
      ? convertMillimetersToFtInch(commonValue)
      : addSpacesToThousands(commonValue, isImperialUnits);
  };

  const getFacadesAreaMetricForBlocks = (blocks: UserBuildingBlock[]) => {
    const wallsData = blocks.flatMap((block) =>
      block.storeys.flatMap((storey) => storey.walls)
    );

    const facadesArea = getFacadesArea({
      walls: wallsData,
      multiplyRate,
    });

    return formatAreaValue(facadesArea, isImperialUnits);
  };

  const getGrossInternalAreaForBlocks = (blocks: UserBuildingBlock[]) => {
    const storeysData = blocks.flatMap((block) => block.storeys);

    return calculateCommonArea({
      nodes: storeysData,
      calculateAreaFunction: (storey) =>
        Number(getAreaInMeters(storey.ceiling.points, multiplyRate)),
      isImperialUnits,
    });
  };

  const getFacadesAreaMetricForStoreys = (storeys: UserBuildingStorey[]) => {
    return calculateCommonArea({
      nodes: storeys,
      calculateAreaFunction: (storey) =>
        getFacadesArea({
          walls: storey.walls,
          multiplyRate,
        }),
      isImperialUnits,
    });
  };

  const getGrossInternalAreaMetricForStoreys = (
    storeys: UserBuildingStorey[]
  ) => {
    return calculateCommonArea({
      nodes: storeys,
      calculateAreaFunction: (storey) =>
        Number(getAreaInMeters(storey.ceiling.points, multiplyRate)),
      isImperialUnits,
    });
  };

  const getWallWidthMetric = (walls: UserBuildingWall[]) => {
    const wallWidths = walls.map((wall) =>
      getWallWidth(wall.points, multiplyRate).toString()
    );

    const commonWallWidthInMeters = getCommonValue(wallWidths);

    if (isMultipleValue(commonWallWidthInMeters))
      return commonWallWidthInMeters;
    const wallWidthInMillimeters = convertMetersToMillimeters(
      commonWallWidthInMeters
    );
    return isImperialUnits && !props?.metricOnly
      ? addSpacesToThousands(
          convertMillimetersToFtInch(wallWidthInMillimeters),
          isImperialUnits
        )
      : addSpacesToThousands(
          Number(wallWidthInMillimeters).toFixed(2),
          isImperialUnits
        );
  };

  const getFacadesAreaMetricForWalls = (walls: WallSearchResults[]) => {
    const calculateWallArea = (wall: WallSearchResults) => {
      return Number(getWallArea(wall.points, multiplyRate));
    };

    return calculateCommonArea({
      nodes: walls,
      calculateAreaFunction: calculateWallArea,
      isImperialUnits,
    });
  };

  const createNewStoreyFromPreviousStorey = (
    lastStorey: UserBuildingStorey,
    storeyNumber: number
  ): UserBuildingStorey => {
    const lastStoreyHeight =
      lastStorey.ceiling.points[0][1] - lastStorey.floor.points[0][1];
    return {
      guid: uuidv7(),
      storeyNumber,
      name: `Floor ${storeyNumber}`,
      floor: {
        points: updatePointsHeight(lastStorey.floor.points, lastStoreyHeight),
        guid: uuidv7(),
        userData: {},
        name: `Floor ${storeyNumber}`,
      },
      ceiling: {
        points: updatePointsHeight(lastStorey.ceiling.points, lastStoreyHeight),
        guid: uuidv7(),
        userData: {},
        name: `Ceiling ${storeyNumber}`,
      },
      walls: lastStorey.walls.map((wall, i) => ({
        points: updatePointsHeight(wall.points, lastStoreyHeight),
        guid: uuidv7(),
        userData: {},
        gridLines: [...wall.gridLines],
        windowPlacements: [...wall.windowPlacements],
        wallPanelErrors: [],
        name: `Wall ${getAlphabetIndex(i)}`,
        wallPanels: [...wall.wallPanels],
      })),
      userData: {},
    };
  };

  const changeStoreysNumberInBlock = (
    value: string,
    userBuildingBlock: UserBuildingBlock
  ) => {
    const validatedValue = limitValue(
      Number(value),
      MetricLimits.FloorsMin,
      MetricLimits.FloorsMax
    );

    const targetStoreyCount = Number(validatedValue);

    const currentStoreyCount = userBuildingBlock.storeys.length;
    const updatedBlock = cloneDeep(userBuildingBlock);

    if (targetStoreyCount < currentStoreyCount) {
      updatedBlock.storeys = userBuildingBlock.storeys.slice(
        0,
        targetStoreyCount
      );
    } else if (targetStoreyCount > currentStoreyCount) {
      for (let i = currentStoreyCount; i < targetStoreyCount; i++) {
        const lastStorey = updatedBlock.storeys[i - 1];
        const newStorey = createNewStoreyFromPreviousStorey(lastStorey, i + 1);
        updatedBlock.storeys.push(newStorey);
      }
    }

    return updatedBlock;
  };

  const convertUserWidthValueToCanvas = (value: string) => {
    const valueWithSpaces = removeSpacesFromThousands(value, isImperialUnits);
    return (
      convertMillimetersToMeters(
        convertInputValueToMillimeters(valueWithSpaces, !isImperialUnits)
      ) * multiplyRate
    );
  };

  const updateCenterLineBuildingWidth = (
    val: string,
    building: UserBuildingCoordinatesObject
  ) => {
    const centerLineVectors = convertFlatVector3ToVectors(
      building.centerLineCoordinates!.points
    );
    const width = convertUserWidthValueToCanvas(val);
    const sections: THREE.Mesh[] = [];
    let floorShape: THREE.Mesh = null!;
    let contour: Line2 = null!;

    for (let i = 0; i < centerLineVectors.length - 1; i++) {
      //TODO fix it when width will be done
      updateSections({
        sections,
        width,
        currentCenterLineSection: [
          centerLineVectors[i],
          centerLineVectors[i + 1],
        ],
        previousCenterLineVectors:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
        closeSection: false,
        contour,
        floor: floorShape,
        setClosedFloor: () => {},
        setContour: (val) => {
          contour = val;
        },
        setFloor: (val) => (floorShape = val),
      });
      updateSections({
        sections,
        width,
        currentCenterLineSection: [
          centerLineVectors[i],
          centerLineVectors[i + 1],
        ],
        previousCenterLineVectors:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
        closeSection: true,
        contour,
        floor: floorShape,
        setClosedFloor: () => {},
        setContour: (val) => {
          contour = val;
        },
        setFloor: (val) => (floorShape = val),
      });
    }

    const firstFloorCoordinates: FlatVector3[] =
      convertBufferGeometryTo3DVectorList(
        floorShape.geometry,
        floorShape.geometry.getAttribute('position').count
      ).map((vector) => getXYZ(vector));

    const updatedBlock = cloneDeep(building.blocks[0]);
    let currentHeight = 0;

    for (
      let floorIndex = 0;
      floorIndex < building.blocks[0].storeys.length;
      floorIndex++
    ) {
      const storeyHeight =
        building.blocks[0].storeys[floorIndex].ceiling.points[0][1] -
        building.blocks[0].storeys[floorIndex].floor.points[0][1];

      const floorPoints = firstFloorCoordinates.map(
        ([x, y, z]): FlatVector3 => [x, y + currentHeight, z]
      );
      const ceilingPoints = firstFloorCoordinates.map(
        ([x, y, z]): FlatVector3 => [x, y + currentHeight + storeyHeight, z]
      );

      const newWalls = generateWalls(
        convertFlatVector3ToVectors(floorPoints),
        convertFlatVector3ToVectors(ceilingPoints),
        updatedBlock.storeys[floorIndex].walls
      );

      updatedBlock.storeys[floorIndex] = {
        ...omit(updatedBlock.storeys[floorIndex], 'id'),
        ceiling: {
          ...omit(updatedBlock.storeys[floorIndex].ceiling, 'id'),
          points: ceilingPoints,
        },
        floor: {
          ...omit(updatedBlock.storeys[floorIndex].floor, 'id'),
          points: floorPoints,
        },
        walls: newWalls,
      } as UserBuildingStorey;

      currentHeight += storeyHeight;
    }

    return updatedBlock;
  };

  const updatePointsHeight = (
    points: FlatVector3[],
    heightOffset: number
  ): FlatVector3[] => {
    return points.map((point) => {
      const updatedPoint: FlatVector3 = [
        point[0],
        point[1] + heightOffset,
        point[2],
      ];
      return updatedPoint;
    });
  };

  const updateFirstFloorCoordinatesForRectangleBuilding = ({
    value,
    userBuildingBlock,
    dimension,
  }: {
    value: string;
    userBuildingBlock: UserBuildingBlock;
    dimension: DistanceInput.Width | DistanceInput.Length;
  }) => {
    const valueWithSpaces = removeSpacesFromThousands(value, isImperialUnits);
    const firstStorey = userBuildingBlock.storeys[0];
    const meterValue =
      convertMillimetersToMeters(
        isImperialUnits
          ? convertFtInchToMillimeters(valueWithSpaces)
          : valueWithSpaces
      ) * multiplyRate;

    const [v1, v2, v3, v4, v5] = convertFlatVector3ToVectors(
      firstStorey.floor.points
    );

    let updatedVectors;
    if (dimension === DistanceInput.Width) {
      updatedVectors = [
        v1,
        getExtendedVector(v1, v2, meterValue),
        getExtendedVector(v4, v3, meterValue),
        v4,
        v5,
      ];
    } else {
      updatedVectors = [
        v1,
        v2,
        getExtendedVector(v2, v3, meterValue),
        getExtendedVector(v1, v4, meterValue),
        v5,
      ];
    }

    return updatedVectors.map((vector) => getXYZ(vector));
  };

  const changeDimensionInRectangleBuilding = ({
    value,
    userBuildingBlock,
    dimension,
  }: {
    value: string;
    userBuildingBlock: UserBuildingBlock;
    dimension: DistanceInput.Width | DistanceInput.Length;
  }) => {
    const firstFloorCoordinates =
      updateFirstFloorCoordinatesForRectangleBuilding({
        value,
        userBuildingBlock,
        dimension,
      });

    const updatedBlock = cloneDeep(userBuildingBlock);

    let currentHeight = 0;

    for (
      let floorIndex = 0;
      floorIndex < userBuildingBlock.storeys.length;
      floorIndex++
    ) {
      const storeyHeight =
        userBuildingBlock.storeys[floorIndex].ceiling.points[0][1] -
        userBuildingBlock.storeys[floorIndex].floor.points[0][1];

      const floorPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + currentHeight,
        z,
      ]);
      const ceilingPoints = firstFloorCoordinates.map(([x, y, z]) => [
        x,
        y + currentHeight + storeyHeight,
        z,
      ]);

      const allWalls = [];
      for (let i = 0; i < floorPoints.length - 1; i++) {
        const wall = [
          floorPoints[i],
          floorPoints[i + 1],
          ceilingPoints[i + 1],
          ceilingPoints[i],
        ];
        allWalls.push(wall);
      }

      updatedBlock.storeys[floorIndex] = {
        ...omit(updatedBlock.storeys[floorIndex], 'id'),
        ceiling: {
          ...omit(updatedBlock.storeys[floorIndex].ceiling, 'id'),
          points: ceilingPoints,
        },
        floor: {
          ...omit(updatedBlock.storeys[floorIndex].floor, 'id'),
          points: floorPoints,
        },
        walls: allWalls.map((wallPoints, index) => ({
          ...omit(updatedBlock.storeys[floorIndex].walls[index], 'id'),
          points: wallPoints,
        })),
      } as UserBuildingStorey;

      currentHeight += storeyHeight;
    }

    return updatedBlock;
  };

  const updateFloorHeightInStorey = (
    height: string,
    storey: UserBuildingStorey
  ) => {
    const valueWithSpaces = removeSpacesFromThousands(height, isImperialUnits);
    const formattedHeight =
      convertMillimetersToMeters(
        convertInputValueToMillimeters(valueWithSpaces, !isImperialUnits)
      ) * multiplyRate;
    const ceilingPoints: FlatVector3[] = updatePointsHeight(
      storey.floor.points,
      Number(formattedHeight)
    );
    const updatedWalls: UserBuildingWall[] = storey.walls.map((wall) => ({
      ...wall,
      points: wall.points.map(([x, y, z], i) => [
        x,
        i < 2 ? y : ceilingPoints[0][1],
        z,
      ]),
    }));

    return {
      ...storey,
      ceiling: {
        ...storey.ceiling,
        points: ceilingPoints,
      },
      walls: updatedWalls,
    };
  };

  const updateStoreyPointsAccordingToNewHeight = (
    height: string,
    currentStorey: UserBuildingStorey,
    previousStorey: UserBuildingStorey
  ) => {
    const previousStoreyHeight =
      previousStorey.ceiling.points[0][1] - previousStorey.floor.points[0][1];

    const updatedFloorPoints: FlatVector3[] = currentStorey.floor.points.map(
      (point) => [point[0], previousStorey.ceiling.points[0][1], point[2]]
    );

    const updatedCeilingPoints = updatePointsHeight(
      updatedFloorPoints,
      previousStoreyHeight
    );

    const updatedWalls = currentStorey.walls.map((wall) => ({
      ...wall,
      points: wall.points.map((point, index) => [
        point[0],
        index < 2 ? updatedFloorPoints[0][1] : updatedCeilingPoints[0][1],
        point[2],
      ]),
    }));

    return {
      ...currentStorey,
      floor: {
        ...currentStorey.floor,
        points: updatedFloorPoints,
      },
      ceiling: {
        ...currentStorey.ceiling,
        points: updatedCeilingPoints,
      },
      walls: updatedWalls,
    } as UserBuildingStorey;
  };

  const updateFloorHeightInBlock = (
    height: string,
    userBuildingBlock: UserBuildingBlock
  ): UserBuildingBlock => {
    const updatedBlock = cloneDeep(userBuildingBlock);
    updatedBlock.storeys[0] = updateFloorHeightInStorey(
      height,
      userBuildingBlock.storeys[0]
    );

    for (let i = 1; i < updatedBlock.storeys.length; i++) {
      updatedBlock.storeys[i] = updateStoreyPointsAccordingToNewHeight(
        height,
        updatedBlock.storeys[i],
        updatedBlock.storeys[i - 1]
      );
    }

    return updatedBlock;
  };

  const updateFloorHeightForSelectedStoreyInBlock = (
    height: string,
    updatedStoreyCount: number,
    userBuildingBlock: UserBuildingBlock
  ) => {
    const updatedBlock = cloneDeep(userBuildingBlock);

    updatedBlock.storeys[updatedStoreyCount - 1] = updateFloorHeightInStorey(
      height,
      updatedBlock.storeys[updatedStoreyCount - 1]
    );

    for (let i = updatedStoreyCount; i < updatedBlock.storeys.length; i++) {
      const previousStorey = updatedBlock.storeys[i - 1];
      const currentStorey = updatedBlock.storeys[i];

      const originalHeightOfCurrentStorey =
        currentStorey.ceiling.points[0][1] - currentStorey.floor.points[0][1];

      const floorPoints: FlatVector3[] = currentStorey.floor.points.map(
        (point) => [point[0], previousStorey.ceiling.points[0][1], point[2]]
      );

      const ceilingPoints: FlatVector3[] = updatePointsHeight(
        floorPoints,
        originalHeightOfCurrentStorey
      );

      const updatedWalls: UserBuildingWall[] = currentStorey.walls.map(
        (wall) => ({
          ...wall,
          points: wall.points.map((point, index) => [
            point[0],
            index < 2 ? floorPoints[index][1] : ceilingPoints[0][1],
            point[2],
          ]),
        })
      );

      updatedBlock.storeys[i] = {
        ...currentStorey,
        floor: {
          ...currentStorey.floor,
          points: floorPoints,
        },
        ceiling: {
          ...currentStorey.ceiling,
          points: ceilingPoints,
        },
        walls: updatedWalls,
      };
    }

    return updatedBlock;
  };

  const calculateConstructionSiteArea = (points: Position[]): number => {
    return Number(area(polygon([points])).toFixed(2));
  };

  const getUsedConstructionSiteArea = (projectData: ProjectStructure) => {
    const coordinates: Ring =
      projectData.environment.constructionSite.points?.map((point) => {
        const [x, y] = GPSRelativePosition(
          point as GeolibGeoJSONPoint,
          getBBoxCenter(projectData.boundingBox)
        );
        return [-y, -x];
      });
    const buildingFloorsCoordinates: Ring[] = getBuildingsCoordinates(
      projectData.buildings!
    ).map((c) =>
      c.map(
        (coordinates) => [coordinates[0], coordinates[2]] as [number, number]
      )
    );

    const occupiedPolygons = clipping.intersection(
      [coordinates],
      buildingFloorsCoordinates
    );

    const siteArea = calculateConstructionSiteArea(
      projectData.environment.constructionSite.points as Position[]
    );

    const occupiedArea = Number(
      occupiedPolygons
        .reduce((acc, polygon) => {
          return (
            acc +
            ShapeUtils.area(polygon[0].map((v) => new Vector2(...v))) /
              Math.pow(multiplyRate, 2)
          );
        }, 0)
        .toFixed(2)
    );

    const usageRate = Number(((occupiedArea / siteArea) * 100).toFixed(2));

    return { occupiedArea, usageRate };
  };

  const calculatePanelAreaInSquareMeters = (panelData: UserBuildingPanel) => {
    const areaInSquareMillimeters =
      panelData.isCorner && panelData.sideWidth
        ? panelData.sideWidth * panelData.height +
          panelData.width * panelData.height
        : panelData.width * panelData.height;

    return +convertSquareMillimetersToSquareMeters(areaInSquareMillimeters);
  };

  const calculatePanelArea = (panelData: UserBuildingPanel) => {
    const areaInSquareMeters = calculatePanelAreaInSquareMeters(panelData);
    return formatAreaValue(Number(areaInSquareMeters), isImperialUnits);
  };

  const calculateNonGlazedAreaInPanel = (panelData: UserBuildingPanel) => {
    const areaInSquareMeters = calculatePanelAreaInSquareMeters(panelData);
    const nonGlazedArea = (areaInSquareMeters - panelData.glazedArea).toFixed(
      2
    );
    return formatAreaValue(Number(nonGlazedArea), isImperialUnits);
  };

  const getAreaUnit = () => {
    return isImperialUnits ? 'ft²' : 'm²';
  };

  const getUnit = () => {
    return isImperialUnits ? 'ft' : 'mm';
  };

  const getMeterUnits = () => (isImperialUnits ? 'ft' : 'm');

  const getCenterLineWidth = (buildingData: UserBuildingCoordinatesObject) => {
    if (!buildingData?.centerLineCoordinates) return '-';

    if (isImperialUnits) {
      return addSpacesToThousands(
        convertMillimetersToFtInch(
          buildingData.centerLineCoordinates.width.toString()
        ),
        isImperialUnits
      );
    }
    return addSpacesToThousands(
      round(buildingData.centerLineCoordinates.width, 2).toString(),
      isImperialUnits
    );
  };

  const getFormattedValueInMillimeters = (value: string) => {
    const valueWithoutSpaces = removeSpacesFromThousands(
      value,
      isImperialUnits
    );

    return isImperialUnits
      ? convertFtInchToMillimeters(valueWithoutSpaces)
      : valueWithoutSpaces;
  };

  return {
    getBuildingDimensionsMetric,
    getFloorHeightMetric,
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getFormattedValue,
    getStoreyHeightInMillimeters,
    getFloorHeightInBlock,
    getFacadesAreaMetricForBlocks,
    getGrossInternalAreaForBlocks,
    getFacadesAreaMetricForStoreys,
    getGrossInternalAreaMetricForStoreys,
    getWallWidthMetric,
    getFacadesAreaMetricForWalls,
    updateCenterLineBuildingWidth,
    changeStoreysNumberInBlock,
    changeDimensionInRectangleBuilding,
    updateFloorHeightInBlock,
    updateFloorHeightInStorey,
    updateFloorHeightForSelectedStoreyInBlock,
    calculateConstructionSiteArea,
    getUsedConstructionSiteArea,
    calculatePanelArea,
    calculateNonGlazedAreaInPanel,
    getAreaUnit,
    getUnit,
    getMeterUnits,
    getCenterLineWidth,
    convertUserWidthValueToCanvas,
    getFormattedValueInMillimeters,
  };
};

export default useFrameProperties;
