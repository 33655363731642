import {
  addSelectedPlacedWindow,
  addToSelectedGridLines,
  addToSelectedPlacedPanel,
  getFacadeDesignerMode,
  resetSelectedGridLines,
  resetSelectedPlacedPanels,
  setSelectedGridLines,
  setSelectedPlacedPanel,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  GridLineDataForFD,
  PanelPlacementDataForFD,
  WindowPlacementDataForFD,
} from '@/components/FacadeDesigner/models';

export const useFacadeDesignerSelection = () => {
  const dispatch = useAppDispatch();

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const handleSelectWindow = ({
    multiSelect,
    data,
  }: {
    multiSelect: boolean;
    data: WindowPlacementDataForFD;
  }) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedPlacedWindow([data]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    } else {
      dispatch(addSelectedPlacedWindow(data));
    }
  };
  const handleSelectGridLine = ({
    multiSelect,
    data,
  }: {
    multiSelect: boolean;
    data: GridLineDataForFD;
  }) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedGridLines([data]));
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedPlacedPanels());
    } else {
      dispatch(addToSelectedGridLines(data));
    }
  };

  const handleSelectPanel = ({
    multiSelect,
    panelData,
  }: {
    multiSelect: boolean;
    panelData: PanelPlacementDataForFD;
  }) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedPlacedPanel(panelData));
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
    } else {
      dispatch(addToSelectedPlacedPanel(panelData));
    }
  };

  return {
    handleSelectGridLine,
    handleSelectWindow,
    handleSelectPanel,
    isSelectionMode,
  };
};
