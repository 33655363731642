import React, { useEffect, useMemo, useRef } from 'react';
import { Group, Layer, Line, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import {
  GridLineData,
  NodeType,
  SelectedNode,
  WindowPlacementData,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  FacadeDesignerPlacementType,
  getDragNode,
  getFacadeDesignerMode,
  getHoveredGridLine,
  getIsSomePanelHovered,
  getHoveredPlacedWindow,
  getHoveredWall,
  getMeasurementActiveWall,
  getModifiedWalls,
  getPlacementErrors,
  getSelectedGridlines,
  getSelectedPlacedWindows,
  resetPlacementErrors,
  resetSelectedGridLines,
  resetSelectedPlacedPanels,
  resetSelectedWindowFromLibrary,
  setDragNode,
  setGridPlacementAbsoluteOffset,
  setHoveredPlacedPanel,
  setHoveredPlacedWindow,
  setSelectedGridLines,
  setSelectedPlacedWindow,
  setSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import FacadeDesignerWallView from '@/components/FacadeDesigner/elements/FacadeDesignerWallView';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { round } from 'mathjs';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import FacadeDesignerBackground from '@/components/FacadeDesigner/elements/FacadeDesignerBackground';
import { useGridLines } from '@/components/FacadeDesigner/hooks/useGridLines';
import { useParams } from 'react-router';

import { FACADE_VIEW } from '@/shared/constants';
import { REPORT_VIEW_SCALE } from '@/components/WindowCreator/constants';
import { useKey, useUpdate } from 'react-use';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import PanelViewReport from './elements/PanelViewReport';
import { GRID_INFORMATION_PADDING } from './constants';
import { Polygon, union } from 'polygon-clipping';
import { useMeasureWallsFacadeDesigner } from './hooks/useMeasureWallsFacadeDesigner';
import { GridLineConfig } from './models';
import { useStore } from 'react-redux';
import { RootState } from '@/store';
import FacadeDesignerMeasurements from '@/components/FacadeDesigner/elements/FacadeDesignerMeasurements';
import FacadeDesignerErrors from '@/components/FacadeDesigner/elements/FacadeDesignerErrors';

interface FacadeDesignerContainerProps {
  buildingGUID: string;
  selectedWalls: SelectedNode[];
  placementHeight: number;
  placementWidth: number;
  isProjectLocked?: boolean;
  onKonvaInit?: (stage: Konva.Stage) => void;
  reportView?: boolean;
  showPanelCellNumbers?: boolean;
}

const FacadeDesignerContainer: React.FC<FacadeDesignerContainerProps> = ({
  buildingGUID,
  selectedWalls,
  placementHeight,
  placementWidth,
  isProjectLocked,
  onKonvaInit,
  reportView,
  showPanelCellNumbers,
}) => {
  Konva.dragButtons = [2];
  const stageRef = useRef<Konva.Stage>(null!);
  const { id } = useParams();

  const windowsData = useFetchWindowsQuery(id!).data!;

  const update = useUpdate();

  const dispatch = useAppDispatch();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const modifiedWalls = useAppSelector(getModifiedWalls);
  const { updateWallWindowPlacements, updateBuildingPlacements } =
    useUpdateUserBuildingData();
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const selectedPlacedGridLines = useAppSelector(getSelectedGridlines);
  const store = useStore();
  const dragNode = useAppSelector(getDragNode);
  const isGridLineDragActive =
    dragNode === FacadeDesignerPlacementType.GridLine;

  const measurementActiveWall = useAppSelector(getMeasurementActiveWall);
  const hoveredWindow = useAppSelector(getHoveredPlacedWindow);
  const hoveredGridLine = useAppSelector(getHoveredGridLine);

  const timeoutForGrabRef = useRef<NodeJS.Timeout | null>(null);
  const grabbedWindowRef = useRef<WindowPlacementData | null>(null);
  const { findDataForWall } = useFindNodeData();
  const { generateCustomGrids } = useGridLines(buildingGUID);
  const { getMeasureWall, getMeasureWalls } = useMeasureWallsFacadeDesigner();

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const allPanels = useGetAllPanelsQuery(id!).data ?? [];
  const isSomePanelHovered = useAppSelector(getIsSomePanelHovered);

  useEffect(() => {
    if (!isSelectionMode) {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    }
  }, [isSelectionMode]);

  const wallsData: WallSearchResults[] = useMemo(() => {
    return selectedWalls.map((wall) => findDataForWall(wall.guid)!);
  }, [selectedWalls]);

  const {
    minMaxWallsCoordinates,
    startPoint,
    wallsTotalHeight,
    wallsTotalWidth,
  } = getMeasureWalls(wallsData);

  const isOnlyOneGridLineSelected = useMemo(() => {
    const values = selectedPlacedGridLines.reduce((acc, curr) => {
      return { ...acc, [curr.absoluteOffset]: 1 };
    }, {});
    return Object.keys(values).length < 2;
  }, [selectedPlacedGridLines]);

  const scaleX = (placementWidth * 0.68) / wallsTotalWidth;
  const scaleY =
    (placementHeight - 100) / (wallsTotalHeight + GRID_INFORMATION_PADDING * 2);
  const scale = Math.min(scaleX, scaleY);

  const { wheelHandler } = useKonvaHandlers();

  const wheelEventEndTimeout = useRef(0);

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.scale({ x: scale, y: scale });
      stageRef.current.position({
        x: placementWidth / 2 - (wallsTotalWidth * scale) / 2,
        y: placementHeight / 2 - (wallsTotalHeight * scale) / 2,
      });
      update();
    }
  }, [modifiedWalls, stageRef.current]);

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    clearTimeout(wheelEventEndTimeout.current);
    wheelEventEndTimeout.current = window.setTimeout(() => update(), 100);
    const scaleData = wheelHandler(event, {
      maxScale: 1,
      minScale: Math.min(0.01, scale),
    });

    if (scaleData) {
      stageRef.current.scale({ x: scaleData.scale, y: scaleData.scale });
      stageRef.current.position({
        x: scaleData.x,
        y: scaleData.y,
      });
    }
  };

  const handleGridPlacement = () => {
    generateCustomGrids({ wallsData, startPoint });
  };

  const handleSaveWindowPlacements = (
    placements: WindowPlacementData[],
    wallData: WallSearchResults
  ) => {
    updateWallWindowPlacements({
      buildingGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Building
      )!.guid,
      blockGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Block
      )!.guid,
      storeyGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Storey
      )!.guid,
      wallGUID: wallData.guid,
      windowPlacements: placements,
    });
  };

  const handleSaveWallPlacements = (
    newPlacements: {
      wallGUID: string;
      gridLines?: GridLineData[];
      windowPlacements?: WindowPlacementData[];
    }[]
  ) => {
    updateBuildingPlacements({
      buildingGUID,
      placementData: newPlacements,
    });
  };
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (
        facadeDesignerMode === FacadeDesignerModes.Selection &&
        !isProjectLocked &&
        (selectedPlacedWindows.length || selectedPlacedGridLines.length)
      ) {
        const newPlacements: {
          wallGUID: string;
          gridLines?: GridLineData[];
          windowPlacements?: WindowPlacementData[];
        }[] = modifiedWalls.map((wallGUID) => {
          const newGridLines = wallsData
            .find((data) => data.guid === wallGUID)
            ?.gridLines.filter(
              (line) =>
                line.cornerAlign ||
                !selectedPlacedGridLines.some(
                  (selectedGridLine) => selectedGridLine.guid === line.guid
                )
            );
          const newWindowPlacements = wallsData
            .find((data) => data.guid === wallGUID)
            ?.windowPlacements.filter(
              (window) =>
                !selectedPlacedWindows.some(
                  (selectedWindow) => selectedWindow.guid === window.guid
                )
            );
          return {
            wallGUID,
            windowPlacements: newWindowPlacements,
            gridLines: newGridLines,
          };
        });

        handleSaveWallPlacements(newPlacements);

        dispatch(setSelectedPlacedWindow([]));
        dispatch(setHoveredPlacedWindow(null));
        dispatch(resetSelectedGridLines());
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [selectedPlacedWindows, wallsData, selectedPlacedGridLines]);

  useEffect(() => {
    stageRef.current && onKonvaInit && onKonvaInit(stageRef.current);
  }, [stageRef.current]);

  const contours = useMemo(() => {
    const metricsWall = wallsData.map((wall) => {
      const { wallHeight, wallOffset, wallWidth } = getMeasureWall(
        wall,
        startPoint,
        minMaxWallsCoordinates
      );
      const offsetX = wallOffset.x;
      const offsetY = round(-1 * wallOffset.y, 2);
      const adjustableHeight = round(offsetY + wallHeight, 2);

      return [
        [
          [offsetX, offsetY],
          [offsetX + wallWidth, offsetY],
          [offsetX + wallWidth, adjustableHeight],
          [offsetX, adjustableHeight],
        ],
      ] as Polygon;
    });

    return union(metricsWall).flat(1);
  }, [wallsData]);

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    }
  };

  const activateDrag = (
    type: FacadeDesignerPlacementType,
    callback: () => void
  ) => {
    timeoutForGrabRef.current = setTimeout(() => {
      dispatch(setDragNode(type));
      callback();
      stageRef.current.container().style.cursor = 'grabbing';
    }, 300);
  };

  const handleStartWindowDrag = () => {
    activateDrag(FacadeDesignerPlacementType.Window, () => {
      dispatch(resetSelectedGridLines());
      dispatch(setSelectedPlacedWindow([hoveredWindow!]));
      grabbedWindowRef.current = hoveredWindow;
      const windowFromLibrary = windowsData.find(
        (window) => hoveredWindow!.windowId === window.id
      );

      windowFromLibrary &&
        dispatch(setSelectedWindowFromLibrary(windowFromLibrary));
      dispatch(
        setGridPlacementAbsoluteOffset(hoveredWindow!.offsetFromLeftEdge)
      );
    });

    return;
  };

  const handleStartGridLineDrag = () => {
    if (!isOnlyOneGridLineSelected) return;
    activateDrag(FacadeDesignerPlacementType.GridLine, () => {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(setGridPlacementAbsoluteOffset(hoveredGridLine!.absoluteOffset));
    });

    return;
  };

  const handleDragStart = (e: KonvaEventObject<PointerEvent>) => {
    if (
      !isSelectionMode ||
      e.evt.shiftKey ||
      measurementActiveWall ||
      isProjectLocked
    )
      return;

    if (hoveredWindow) {
      handleStartWindowDrag();
    }

    if (hoveredGridLine) {
      handleStartGridLineDrag();
    }
  };

  const handleGridLineDragEnd = () => {
    // Can't happen, however for additional validation added here, to not break out the code
    if (!selectedPlacedGridLines.length) return;

    const hoveredWall = getHoveredWall(store.getState() as RootState);

    const selectedGridLineOffset =
      selectedPlacedGridLines[0].offsetFromLeftEdge;

    const relevantWalls = wallsData.filter((wall) =>
      wall.gridLines.some(
        (line) => line.offsetFromLeftEdge === selectedGridLineOffset
      )
    );
    const newGridLines = generateCustomGrids({
      wallsData: relevantWalls.map((wallData) => ({
        ...wallData,
        gridLines: wallData.gridLines.filter(
          (gridLine) =>
            !selectedPlacedGridLines.some((line) => gridLine.guid === line.guid)
        ),
      })),
      startPoint,
    });
    const mainSelectedGridLine = newGridLines?.find(
      (line) => line.wallGUID === hoveredWall
    );
    mainSelectedGridLine
      ? dispatch(setSelectedGridLines([mainSelectedGridLine]))
      : dispatch(setSelectedGridLines(newGridLines));
    handleDragReset();
  };

  const handleWindowDragEnd = (newOffset: number) => {
    const hoveredWall = getHoveredWall(store.getState() as RootState);
    if (!grabbedWindowRef.current || !hoveredWall) {
      return handleDragReset();
    }

    const wall = wallsData.find((wall) =>
      wall.windowPlacements.find(
        (window) => window.guid === grabbedWindowRef.current?.guid
      )
    );

    const hoveredWallPlacement = wallsData
      .find((wall) => wall.guid === hoveredWall)
      ?.windowPlacements.filter(
        (window) => window.guid !== grabbedWindowRef.current?.guid
      );

    if (!hoveredWallPlacement) return;
    const newWindowData = {
      wallGUID: hoveredWall,
      windowId: grabbedWindowRef.current.windowId,
      guid: grabbedWindowRef.current.guid,
      offsetFromLeftEdge: newOffset,
    };
    handleSaveWallPlacements([
      {
        wallGUID: hoveredWall,
        windowPlacements: [...hoveredWallPlacement, newWindowData],
      },
      {
        wallGUID: wall?.guid ?? '',
        windowPlacements: wall?.windowPlacements.filter(
          (window) => window.guid !== grabbedWindowRef.current?.guid
        ),
      },
    ]);
    dispatch(setSelectedPlacedWindow([newWindowData]));
    handleDragReset();
  };

  const handleDragEnd = () => {
    if (!isSelectionMode) return;
    const placementErrors = getPlacementErrors(store.getState() as RootState);
    if (Object.values(placementErrors).some((error) => error.state)) {
      handleDragReset();
      return;
    }
    if (isGridLineDragActive) {
      handleGridLineDragEnd();
    }

    handleDragReset();
  };

  const handleDragReset = () => {
    timeoutForGrabRef.current && clearTimeout(timeoutForGrabRef.current);
    timeoutForGrabRef.current = null;
    grabbedWindowRef.current = null;

    dragNode && dispatch(setDragNode(null));
    dispatch(resetPlacementErrors());

    dispatch(resetSelectedWindowFromLibrary());

    if ((hoveredGridLine || hoveredWindow) && isOnlyOneGridLineSelected) {
      stageRef.current.container().style.cursor = 'pointer';
    }
  };

  useKey('Escape', handleDragReset, { event: 'keydown' }, [dragNode]);

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const hoveredElementIsStage = event.target?.nodeType === 'Stage';
    if (isSomePanelHovered && hoveredElementIsStage)
      dispatch(setHoveredPlacedPanel(null));
  };

  return (
    <Stage
      onContextMenu={(e) => e.evt.preventDefault()}
      width={placementWidth}
      height={placementHeight}
      onClick={handleClick}
      draggable
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
      ref={stageRef}
      onPointerDown={handleDragStart}
      onPointerUp={handleDragEnd}
      onMouseMove={handleMouseMove}
    >
      {stageRef.current && (
        <>
          <Layer>
            <FacadeDesignerBackground
              scale={stageRef.current.scaleX()!}
              totalHeight={wallsTotalHeight}
              totalWidth={wallsTotalWidth}
              unitSystem={unitSystem}
            />

            <Group id={FACADE_VIEW}>
              {wallsData.map((wallData) => {
                const { wallHeight, wallOffset, wallWidth } = getMeasureWall(
                  wallData,
                  startPoint,
                  minMaxWallsCoordinates
                );

                return (
                  <FacadeDesignerWallView
                    reportView={reportView}
                    wallHeight={wallHeight}
                    wallWidth={wallWidth}
                    wallOffset={wallOffset}
                    facadeDesignerMode={facadeDesignerMode}
                    scale={
                      reportView
                        ? REPORT_VIEW_SCALE
                        : stageRef.current.scaleX()!
                    }
                    wallData={wallData}
                    key={wallData.guid}
                    onAddWindow={(data) =>
                      handleSaveWindowPlacements(data, wallData)
                    }
                    onMeasurementUpdate={(data) =>
                      handleSaveWindowPlacements(data, wallData)
                    }
                    onGridPlacement={handleGridPlacement}
                    handleWindowDragEnd={handleWindowDragEnd}
                  />
                );
              })}
              {contours.map((contour, index) => (
                <Line
                  key={`wall-contour-${index}`}
                  points={contour.flat()}
                  stroke={GridLineConfig.placedSelected.stroke}
                  strokeWidth={
                    reportView
                      ? REPORT_VIEW_SCALE
                      : 1.3 / stageRef.current.scaleX()!
                  }
                  strokeScaleEnabled={!reportView}
                />
              ))}
            </Group>
            <>
              <FacadeDesignerMeasurements
                projectId={id!}
                wallsData={wallsData}
                startPoint={startPoint}
                scale={stageRef.current.scale()!.x}
                minMaxWallsCoordinates={minMaxWallsCoordinates}
                selectedWalls={selectedWalls}
                wallsTotalWidth={wallsTotalWidth}
                wallsTotalHeight={wallsTotalHeight}
              />
            </>
          </Layer>
          {reportView && (
            <Layer>
              {allPanels.map((panel) => (
                <PanelViewReport
                  key={panel.id}
                  panelInfo={panel}
                  scale={stageRef.current.scaleX()}
                  showPanelCellNumbers={showPanelCellNumbers}
                />
              ))}
            </Layer>
          )}
        </>
      )}
      <FacadeDesignerErrors scale={scale} />
    </Stage>
  );
};

export default FacadeDesignerContainer;
