import React, { useEffect, useMemo } from 'react';
import { UserBuildingBlock } from '@/models';
import { createLine2 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as polyclip from 'polyclip-ts';
import {
  generateVerticalBlockContour,
  getBlockContour,
  getBlockContourMaterial,
  getEdgePoints,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  BUILDING_BORDER_COLOR,
  BUILDING_SELECTED_CONTOUR_COLOR,
  DEFAULT_BORDER_CONTOUR_FAT_LINE_WIDTH,
} from '@/shared/materials';
import { Line2 } from 'three/examples/jsm/lines/Line2';

const BlockContour = ({
  block,
  showContour,
}: {
  block: UserBuildingBlock;
  showContour: boolean;
}) => {
  polyclip.setPrecision(1e-9);

  const configureLineMaterial = (line: Line2, showContour: boolean): Line2 => {
    line.material.linewidth = DEFAULT_BORDER_CONTOUR_FAT_LINE_WIDTH;
    line.material.color = showContour
      ? BUILDING_SELECTED_CONTOUR_COLOR
      : BUILDING_BORDER_COLOR;

    return line;
  };

  const verticalContour = useMemo(() => {
    return block.storeys
      .map((storey) =>
        getEdgePoints(storey).map((point) =>
          configureLineMaterial(
            generateVerticalBlockContour(point, storey.ceiling.points[0]),
            showContour
          )
        )
      )
      .flat();
  }, [block, showContour]);

  const contour = useMemo(() => {
    const contourPoints = getBlockContour(block);

    return contourPoints
      .map((edges) =>
        edges?.map((edge) =>
          configureLineMaterial(
            createLine2(edge.flat(), getBlockContourMaterial()),
            showContour
          )
        )
      )
      .flat(2);
  }, [block, showContour]);

  useEffect(() => {
    return () => {
      verticalContour.forEach((line) => line.geometry.dispose());
      contour.forEach((line) => line.geometry.dispose());
    };
  }, [block]);

  return (
    <group>
      {verticalContour.map((point, i) => (
        <primitive object={point} key={`vertical-contour_${i}`} />
      ))}

      {contour.map((edge) => (
        <primitive object={edge} key={`contour-point_${edge.id}`} />
      ))}
    </group>
  );
};

export default BlockContour;
