import React from 'react';
import { UnitSystemTypes } from '@/models';
import {
  FlatVector2Axis,
  SavedWindow,
} from '@/components/WindowCreator/models';
import {
  getHoveredPlacedWindow,
  getSelectedPlacedWindows,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import FacadeDesignerWindow from '@/components/FacadeDesigner/elements/FacadeDesignerWindow';
import { FacadeDesignerModes } from '@/models/shared.model';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { WindowPlacementDataForFD } from '../models';

interface ExistingWindowsProps {
  placedWindows: WindowPlacementDataForFD[];
  windowsData: SavedWindow[];
  scale: number;
  wallData: WallSearchResults;
  wallHeight: number;
  wallWidth: number;
  unitSystem: UnitSystemTypes;
  isWindowPlacementProcessing: boolean;
  facadeDesignerMode: FacadeDesignerModes;
}
const FacadeDesignerWindows = ({
  placedWindows,
  windowsData,
  scale,
  wallData,
  unitSystem,
  wallHeight,
  facadeDesignerMode,
  isWindowPlacementProcessing,
}: ExistingWindowsProps) => {
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data!.buildings!;
  const dispatch = useAppDispatch();
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const hoveredPlacedWindow = useAppSelector(getHoveredPlacedWindow);
  const { updateBuildingPlacements } = useUpdateUserBuildingData();

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    updateBuildingPlacements({
      buildingGUID: userBuildings[0].guid,
      placementData: [
        {
          wallGUID: wallData.guid,
          wallPanelErrors: wallData.wallPanelErrors,
          wallPanels: wallData.wallPanels,
          windowPlacements: placedWindows.map((pw) =>
            pw.guid === selectedPlacedWindows[0].guid
              ? { ...pw, offsetFromLeftEdge: points[0][1][0] }
              : pw
          ),
        },
      ],
      triggerBEUpdate: false,
    });
  };

  const handleMeasurementEscape = () => {
    dispatch(setSelectedPlacedWindow([]));

    updateBuildingPlacements({
      buildingGUID: userBuildings[0].guid,
      placementData: [
        {
          wallGUID: wallData.guid,
          wallPanelErrors: wallData.wallPanelErrors,
          wallPanels: wallData.wallPanels,
          windowPlacements: placedWindows.map((pw) =>
            pw.guid === selectedPlacedWindows[0].guid
              ? {
                  ...pw,
                  offsetFromLeftEdge:
                    selectedPlacedWindows[0].offsetFromLeftEdge,
                }
              : pw
          ),
        },
      ],
      triggerBEUpdate: false,
    });
  };

  const handleMeasurementSubmit = () => {
    updateBuildingPlacements({
      buildingGUID: userBuildings[0].guid,
      placementData: [
        {
          wallGUID: wallData.guid,
          wallPanelErrors: [],
          wallPanels: [],
        },
      ],
    });
    dispatch(setSelectedPlacedWindow([]));
  };

  return (
    <>
      {placedWindows.map((placedWindowData, idx) => {
        const savedWindowData = windowsData.find(
          (_w) => _w.id === placedWindowData.windowId
        );
        if (!savedWindowData) return null;
        return (
          <FacadeDesignerWindow
            key={idx}
            unitSystem={unitSystem}
            scale={scale}
            wallHeight={wallHeight}
            isSelected={selectedPlacedWindows.some(
              (spw) => spw.guid === placedWindowData.guid
            )}
            savedWindowData={savedWindowData}
            placedWindowData={placedWindowData}
            facadeDesignerMode={facadeDesignerMode}
            isWindowPlacementProcessing={isWindowPlacementProcessing}
            hoveredPlacedWindow={hoveredPlacedWindow}
            wallData={wallData}
            onMeasurementChange={handleMeasurementChange}
            onMeasurementEscape={handleMeasurementEscape}
            onMeasurementSubmit={handleMeasurementSubmit}
          />
        );
      })}
    </>
  );
};

export default FacadeDesignerWindows;
