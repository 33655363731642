import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useThree } from '@react-three/fiber';
import {
  addNodesToSelectNodes,
  getSelectedNodes,
  removeFromSelectedNodeArray,
  selectNodes,
} from '@/store/slices/canvasBuildingSlice';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useEffect, useState } from 'react';
import * as THREE from 'three';
import {
  getCursorCoordinatesOnOrthographicSystem,
  isLeftClick,
} from '@/shared/helpers';
import {
  CanvasActionsModes,
  NodeType,
  SelectedNodeSource,
  UserBuildingWall,
} from '@/models';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';
import { getIsCameraRotating } from '@/store/slices/canvasCamerasSlice';

export const useSelectionHandlers = (
  selectionMode:
    | CanvasActionsModes.selection
    | CanvasActionsModes.facadeDesigner
) => {
  const dispatch = useAppDispatch();
  const { raycaster, scene, gl, camera } = useThree();

  const isCameraRotating = useAppSelector(getIsCameraRotating);
  const selectedGuids = Object.keys(useAppSelector(getSelectedNodes));
  const isSelectionMode = selectionMode === CanvasActionsModes.selection;

  const { getFacadeWallsByWallGuid, getFacadeContourLines } = useFacadeData();
  const { selectNode } = useSelectedNodes();
  const { setHoveredNode, resetHoveredNode } = useHoveredNode();

  const [tabPressed, setTabPressed] = useState(false);
  const [facadeGuids, setFacadeGuids] = useState<string[]>([]);
  const [hoveredWallGuid, setHoveredWallGuid] = useState<string | undefined>();
  const [facadeContour, setFacadeContour] = useState<THREE.Group>(
    new THREE.Group()
  );
  const [isCursorClicked, setIsCursorClicked] = useState(false);

  const notAllowedElementsForSelection = [
    NodeType.Window,
    NodeType.Panel,
    NodeType.Environment,
  ];

  const getObjectUnderMouse = (event: PointerEvent) => {
    const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
      event,
      gl
    );

    raycaster.setFromCamera(cursorCoordinates, camera);

    const intersectsFirst = raycaster
      .intersectObjects(scene.children, true)
      .filter(
        (intersect) =>
          !notAllowedElementsForSelection.includes(
            intersect.object.userData.nodeType
          )
      )[0];

    if (
      !intersectsFirst ||
      (!isSelectionMode &&
        intersectsFirst.object?.userData?.nodeType !== NodeType.Wall)
    )
      return;

    return intersectsFirst.object.userData?.guid
      ? {
          guid: intersectsFirst.object.userData?.guid,
          isLocked: intersectsFirst.object.userData?.isLocked,
          isSelected: intersectsFirst.object.userData?.isSelected,
          nodeType: intersectsFirst.object.userData?.nodeType,
        }
      : undefined;
  };

  const checkIsObjectIsWallAndIncludedInFacade = (
    wallGuid: string,
    shiftKey: boolean,
    isWall: boolean
  ) => {
    if (!isWall) return true;
    if (isSelectionMode || !shiftKey) return true;

    return (
      facadeGuids.some((guid) => guid === wallGuid) || facadeGuids.length === 0
    );
  };

  const isFacadeAlreadySelected = (guidFromFacade: string) =>
    selectedGuids.some((selectedGuid) => selectedGuid === guidFromFacade);

  const multiSelectFacade = (facade: UserBuildingWall[], guid: string) => {
    if (isFacadeAlreadySelected(guid)) {
      dispatch(removeFromSelectedNodeArray(facade.map((wall) => wall.guid)));
    } else {
      dispatch(
        addNodesToSelectNodes(
          facade.map((wall) => ({ guid: wall.guid, type: NodeType.Wall }))
        )
      );
    }
  };

  const selectOneFacade = (facade: UserBuildingWall[], guid: string) => {
    if (isFacadeAlreadySelected(guid)) {
      dispatch(removeFromSelectedNodeArray(facade.map((wall) => wall.guid)));
    } else {
      dispatch(
        selectNodes(
          facade.map((wall) => ({ guid: wall.guid, type: NodeType.Wall }))
        )
      );
    }
  };

  const selectFacade = (
    facade: UserBuildingWall[],
    guid: string,
    isMultiSelect: boolean
  ) => {
    if (isMultiSelect) {
      multiSelectFacade(facade, guid);
    } else {
      selectOneFacade(facade, guid);
    }
  };

  const handlePointerUp = (event: PointerEvent) => {
    setIsCursorClicked(false);
    if (event.target && (event.target as HTMLElement).tagName !== 'CANVAS')
      return;
    if (!isLeftClick(event) || !isCursorClicked) return;

    const object = getObjectUnderMouse(event);
    const isWall = object?.nodeType === NodeType.Wall;
    if (!object) {
      return;
    }

    if (isWall && (selectedGuids.length === 0 || !event.shiftKey)) {
      const facade = getFacadeWallsByWallGuid(object.guid);
      facade && setFacadeGuids(facade.map((wall) => wall.guid));
    }

    if (isWall && tabPressed) {
      const facade = getFacadeWallsByWallGuid(object.guid);
      if (!facade) return;
      return selectFacade(
        facade,
        object.guid,
        event.shiftKey && isSelectionMode
      );
    }

    if (
      checkIsObjectIsWallAndIncludedInFacade(
        object.guid,
        event.shiftKey,
        isWall
      )
    ) {
      selectNode({
        type: object.nodeType,
        guid: object.guid,
        isLocked: object.isLocked,
        isSelected: object.isSelected,
        ignoreSelectionMode: !isSelectionMode,
        source: SelectedNodeSource.Viewer,
        event,
      });
    }
  };

  const showFacadeContour = (wallGuid: string) => {
    const contourLines = getFacadeContourLines(wallGuid);
    const group = new THREE.Group();

    contourLines.forEach((line) => group.add(line));

    resetHoveredNode();
    setFacadeContour(group);
  };

  const hideFacadeContour = () => setFacadeContour(new THREE.Group());

  const handlePointerMove = (event: PointerEvent) => {
    if (event.target && (event.target as HTMLElement).tagName !== 'CANVAS') {
      return resetHoveredNode();
    }

    const object = getObjectUnderMouse(event);
    const isWall = object?.nodeType === NodeType.Wall;
    if (!object) {
      hoveredWallGuid && setHoveredWallGuid('');
      return resetHoveredNode();
    }

    if (
      object.guid !== hoveredWallGuid &&
      checkIsObjectIsWallAndIncludedInFacade(
        object.guid,
        event.shiftKey,
        isWall
      )
    ) {
      isWall
        ? setHoveredWallGuid(object.guid)
        : setHoveredNode({ nodeGUID: object.guid });
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      if (!tabPressed && hoveredWallGuid) {
        setTabPressed(true);
      }
    }

    if (event.shiftKey && hoveredWallGuid) {
      checkIsObjectIsWallAndIncludedInFacade(
        hoveredWallGuid,
        event.shiftKey,
        true
      )
        ? setHoveredNode({
            nodeGUID: hoveredWallGuid ?? '',
            nodeType: NodeType.Wall,
          })
        : resetHoveredNode();
    }
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      setTabPressed(false);
      hoveredWallGuid &&
        setHoveredNode({
          nodeGUID: hoveredWallGuid ?? '',
          nodeType: NodeType.Wall,
        });
      hideFacadeContour();
    }
    if (!event.shiftKey && hoveredWallGuid) {
      checkIsObjectIsWallAndIncludedInFacade(
        hoveredWallGuid,
        event.shiftKey,
        true
      )
        ? setHoveredNode({
            nodeGUID: hoveredWallGuid,
            nodeType: NodeType.Wall,
          })
        : resetHoveredNode();
    }
  };

  const handlePointerDown = () => {
    setIsCursorClicked(true);
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    if (!canvas) return;

    !isCameraRotating && canvas.addEventListener('pointerup', handlePointerUp);
    canvas.addEventListener('pointerdown', handlePointerDown);
    !isCameraRotating &&
      canvas.addEventListener('pointermove', handlePointerMove);

    return () => {
      canvas.removeEventListener('pointerup', handlePointerUp);
      canvas.removeEventListener('pointerdown', handlePointerDown);
      canvas.removeEventListener('pointermove', handlePointerMove);
    };
  }, [
    tabPressed,
    facadeGuids,
    hoveredWallGuid,
    isCameraRotating,
    selectedGuids,
    isCursorClicked,
  ]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [tabPressed, hoveredWallGuid]);

  useEffect(() => {
    hoveredWallGuid
      ? setHoveredNode({
          nodeGUID: hoveredWallGuid,
          nodeType: NodeType.Wall,
          isLocked: false,
          source: SelectedNodeSource.Viewer,
        })
      : resetHoveredNode();
  }, [hoveredWallGuid]);

  useEffect(() => {
    selectedGuids && setFacadeGuids(selectedGuids);
  }, []);

  useEffect(() => {
    tabPressed && hoveredWallGuid && showFacadeContour(hoveredWallGuid);
    !tabPressed && hideFacadeContour();
  }, [hoveredWallGuid, tabPressed]);

  return { facadeContour };
};
