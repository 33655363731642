import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import {
  CanvasCamera,
  CanvasCameraCenterMode,
  CanvasCameraPolarAngles,
  CanvasCameraType,
} from '@/models';

interface CanvasCameraState {
  cameraPolarAngle: CanvasCameraPolarAngles;
  cameraCenterPosition: CanvasCameraCenterMode;
  appliedCamera?: CanvasCamera;
  cameraDirection: {
    theta: number;
  };
  cameraType: CanvasCameraType;
  isCameraRotating: boolean;
}

const initialState: CanvasCameraState = {
  cameraPolarAngle: CanvasCameraPolarAngles.defaultView,
  cameraCenterPosition: CanvasCameraCenterMode.custom,
  cameraDirection: { theta: 0 },
  cameraType: CanvasCameraType.Orthographic,
  isCameraRotating: false,
};

export const canvasCamerasSlice = createSlice({
  name: 'canvasCamera',
  initialState,
  reducers: {
    setCameraPolarAngle: (
      state,
      action: PayloadAction<CanvasCameraPolarAngles>
    ) => {
      state.cameraPolarAngle = action.payload;
    },
    setCameraCenterPosition: (
      state,
      action: PayloadAction<CanvasCameraCenterMode>
    ) => {
      state.cameraCenterPosition = action.payload;
    },
    resetCameraPolarAngle: (state) => {
      state.cameraPolarAngle = CanvasCameraPolarAngles.customView;
    },
    setAppliedCamera: (state, action: PayloadAction<CanvasCamera>) => {
      state.appliedCamera = action.payload;
    },
    resetAppliedCamera: (state) => {
      state.appliedCamera = undefined;
    },
    setCameraDirection: (state, action: PayloadAction<{ theta: number }>) => {
      state.cameraDirection = action.payload;
    },
    setCameraType: (state, action: PayloadAction<CanvasCameraType>) => {
      state.cameraType = action.payload;
    },
    setIsCameraRotating: (state, action: PayloadAction<boolean>) => {
      state.isCameraRotating = action.payload;
    },
  },
});

export const {
  setCameraPolarAngle,
  resetCameraPolarAngle,
  setCameraCenterPosition,
  setAppliedCamera,
  resetAppliedCamera,
  setCameraDirection,
  setCameraType,
  setIsCameraRotating,
} = canvasCamerasSlice.actions;

export const getCameraPolarAngle = (state: RootState) =>
  state.canvasReducer.canvasCamera.cameraPolarAngle;

export const getCameraCenterPosition = (state: RootState) =>
  state.canvasReducer.canvasCamera.cameraCenterPosition;

export const getAppliedCamera = (state: RootState) =>
  state.canvasReducer.canvasCamera.appliedCamera;

export const getCameraDirection = (state: RootState) =>
  state.canvasReducer.canvasCamera.cameraDirection;

export const getCameraType = (state: RootState) =>
  state.canvasReducer.canvasCamera.cameraType;

export const getIsCameraRotating = (state: RootState) =>
  state.canvasReducer.canvasCamera.isCameraRotating;
