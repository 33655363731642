import React, { useEffect, useState } from 'react';
import useMouseOverCanvas from '@/shared/hooks/useMouseOverCanvas';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';

interface ShortcutTooltipProps {
  hints: React.ReactNode[];
  footerHints: React.ReactNode[];
  showOnlyOnCanvas?: boolean;
}

const ShortcutTooltip: React.FC<ShortcutTooltipProps> = ({
  hints,
  footerHints,
  showOnlyOnCanvas = false,
}) => {
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );
  useMouseOverCanvas(setPosition);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!showOnlyOnCanvas) return;
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    canvas?.addEventListener('mouseenter', () => setIsVisible(true));
    canvas?.addEventListener('mouseleave', () => setIsVisible(false));

    return () => {
      canvas?.removeEventListener('mouseenter', () => setIsVisible(true));
      canvas?.removeEventListener('mouseleave', () => setIsVisible(false));
    };
  }, []);

  if (!position || !isVisible) return <></>;

  return (
    <div
      className="absolute rounded-lg border border-solid border-light-gray-40 bg-light-gray-10"
      style={{
        display: isVisible ? 'block' : 'none',
        top: position.y - 35,
        left: position.x + 17,
      }}
    >
      <div className="border-0 border-b border-solid border-light-gray-40 box-border">
        <div className="p-[10px] flex flex-col gap-1">
          {hints.map((hint) => hint)}
        </div>
      </div>
      <div className="min-h-7 box-border pl-[10px] flex items-center rounded-b-lg bg-light-gray-20 border-0 border-b border-solid border-light-gray-40">
        {footerHints?.map((hint) => hint)}
      </div>
    </div>
  );
};

export default ShortcutTooltip;
