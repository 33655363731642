import React from 'react';
import {
  getColorDescriptionByName,
  getHexByName,
} from '@/components/WindowCreator/helpers/config';
import { WindowColor } from '@/models/window-configurator.model';
import { IntusTooltip } from '@/shared/elements';

interface UsedColorsPaletteProps {
  paletteName: string;
  colors: string[];
  colorsData: WindowColor[];
}

const UsedColorsPalette: React.FC<UsedColorsPaletteProps> = ({
  colorsData,
  colors,
  paletteName,
}) => {
  return (
    <div className="font-light leading-5 mb-1 px-3 flex justify-between items-center">
      <span>{paletteName}</span>
      <div className={'flex'}>
        {colors.map((color) => (
          <IntusTooltip
            key={color}
            placement={'bottom'}
            title={getColorDescriptionByName(colorsData, color)}
            zIndex={50}
          >
            <div
              className={`rounded-full w-6 h-6 border border-solid border-light-gray-20 ml-[-12px] hover:z-[100] hover:border-light-green-100`}
              style={{ backgroundColor: getHexByName(colorsData, color) }}
            />
          </IntusTooltip>
        ))}
      </div>
    </div>
  );
};

export default UsedColorsPalette;
