import React, { FC } from 'react';

interface QuoteFacadesProps {
  snapshots: {
    url: string;
    index: number;
  }[];
}

const QuoteFacades: FC<QuoteFacadesProps> = ({ snapshots }) => {
  return (
    <div className="h-full w-full flex relative flex-col">
      <div className="px-6 py-9 box-border grid grid-cols-2 grid-rows-2 relative gap-3 bg-[#ECEEEE] flex-1 overflow-hidden">
        {snapshots.map((snapshot, index) => {
          return (
            <div
              key={index}
              className="overflow-hidden flex flex-col justify-between items-start w-full h-full gap-2"
            >
              <img
                src={snapshot.url}
                alt={`Snapshot ${snapshot.index}`}
                className="max-w-full max-h-[90%]"
              />
              <h3 className="m-0 leading-6 font-normal text-xs self-start">
                Facade {snapshot.index}
              </h3>
            </div>
          );
        })}
        <div className="absolute bottom-0 left-0 bg-[#F9F9F9] px-2 py-1 font-light leading-4 text-[10px] text-dark-gray-100">
          Areas marked in red are out of scope and not included into quote
        </div>
      </div>
    </div>
  );
};

export default QuoteFacades;
