import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ReportStatus } from '@/models/report';

interface ReportState {
  projects: {
    [key: string]: {
      status: ReportStatus;
      errorMessage: string;
    };
  };
}

const initialState: ReportState = {
  projects: {},
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setStatusReport: (
      state,
      action: PayloadAction<{
        id: string;
        status: ReportStatus;
      }>
    ) => {
      state.projects[action.payload.id] = {
        status: action.payload.status,
        errorMessage: state.projects[action.payload.id]?.errorMessage,
      };
    },
    setErrorMessageReport: (
      state,
      action: PayloadAction<{
        id: string;
        errorMessage: string;
      }>
    ) => {
      state.projects[action.payload.id] = {
        status: state.projects[action.payload.id]?.status,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const { setStatusReport, setErrorMessageReport } = reportSlice.actions;

export const getStatusReport = (state: RootState, id: string) =>
  state.canvasReducer.report.projects?.[id]?.status;

export const getErrorMessageReport = (state: RootState, id: string) =>
  state.canvasReducer.report.projects?.[id]?.errorMessage;
