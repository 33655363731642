import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';

interface SharedState {
  measurementErrors: string[];
}

const initialState: SharedState = {
  measurementErrors: [],
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setMeasurementErrors: (state, action: PayloadAction<string[]>) => {
      state.measurementErrors = action.payload;
    },
  },
});

export const { setMeasurementErrors } = sharedSlice.actions;

export const getMeasurementErrors = (state: RootState) =>
  state.canvasReducer.shared.measurementErrors;
