import React, { ReactNode } from 'react';

import { REPORT_HEIGHT, REPORT_WIDTH } from '@/models/report';

const QuoteWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={`flex justify-between bg-white`}
      style={{
        width: `${REPORT_WIDTH}px`,
        height: `${REPORT_HEIGHT}px`,
      }}
    >
      {children}
    </div>
  );
};

export default QuoteWrapper;
