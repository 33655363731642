import React, { useMemo } from 'react';
import { WindowPlacementData } from '@/models';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  getDragNode,
  resetSelectedGridLines,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { KonvaEventObject } from 'konva/lib/Node';
import { isLeftClick } from '@/shared/helpers';
import { Group } from 'react-konva';
import WallView from '@/components/FacadeDesigner/elements/WallView';
import { WindowPlacementDataForFD } from '../models';
import FacadeDesignerConstructionElements from '@/components/FacadeDesigner/elements/FacadeDesignerConstructionElements';
import FacadeDesignerElementPlacement from '@/components/FacadeDesigner/elements/FacadeDesignerElementPlacement';

interface FacadeDesignerFloorViewProps {
  wallOffset: { x: number; y: number };
  wallData: WallSearchResults;
  wallHeight: number;
  wallWidth: number;
  scale: number;
  onMeasurementUpdate: (data: WindowPlacementData[]) => void;
  onAddWindow: (data: WindowPlacementData[]) => void;
  facadeDesignerMode: FacadeDesignerModes;
  onGridPlacement: () => void;
  reportView?: boolean;
  handleWindowDragEnd?: (newOffset: number) => void;
}
const FacadeDesignerWallView = ({
  wallOffset,
  wallData,
  scale,
  wallHeight,
  wallWidth,
  onAddWindow,
  onGridPlacement,
  handleWindowDragEnd,
  facadeDesignerMode,
  reportView,
}: FacadeDesignerFloorViewProps) => {
  const dispatch = useAppDispatch();

  const dragNode = useAppSelector(getDragNode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;
  const isElementEditingMode =
    [
      FacadeDesignerModes.GridLinePlacement,
      FacadeDesignerModes.WindowPlacement,
    ].includes(facadeDesignerMode) || dragNode;

  const placedWindows: WindowPlacementDataForFD[] = useMemo(() => {
    return (
      wallData.windowPlacements.map((windowData) => ({
        ...windowData,
        wallGUID: wallData.guid,
      })) || []
    );
  }, [wallData]);

  const resetSelectedWindow = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    e.cancelBubble = true;
    dispatch(setSelectedPlacedWindow([]));
    dispatch(resetSelectedGridLines());
  };

  return (
    <Group offsetX={-wallOffset.x} offsetY={wallOffset.y}>
      <Group onClick={resetSelectedWindow}>
        <WallView
          wallWidth={wallWidth}
          wallHeight={wallHeight}
          scale={scale}
          reportView={reportView}
        />
      </Group>

      <FacadeDesignerConstructionElements
        wallData={wallData}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        scale={scale}
        reportView={reportView}
        facadeDesignerMode={facadeDesignerMode}
        wallOffset={wallOffset}
        placedWindows={placedWindows}
      />
      {isElementEditingMode && (
        <FacadeDesignerElementPlacement
          wallData={wallData}
          wallWidth={wallWidth}
          wallHeight={wallHeight}
          wallOffset={wallOffset}
          scale={scale}
          facadeDesignerMode={facadeDesignerMode}
          onAddWindow={onAddWindow}
          onGridPlacement={onGridPlacement}
          handleWindowDragEnd={handleWindowDragEnd}
          placedWindows={placedWindows}
          reportView={reportView}
        />
      )}
    </Group>
  );
};

export default FacadeDesignerWallView;
