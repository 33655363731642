import React from 'react';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { projectsApi } from '@/store/apis/projectsApi';
import { NotificationService } from '@/shared/services/error-handler.service';
import ShadowButton from '@/shared/elements/ShadowNotification/ShadowButton';
import { RootState } from '@/store';

const undoBuildingDelete = createListenerMiddleware();

interface DeleteBuildingArgs {
  projectId: number;
  guids?: string[];
}

undoBuildingDelete.startListening({
  matcher: isAnyOf(
    projectsApi.endpoints.deleteBuildingBlocks.matchFulfilled,
    projectsApi.endpoints.deleteBuildings.matchFulfilled
  ),
  effect: async (action, listenerApi) => {
    const { projectId, guids } = (
      action.meta as { arg: { originalArgs: DeleteBuildingArgs } }
    ).arg.originalArgs;
    const fetchProjectSelector = projectsApi.endpoints.fetchProject.select(
      projectId.toString()
    );
    const state = listenerApi.getState() as RootState;
    const projectData = fetchProjectSelector(state).data;

    if (projectData?.buildings?.length === 0 && guids) {
      NotificationService.shadow({
        message: 'The building was successfully deleted',
        key: 'building-deleted',
        duration: 5,
        description: (
          <ShadowButton
            onClick={() => {
              listenerApi.dispatch(
                projectsApi.endpoints.undoBuildingDeletion.initiate({
                  projectId,
                })
              );
              NotificationService.close('building-deleted');
            }}
          >
            Undo
          </ShadowButton>
        ),
      });
    }
  },
});

export { undoBuildingDelete };
