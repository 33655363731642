import { UnitSystemTypes, UserBuildingPanel } from '@/models';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import { formatAreaValue } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { round } from 'mathjs';
import { convertSquareMillimetersToSquareMeters } from '../helpers/distance';
import { SavedWindow } from '@/components/WindowCreator/models';
import { OperationType } from '@/models/window-configurator.model';
import { getFacadesArea } from '../helpers/metrics';
import { uniqBy } from 'lodash';

const useBuildingMetrics = () => {
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const countWindowsByPanel = (windowId: number, panel: UserBuildingPanel) => {
    return panel.panelWindows.reduce((acc, window) => {
      return window.id === windowId ? acc + 1 : acc;
    }, 0);
  };

  const getAllWalls = () =>
    projectData.buildings!.flatMap((building) =>
      building.blocks.flatMap((block) =>
        block.storeys.flatMap((storey) => storey.walls)
      )
    );

  const getAllPanels = () => getAllWalls().flatMap((wall) => wall.wallPanels);

  const getAllWindows = () =>
    getAllWalls().flatMap((wall) => wall.windowPlacements);

  const countPanelsInBuildings = (panels: UserBuildingPanel[]) => {
    const uniquePanels = uniqBy(getAllPanels(), 'guid');
    const uniquePanelIds = uniquePanels.map((panel) => panel.panelId);

    const panelsDataInBuilding = uniquePanelIds.map(
      (id) => panels.find((panel) => panel.id === id)!
    );

    const panelsTypeCount = panelsDataInBuilding.reduce(
      (count, panel) => {
        count[panel.isCorner ? 'corner' : 'straight']++;
        return count;
      },
      { corner: 0, straight: 0 }
    );

    return {
      panelsCount: uniquePanelIds.length,
      ...panelsTypeCount,
    };
  };

  const countAmountOfWindowsInBuilding = () =>
    getAllWalls().reduce(
      (total, wall) => total + wall.windowPlacements.length,
      0
    );

  const countKindOfWindows = (windowsLibrary: SavedWindow[]) => {
    const windowIds = getAllWindows().map((window) => window.windowId);

    const windowTypes = windowIds
      .map((id) => windowsLibrary.find((window) => window.id === id))
      .reduce(
        (totalAmount, window) => {
          const hasOperable = window!.innerFrames.some(
            (innerWindow) => innerWindow.operationType !== OperationType.Fixed
          );
          totalAmount[hasOperable ? 'operable' : 'fixed']++;
          return totalAmount;
        },
        { fixed: 0, operable: 0 }
      );

    return windowTypes;
  };
  const countPanelizedAreaData = (panels: UserBuildingPanel[]) => {
    const panelsIdInBuilding = uniqBy(getAllPanels(), 'guid').map(
      (panel) => panel.panelId
    );

    const panelDimensionsInBuilding = panelsIdInBuilding
      .map((id) => panels.find((panel) => panel.id === id)!)
      .map(({ fullWidth, fullHeight, isCorner, fullSideWidth }) => ({
        fullWidth,
        fullHeight,
        isCorner,
        fullSideWidth,
      }));

    const panelizedAreInMillimeters = panelDimensionsInBuilding.reduce(
      (panelizedArea, panel) => {
        const panelArea = panel.isCorner
          ? panel.fullWidth * panel.fullHeight +
            panel.fullSideWidth! * panel.fullHeight
          : panel.fullWidth * panel.fullHeight;
        return panelizedArea + panelArea;
      },
      0
    );

    const panelizedAreaInSquareMeters = round(
      +convertSquareMillimetersToSquareMeters(panelizedAreInMillimeters),
      2
    );
    const wallsData = getAllWalls();

    const facadesAreaInMeters = getFacadesArea({
      walls: wallsData,
      multiplyRate,
    });

    const nonPanelizedAreaInMeters = round(
      +facadesAreaInMeters - panelizedAreaInSquareMeters,
      2
    );
    return {
      panelizedArea: formatAreaValue(
        panelizedAreaInSquareMeters,
        isImperialUnits
      ),
      panelizedAreaUsage: round(
        (panelizedAreaInSquareMeters / facadesAreaInMeters) * 100,
        2
      ),
      nonPanelizedArea: formatAreaValue(
        nonPanelizedAreaInMeters,
        isImperialUnits
      ),
      nonPanelizedAreaUsage: round(
        (nonPanelizedAreaInMeters / facadesAreaInMeters) * 100,
        2
      ),
    };
  };

  const calculateGlazedAreaData = (panels: UserBuildingPanel[]) => {
    const panelsData = getAllPanels().map(({ panelId, isInitialCorner }) => ({
      panelId,
      isInitialCorner,
    }));

    const panelsGlazedArea = round(
      panelsData
        .map(({ panelId, isInitialCorner }) => {
          const panel = panels.find((p) => p.id === panelId)!;
          return isInitialCorner || !panel.isCorner
            ? panel.glazedArea
            : panel.fullCornerGlazedArea - panel.glazedArea;
        })
        .reduce(
          (totalGlazedArea, glazedArea) => totalGlazedArea + glazedArea,
          0
        ),
      2
    );

    const wallsData = projectData.buildings!.flatMap((building) =>
      building.blocks.flatMap((block) =>
        block.storeys.flatMap((storey) => storey.walls)
      )
    );

    const facadesAreaInMeters = getFacadesArea({
      walls: wallsData,
      multiplyRate,
    });

    const nonGlazedAreaInMeters = round(
      +facadesAreaInMeters - panelsGlazedArea,
      2
    );

    return {
      glazedArea: formatAreaValue(panelsGlazedArea, isImperialUnits),
      glazedAreaUsage: round((panelsGlazedArea / facadesAreaInMeters) * 100, 2),
      nonGlazeArea: formatAreaValue(nonGlazedAreaInMeters, isImperialUnits),
      nonGlazeAreaUsage: round(
        (nonGlazedAreaInMeters / facadesAreaInMeters) * 100,
        2
      ),
    };
  };

  const countPanelsById = (): { [key: number]: number } => {
    const uniquePanels = uniqBy(getAllPanels(), 'guid');
    return uniquePanels.reduce(
      (panelCount: { [key: number]: number }, panel) => {
        panelCount[panel.panelId] = (panelCount[panel.panelId] || 0) + 1;
        return panelCount;
      },
      {}
    );
  };

  const panelCount = countPanelsById();

  const calculatePanelArea = (panelData: UserBuildingPanel) => {
    const areaInSquareMeters = convertSquareMillimetersToSquareMeters(
      panelData.isCorner && panelData.sideWidth
        ? panelData.sideWidth * panelData.height +
            panelData.width * panelData.height
        : panelData.width * panelData.height
    );
    return formatAreaValue(Number(areaInSquareMeters), isImperialUnits);
  };

  const calculateNonGlazedAreaInPanel = (
    panelWidth: number,
    panelHeight: number,
    glazedArea: number
  ) => {
    const areaInSquareMeters = Number(
      convertSquareMillimetersToSquareMeters(panelWidth * panelHeight)
    );
    const nonGlazedArea = (areaInSquareMeters - glazedArea).toFixed(2);
    return formatAreaValue(Number(nonGlazedArea), isImperialUnits);
  };

  return {
    countPanelsInBuildings,
    countAmountOfWindowsInBuilding,
    countKindOfWindows,
    countPanelizedAreaData,
    countWindowsByPanel,
    calculateGlazedAreaData,
    panelCount,
    calculatePanelArea,
    calculateNonGlazedAreaInPanel,
  };
};

export default useBuildingMetrics;
