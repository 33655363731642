import React, { useEffect, useMemo, useState } from 'react';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  C_FatLineBorderMaterial,
} from '@/shared/materials';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  createLine2,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { useUnmount } from 'react-use';
import { BufferGeometry } from 'three';

interface BorderProps {
  geometry: BufferGeometry;
  isSelected?: boolean;
  isHovered?: boolean;
}

const Border: React.FC<BorderProps> = ({ geometry, isSelected, isHovered }) => {
  const borderMaterial = useMemo(() => {
    const material = C_FatLineBorderMaterial.clone();
    if (isSelected || isHovered) {
      material.color = BUILDING_SELECTED_CONTOUR_COLOR;
    }
    return material;
  }, [isSelected, isHovered, geometry]);

  const [borderLine, setBorderLine] = useState<Line2>();

  useEffect(() => {
    const points = Array.from(geometry.getAttribute('position').array);
    if (!borderLine) {
      setBorderLine(createLine2(points, borderMaterial));
    } else {
      updateLine2Position(borderLine, points);
    }
    return () => {
      borderLine && borderLine.geometry.dispose();
    };
  }, [geometry.getAttribute('position').array]);

  useEffect(() => {
    if (borderLine) {
      borderLine.material = borderMaterial;
    }
  }, [borderLine, isSelected, isHovered]);

  useUnmount(() => {
    borderLine && borderLine.geometry.dispose();
  });

  if (!borderLine) return null;

  return <primitive object={borderLine} />;
};

export default Border;
