import * as THREE from 'three';
import moment from 'moment';

import { CanvasCamera } from '@/models';
import { RootState } from '@react-three/fiber';
import { gsap } from 'gsap';
import { FOV } from '@/models/camera.model';

type ControlsProto = {
  update(): void;
  target: THREE.Vector3;
};

export function calculateZoomFromPerspectiveCamera(
  perspectiveCamera: THREE.PerspectiveCamera,
  sizeHeight: number
): number {
  const distance = perspectiveCamera.position.length();
  const frustumHeight =
    2 * distance * Math.tan((perspectiveCamera.fov * Math.PI) / 360);

  return sizeHeight / frustumHeight;
}

export function createPerspectiveCamera(
  orthographicCamera: THREE.OrthographicCamera,
  size: { width: number; height: number }
): THREE.PerspectiveCamera {
  const frustumHeight =
    (orthographicCamera.top - orthographicCamera.bottom) /
    orthographicCamera.zoom;
  const distance = orthographicCamera.position.length();
  const fovFactor =
    2 * Math.atan(frustumHeight / (2 * distance)) * (180 / Math.PI);
  const newCamera = new THREE.PerspectiveCamera(FOV, size.width / size.height);

  copyCameraParameters(orthographicCamera, newCamera, (1 / fovFactor) * FOV);

  return newCamera;
}

export function createOrthographicCamera(
  perspectiveCamera: THREE.PerspectiveCamera,
  size: { width: number; height: number },
  initialDistance: number,
  currentDistance: number
): THREE.OrthographicCamera {
  const newCamera = new THREE.OrthographicCamera(
    size.width / -2,
    size.width / 2,
    size.height / 2,
    size.height / -2
  );

  const scaleFactor = initialDistance / currentDistance;

  newCamera.zoom = calculateZoomFromPerspectiveCamera(
    perspectiveCamera,
    size.height
  );

  copyCameraParameters(perspectiveCamera, newCamera, scaleFactor);

  return newCamera;
}

export function copyCameraParameters(
  source: THREE.Camera,
  target: THREE.Camera,
  scaleFactor: number = 1
) {
  target.position.copy(source.position);
  target.quaternion.copy(source.quaternion);

  if (target instanceof THREE.OrthographicCamera) {
    target.position.set(
      source.position.x * scaleFactor,
      source.position.y * scaleFactor,
      source.position.z * scaleFactor
    );
  } else {
    target.position.set(
      source.position.x / scaleFactor,
      source.position.y / scaleFactor,
      source.position.z / scaleFactor
    );
  }

  if (
    target instanceof THREE.PerspectiveCamera ||
    target instanceof THREE.OrthographicCamera
  ) {
    target.updateProjectionMatrix();
  }
}

export function getFormattedDateTime(
  formattedDateTime: string,
  cameras: CanvasCamera[]
) {
  const occurrence = cameras.reduce(
    (acc, camera) => (camera.name.includes(formattedDateTime) ? acc + 1 : acc),
    0
  );

  return occurrence
    ? `${formattedDateTime} (${occurrence})`
    : formattedDateTime;
}

export const getformattedMomentDate = () =>
  moment().format('MMM DD [at] hh:mm A');

export const makeScreenshotDelay = 2000;

export const extractCameraTypeFromCanvas = (get: RootState['get']) => {
  return get().camera.type.replace('Camera', '');
};

const CAMERA_ANIMATION_DURATION = 1.5;

export const centerCamera = ({
  boundingBox,
  camera,
  controls,
  margin = 1.2,
  onCameraAnimationEnd,
}: {
  boundingBox: THREE.Box3;
  camera: THREE.Camera;
  controls: THREE.EventDispatcher<object> | null;
  margin?: number;
  onCameraAnimationEnd?: () => void;
}) => {
  const typedControls = controls as unknown as ControlsProto;

  const newCenter = new THREE.Vector3();
  boundingBox.getCenter(newCenter);

  // Target for camera animation
  const target = { x: newCenter.x, y: newCenter.y, z: newCenter.z };

  if (isOrthographic(camera)) {
    adjustOrthographicCamera({
      camera,
      boundingBox,
      newCenter,
      margin,
      target,
      typedControls,
      onCameraAnimationEnd,
    });
  } else if (isPerspective(camera)) {
    adjustPerspectiveCamera({
      camera,
      boundingBox,
      newCenter,
      target,
      typedControls,
      margin,
      onCameraAnimationEnd,
    });
  }
};

function isOrthographic(
  camera: THREE.Camera
): camera is THREE.OrthographicCamera {
  return (camera as THREE.OrthographicCamera).isOrthographicCamera === true;
}

function isPerspective(
  camera: THREE.Camera
): camera is THREE.PerspectiveCamera {
  return (camera as THREE.PerspectiveCamera).isPerspectiveCamera === true;
}

function adjustOrthographicCamera({
  camera,
  boundingBox,
  newCenter,
  margin,
  target,
  typedControls,
  onCameraAnimationEnd,
}: {
  camera: THREE.OrthographicCamera;
  boundingBox: THREE.Box3;
  newCenter: THREE.Vector3;
  margin: number;
  target: { x: number; y: number; z: number };
  typedControls: ControlsProto;
  onCameraAnimationEnd?: () => void;
}) {
  let maxHeight = 0,
    maxWidth = 0;

  // Vertices of the bounding box
  const vertices = [
    new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
    new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z),
    new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z),
    new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z),
    new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z),
    new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z),
    new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z),
    new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z),
  ];

  // Transforming the coordinates of the new object's center to the camera coordinate system using the camera's inverse world matrix
  newCenter.applyMatrix4(camera.matrixWorldInverse);
  for (const v of vertices) {
    v.applyMatrix4(camera.matrixWorldInverse);
    maxHeight = Math.max(maxHeight, Math.abs(v.y - newCenter.y));
    maxWidth = Math.max(maxWidth, Math.abs(v.x - newCenter.x));
  }

  // This is needed to reduce the distance to the object and fit it into the camera view
  maxHeight *= 2;
  maxWidth *= 2;

  // Calculating the zoom to ensure the entire height and width of the object fits within the camera's viewable space
  const zoomForHeight = (camera.top - camera.bottom) / maxHeight;
  const zoomForWidth = (camera.right - camera.left) / maxWidth;
  const newZoom = Math.min(zoomForHeight, zoomForWidth) / margin;

  gsap.to(camera, {
    duration: CAMERA_ANIMATION_DURATION,
    zoom: newZoom,
    onUpdate: () => {
      camera.updateProjectionMatrix();
    },
  });

  animateCameraLookAt(target, typedControls, onCameraAnimationEnd);
}

function adjustPerspectiveCamera({
  camera,
  boundingBox,
  newCenter,
  target,
  typedControls,
  margin,
  onCameraAnimationEnd,
}: {
  camera: THREE.PerspectiveCamera;
  boundingBox: THREE.Box3;
  newCenter: THREE.Vector3;
  target: { x: number; y: number; z: number };
  typedControls: ControlsProto;
  margin: number;
  onCameraAnimationEnd?: () => void;
}) {
  // Calculating the maximum distance from the object's center to one of its boundaries
  const maxDistance = boundingBox.max.distanceTo(newCenter);

  // Calculating the distance to move the camera back to fit the entire object in the field of view,
  // considering the camera's field of view and the given margin
  const distanceToFit =
    maxDistance / Math.sin((camera.fov * Math.PI) / 360) / margin;

  // Calculating the direction from the current camera position to the new center of the object
  const direction = camera.position.clone().sub(newCenter).normalize();

  // Calculating the new camera position, moved back by the calculated distance along the computed direction
  const newPosition = newCenter
    .clone()
    .add(direction.multiplyScalar(distanceToFit));

  gsap.to(camera.position, {
    duration: CAMERA_ANIMATION_DURATION,
    x: newPosition.x,
    y: newPosition.y,
    z: newPosition.z,
    onUpdate: () => {
      camera.lookAt(target.x, target.y, target.z);
      camera.updateProjectionMatrix();
    },
  });

  animateCameraLookAt(target, typedControls, onCameraAnimationEnd);
}

function animateCameraLookAt(
  target: { x: number; y: number; z: number },
  controls: ControlsProto,
  onCameraAnimationEnd?: () => void
) {
  const animatedTarget = {
    x: controls.target.x,
    y: controls.target.y,
    z: controls.target.z,
  };

  gsap.to(animatedTarget, {
    duration: CAMERA_ANIMATION_DURATION,
    x: target.x,
    y: target.y,
    z: target.z,
    onUpdate: () => {
      controls.target.set(animatedTarget.x, animatedTarget.y, animatedTarget.z);
      controls.update();
    },
    onComplete: () => {
      onCameraAnimationEnd && onCameraAnimationEnd();
    },
  });
}
