import React, { useMemo } from 'react';
import { EnvironmentObject, NodeType } from '@/models';
import {
  generateBuildingGeometry,
  generateBuildingShape,
  getBBoxCenter,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { GeolibGeoJSONPoint } from 'geolib/es/types';
import { BBox } from '@turf/turf';
import * as THREE from 'three';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  SELECTED_BUILDING_MATERIAL_COLOR,
} from '@/shared/materials';
import { useAppSelector } from '@/store/hooks';
import {
  getIsNodeHovered,
  getIsNodeSelected,
} from '@/store/slices/canvasBuildingSlice';

const ProjectCanvasBuilding = ({
  envBuilding,
  mapBoundingBox,
  isParentSelected,
  isParentLocked,
}: {
  envBuilding: EnvironmentObject;
  mapBoundingBox: BBox;
  isParentSelected: boolean;
  isParentLocked?: boolean;
}) => {
  if (envBuilding?.userData?.isHidden) return null;

  const isEnvBuildingSelected = useAppSelector(
    getIsNodeSelected(envBuilding.guid)
  );

  const mapCenter: GeolibGeoJSONPoint = getBBoxCenter(mapBoundingBox);

  const isCurrentNodeHovered = useAppSelector(
    getIsNodeHovered(envBuilding.guid)
  );

  const buildingShape = useMemo(
    () => generateBuildingShape(envBuilding.coordinates, mapCenter),
    []
  );
  const buildingGeometry = useMemo(
    () => generateBuildingGeometry(buildingShape, envBuilding.levelsCount || 1),
    []
  );

  const contourEdges = useMemo(() => {
    const edgeGeometry = new THREE.EdgesGeometry(buildingGeometry);
    edgeGeometry.computeBoundingBox();
    return edgeGeometry;
  }, [buildingGeometry]);

  const contourMaterial = useMemo(() => {
    const material = new THREE.LineBasicMaterial({
      color: BUILDING_SELECTED_CONTOUR_COLOR,
    });
    material.colorWrite = isCurrentNodeHovered;
    return material;
  }, [isCurrentNodeHovered]);

  return (
    <>
      <mesh
        castShadow
        key={buildingGeometry.uuid}
        geometry={buildingGeometry}
        userData={{
          ...envBuilding,
          nodeType: NodeType.SurroundingBuilding,
          guid: envBuilding.guid,
          isLocked: isParentLocked || envBuilding.userData?.isLocked,
          isSelected: isEnvBuildingSelected || isParentSelected,
        }}
      >
        <meshStandardMaterial
          color={
            isEnvBuildingSelected || isParentSelected
              ? SELECTED_BUILDING_MATERIAL_COLOR
              : '#b4b4b4'
          }
        />
        <lineSegments geometry={contourEdges} material={contourMaterial} />
      </mesh>
    </>
  );
};

export default ProjectCanvasBuilding;
