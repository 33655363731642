import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';
import { QuoteRequestData } from '@/models/quote.model';

const blueScreenApi = createApi({
  reducerPath: 'bluescreen',
  tagTypes: ['DraftProject'],
  baseQuery,
  endpoints: (build) => ({
    fetchProjectByDraftId: build.query<string, string>({
      providesTags: ['DraftProject'],
      query: (draftId) => ({
        url: `/bluescreen/${draftId}`,
        method: 'GET',
      }),
    }),
    sendDataForQuote: build.mutation<
      void,
      { draftId: string; data: QuoteRequestData }
    >({
      query: ({ draftId, data }) => ({
        url: `/bluescreen/${draftId}/quote`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useFetchProjectByDraftIdQuery, useSendDataForQuoteMutation } =
  blueScreenApi;

export { blueScreenApi };
