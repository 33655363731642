import InnerWindow from '@/components/WindowCreator/elements/InnerWindow/InnerWindow';
import { View } from '@/components/WindowCreator/models';
import { PanelInnerFrame } from '@/models';
import { InnerWindowData } from '@/models/window-configurator.model';
import { getOperationTypeWidth } from '@/shared/helpers';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import React from 'react';
import { Group } from 'react-konva';

interface PanelWindowViewProps {
  scale: number;
  offsetX: number;
  offsetY: number;
  windowInnerFrames: PanelInnerFrame[];
  panelCenterX: number;
  panelCenterY: number;
}

const PanelWindowView: React.FC<PanelWindowViewProps> = ({
  offsetX,
  offsetY,
  scale,
  windowInnerFrames,
  panelCenterX,
  panelCenterY,
}) => {
  const correctedPanelCenterX = panelCenterX - offsetX;
  const correctedPanelCenterY = panelCenterY - offsetY;
  const config = useFetchWindowConfigQuery().data!;

  const calculateInnerWindowPoints = (
    frame: PanelInnerFrame,
    panelCenterX: number,
    panelCenterY: number
  ): [number, number][] => {
    const operationTypeWidth = getOperationTypeWidth(
      config.operationTypes,
      frame.operationType
    );
    const halfWidth =
      (frame.width + operationTypeWidth + config.panel.sideWidth) / 2;
    const halfHeight =
      (frame.height + operationTypeWidth + config.panel.topWidth) / 2;

    const centerX = panelCenterX + frame.x;
    const centerY = panelCenterY - frame.y;

    return [
      [centerX - halfWidth, centerY - halfHeight],
      [centerX + halfWidth, centerY - halfHeight],
      [centerX + halfWidth, centerY + halfHeight],
      [centerX - halfWidth, centerY + halfHeight],
    ];
  };

  return (
    <Group y={offsetY} x={offsetX} listening={false}>
      {windowInnerFrames.map((windowData, i) => {
        const windowsData: InnerWindowData = {
          points: calculateInnerWindowPoints(
            windowData,
            correctedPanelCenterX,
            correctedPanelCenterY
          ),
          operationType: windowData.operationType,
          iguId: windowData.iguId,
        };

        return (
          <InnerWindow
            view={View.Outside}
            windowData={windowsData}
            outerFramePoints={calculateInnerWindowPoints(
              windowData,
              correctedPanelCenterX,
              correctedPanelCenterY
            )}
            scale={scale}
            frameColor={windowData.outerFrameColor}
            key={i}
          />
        );
      })}
    </Group>
  );
};

export default PanelWindowView;
