import React from 'react';
import InnerWindow from '@/components/WindowCreator/elements/InnerWindow/InnerWindow';
import { Mullion } from '@/components/WindowCreator/elements/Mullion';
import OuterFrame from '@/components/WindowCreator/elements/OuterFrame';
import { Group } from 'react-konva';
import {
  SavedWindow,
  View,
  WindowCreatorFormData,
} from '@/components/WindowCreator/models/konva-model';
import { UnitSystemTypes } from '@/models';
import { MullionData } from '@/models/window-configurator.model';
import { WindowCreatorModes } from '@/models/shared.model';
import { WINDOW_CREATOR_VIEW } from '@/shared/constants';

interface WindowViewProps {
  data: SavedWindow | WindowCreatorFormData;
  scale: number;
  units: UnitSystemTypes;
  view?: View;
  onDeleteMullionSubmit?: (data: MullionData) => void;

  // For window placement only
  offsetX?: number;
  offsetY?: number;
  viewOnly?: boolean;
  mode?: WindowCreatorModes;
  listening?: boolean;
}

const WindowView: React.FC<WindowViewProps> = ({
  data,
  scale,
  units,
  onDeleteMullionSubmit,
  viewOnly = false,
  offsetX,
  offsetY,
  mode,
  view = View.Outside,
  listening,
}) => {
  return (
    <Group
      y={typeof offsetY === 'number' ? offsetY - data.points[2][1] : 0}
      x={typeof offsetX === 'number' ? offsetX - data.points[0][0] : 0}
      id={WINDOW_CREATOR_VIEW}
      listening={listening}
    >
      {data.innerFrames.length > 1 &&
        data.innerFrames.map((windowData, i) => (
          <InnerWindow
            view={view ?? View.Outside}
            windowData={windowData}
            outerFramePoints={data.points}
            units={units}
            scale={scale}
            frameColor={
              view === View.Inside ? data.innerColor : data.outerColor
            }
            key={i}
          />
        ))}
      {data.mullions.map((mullion, idx) => (
        <Mullion
          color={view === View.Inside ? data.innerColor : data.outerColor}
          framePoints={data.points}
          key={`mullion_${idx}`}
          mullionData={mullion}
          onDeleteMullionSubmit={onDeleteMullionSubmit}
        />
      ))}
      <OuterFrame
        data={data as SavedWindow}
        scale={scale}
        viewOnly={viewOnly}
        units={units}
        mode={mode}
        view={view ?? View.Outside}
      />
    </Group>
  );
};

export default WindowView;
