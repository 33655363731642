import React from 'react';

import { UserBuildingPanel } from '@/models';
import { Group, Rect } from 'react-konva';
import PanelView from './PanelView';

interface PanelViewReportProps {
  panelInfo: UserBuildingPanel;
  scale: number;
  showPanelCellNumbers?: boolean;
}

const PanelViewReport: React.FC<PanelViewReportProps> = ({
  panelInfo,
  scale,
  showPanelCellNumbers,
}) => {
  if (!panelInfo) return null;
  const dynamicPadding = 1 / scale;

  const panelWidth =
    panelInfo.isCorner && panelInfo.sideWidth
      ? panelInfo.sideWidth + panelInfo.width
      : panelInfo.width;

  return (
    <Group id={`panel-${panelInfo.id}`} x={-99999}>
      {/* We use this rect in order to add padding for snapshot, in some cases toImage method crop borders */}

      <Rect
        x={-dynamicPadding}
        y={-dynamicPadding}
        width={panelWidth + dynamicPadding * 2}
        height={panelInfo.height + dynamicPadding * 2}
        fill="transparent"
      />

      {panelInfo.isCorner && panelInfo.sideWidth && (
        <PanelView
          scale={scale}
          panelWidth={panelInfo.sideWidth}
          panelInfo={panelInfo}
          reportView
          showPanelCellNumbers={showPanelCellNumbers}
        />
      )}
      <PanelView
        scale={scale}
        offsetX={panelInfo.sideWidth ?? 0}
        panelWidth={panelInfo.width}
        panelInfo={panelInfo}
        reportView
        showPanelCellNumbers={showPanelCellNumbers}
      />
    </Group>
  );
};

export default PanelViewReport;
