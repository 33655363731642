import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { QuoteStatus } from '@/models/quote.model';

interface QuoteState {
  status: QuoteStatus;
  errorMessage: string;
}

const initialState: QuoteState = {
  status: QuoteStatus.DEFAULT,
  errorMessage: '',
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuoteRequestStatus: (state, { payload }: PayloadAction<QuoteStatus>) => {
      state.status = payload;
    },
    setQuoteRequestErrorMessage: (
      state,
      { payload }: PayloadAction<QuoteStatus>
    ) => {
      state.errorMessage = payload;
    },
  },
});

export const { setQuoteRequestStatus, setQuoteRequestErrorMessage } =
  quoteSlice.actions;

export const getQuoteRequestStatusReport = (state: RootState) =>
  state.canvasReducer.quote.status;

export const getQuoteRequestStatusErrorMessage = (state: RootState) =>
  state.canvasReducer.quote.errorMessage;
