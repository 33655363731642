import { IntusButton } from '@/shared/elements';
import { CanvasActionsModes, DrawModes } from '@/models';
import {
  CubeIcon,
  CursorIcon,
  FacadeDesignerIcon,
  FreeDrawIcon,
  WindowLibraryIcon,
} from '@/shared/icons';
import { RectangleDrawIcon } from '@/shared/icons/RectangleDrawIcon';
import { CenterLineIcon } from '@/shared/icons/CenterLineIcon';
import React, { useEffect, useRef, useState } from 'react';
import {
  setDrawMode,
  setIsInDrawMode,
  setMode,
} from '@/store/slices/canvasModesSlice';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIsMapLoaded } from '@/store/slices/canvasMapSlice';
import { useOutsideMouseDown } from '@/shared/hooks';
import WindowModal from '@/components/WindowCreator/WindowModal';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { subscribe, unsubscribe } from '@/core/events';
import { OPEN_WINDOW_CREATOR } from '@/core/event-names';
import { clearSelectedNodes } from '@/store/slices/canvasBuildingSlice';
import { resetExternalElementsState } from '@/store/slices/canvasExternalElementsSlice';

interface ProjectHeaderToolbarProps {
  mode: CanvasActionsModes;
  drawMode: DrawModes;
  projectId: string;
}

const ProjectHeaderToolbar = ({
  mode,
  drawMode,
  projectId,
}: ProjectHeaderToolbarProps) => {
  const data = useFetchProjectQuery(projectId).data;
  const loadedWindowConfig = !!useFetchWindowConfigQuery().data;
  const isMapLoaded = useAppSelector(getIsMapLoaded);
  const isDesignerMode = mode === CanvasActionsModes.facadeDesigner;
  const buildingModesRef = useRef<HTMLDivElement>(null!);
  const dispatch = useAppDispatch();

  const [showBuildingModeOptions, setShowBuildingModeOptions] = useState(false);
  const [showWindowCreator, setShowWindowCreator] = useState(false);

  const isUserBuildingExist = !!data?.buildings?.length;
  const isLocked = data?.locked;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mode]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (
        mode === CanvasActionsModes.createWindow ||
        mode === CanvasActionsModes.facadeDesigner
      )
        return;
      setShowBuildingModeOptions(false);
      dispatch(setMode(CanvasActionsModes.selection));
    }
  };

  useOutsideMouseDown(buildingModesRef, () =>
    setShowBuildingModeOptions(false)
  );
  const getActiveFill = (expectedMode: DrawModes): string =>
    drawMode === expectedMode ? '#fff' : '#414042';

  const getDrawModeIcon = () => {
    switch (drawMode) {
      case DrawModes.Unset:
        return <CubeIcon fill="#414042" className="animate-fastFadeInIcon" />;
      case DrawModes.FreeDraw:
        return <FreeDrawIcon fill={getActiveFill(DrawModes.FreeDraw)} />;
      case DrawModes.Rectangle:
        return <RectangleDrawIcon fill={getActiveFill(DrawModes.Rectangle)} />;
      case DrawModes.CenterLine:
        return <CenterLineIcon fill={getActiveFill(DrawModes.CenterLine)} />;
    }
  };
  const handleDrawModeActivation = (mode: DrawModes) => {
    dispatch(resetExternalElementsState());
    dispatch(setIsInDrawMode(true));
    dispatch(setDrawMode(mode));
    setShowBuildingModeOptions(false);
  };

  const handleWindowCreator = (show: boolean) => {
    setShowWindowCreator(show);
    dispatch(clearSelectedNodes());
  };

  const onModeChange = (mode: CanvasActionsModes) => dispatch(setMode(mode));

  const handleClickBuildingMode = () => {
    setShowBuildingModeOptions(true);
  };

  const buttonClassNames = (buttonMode?: CanvasActionsModes) => {
    const isWindowCreatorMode = buttonMode === CanvasActionsModes.createWindow;
    return `${
      (mode === buttonMode && !showWindowCreator) ||
      (isWindowCreatorMode && showWindowCreator)
        ? '!bg-light-green-100 hover:!bg-dark-green-100'
        : 'hover:!bg-light-gray-1'
    } !w-12 !h-12 !rounded-none !border-none leading-[0px] disabled:hover:!bg-white`;
  };

  const handleFacadeDesigner = () => {
    if (mode !== CanvasActionsModes.facadeDesigner) {
      dispatch(clearSelectedNodes());
      dispatch(setMode(CanvasActionsModes.facadeDesigner));
    }
  };

  const getIconFillColor = (buttonMode: CanvasActionsModes) =>
    mode === buttonMode && !showWindowCreator ? '#fff' : '#414042';

  useEffect(() => {
    subscribe(OPEN_WINDOW_CREATOR, () => handleWindowCreator(true));
    return () => {
      unsubscribe(OPEN_WINDOW_CREATOR, () => handleWindowCreator(true));
    };
  }, []);

  return (
    <div className="flex">
      <IntusButton
        className={buttonClassNames(CanvasActionsModes.selection)}
        onClick={() => onModeChange(CanvasActionsModes.selection)}
        icon={
          <CursorIcon fill={getIconFillColor(CanvasActionsModes.selection)} />
        }
        type="default"
        id="project-header__selection-mode"
      />
      <div className="relative">
        <IntusButton
          className={buttonClassNames(CanvasActionsModes.building)}
          onClick={handleClickBuildingMode}
          icon={getDrawModeIcon()}
          type="default"
          disabled={isUserBuildingExist || !isMapLoaded || isDesignerMode}
          id="project-header__building-mode"
        />
        {showBuildingModeOptions && (
          <div
            ref={buildingModesRef}
            className="project-header__building-actions absolute bottom-[-112px] left-[-36px] flex flex-col gap-1 p-0.5 z-50 justify-center box-border bg-white items-center rounded-lg"
          >
            <div className="w-full">
              <IntusButton
                small
                rootClassName={'!justify-start'}
                isActive={drawMode === DrawModes.Rectangle}
                onClick={() => handleDrawModeActivation(DrawModes.Rectangle)}
                block
                type="default"
                icon={
                  <RectangleDrawIcon
                    fill={getActiveFill(DrawModes.Rectangle)}
                  />
                }
                id="project-header__buildingMode-rectangle"
              >
                Rectangle
              </IntusButton>
            </div>
            <div className="w-full">
              <IntusButton
                small
                rootClassName={'!justify-start'}
                isActive={drawMode === DrawModes.CenterLine}
                onClick={() => handleDrawModeActivation(DrawModes.CenterLine)}
                block
                type="default"
                icon={
                  <CenterLineIcon fill={getActiveFill(DrawModes.CenterLine)} />
                }
                id="project-header__buildingMode-centerLine"
              >
                Center Line
              </IntusButton>
            </div>
            <div className="w-full">
              <IntusButton
                small
                rootClassName={'!justify-start'}
                isActive={drawMode === DrawModes.FreeDraw}
                onClick={() => handleDrawModeActivation(DrawModes.FreeDraw)}
                block
                type="default"
                icon={<FreeDrawIcon fill={getActiveFill(DrawModes.FreeDraw)} />}
                id="project-header__buildingMode-freeDraw"
              >
                Free Form
              </IntusButton>
            </div>
          </div>
        )}
      </div>

      <IntusButton
        type="default"
        disabled={!loadedWindowConfig || isLocked}
        className={buttonClassNames(CanvasActionsModes.createWindow)}
        onClick={() => handleWindowCreator(true)}
        icon={
          <WindowLibraryIcon fill={showWindowCreator ? '#fff' : '#414042'} />
        }
        id="project-header__create-window"
      />

      <IntusButton
        type="default"
        disabled={!isUserBuildingExist}
        className={buttonClassNames(CanvasActionsModes.facadeDesigner)}
        onClick={handleFacadeDesigner}
        icon={
          <FacadeDesignerIcon
            fill={isDesignerMode && !showWindowCreator ? '#fff' : '#414042'}
          />
        }
      />
      {showWindowCreator && (
        <WindowModal
          isOpen={showWindowCreator}
          setIsOpen={handleWindowCreator}
          projectId={projectId}
        />
      )}
    </div>
  );
};

export default ProjectHeaderToolbar;
