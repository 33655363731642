import * as THREE from 'three';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';

export const BUILDING_SELECTED_CONTOUR_COLOR = new THREE.Color('#0094FF');
export const BUILDING_MATERIAL_COLOR = new THREE.Color('#ffffff');
export const BUILDING_BORDER_COLOR = new THREE.Color('#808285');
export const BUILDING_GRID_LINE_COLOR = new THREE.Color('#CCCDCE');
export const SELECTED_BUILDING_MATERIAL_COLOR = new THREE.Color('#B6C8FF');
export const BUILDING_CREATION_CONTOUR_COLOR = new THREE.Color('#64BC50');
export const LEFT_SPLIT_FACE_COLOR = new THREE.Color('#886D9E');
export const RIGHT_SPLIT_FACE_COLOR = new THREE.Color('#FCCC00');
export const SPLIT_LINE_COLOR = new THREE.Color('#D75958');
export const DEFAULT_DRAWING_FAT_LINE_WIDTH = 0.0013;
export const DEFAULT_BORDER_FAT_LINE_WIDTH = 0.0007;
export const DEFAULT_BORDER_CONTOUR_FAT_LINE_WIDTH = 0.0013;

export const C_FloorMaterial = new THREE.MeshBasicMaterial({
  color: BUILDING_MATERIAL_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
});

export const C_BasicLineMaterial = new THREE.LineBasicMaterial({
  color: '#000',
  toneMapped: false,
});

export const C_LineContourMaterial = new THREE.LineBasicMaterial({
  color: BUILDING_CREATION_CONTOUR_COLOR,
});

export const C_DashedLineMaterial = new THREE.LineDashedMaterial({
  color: BUILDING_CREATION_CONTOUR_COLOR,
  linewidth: 1,
  scale: 1,
  dashSize: 0.02,
  gapSize: 0.02,
});

export const C_MeshBaseColorMaterial = new THREE.MeshBasicMaterial({
  color: BUILDING_CREATION_CONTOUR_COLOR,
  toneMapped: false,
});

export const WALL_OPACITY = 0.7;
export const ROOF_OPACITY = 0.7;

export const C_RoofMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  color: BUILDING_MATERIAL_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
  opacity: WALL_OPACITY,
});

export const C_WallMaterial = new THREE.MeshBasicMaterial({
  color: BUILDING_MATERIAL_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
});

export const C_SurfaceSelectedMaterial = new THREE.MeshBasicMaterial({
  color: SELECTED_BUILDING_MATERIAL_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
});

export const C_WallSelectedMaterial = new THREE.MeshBasicMaterial({
  color: SELECTED_BUILDING_MATERIAL_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
});

export const C_FatLineContourMaterial = new LineMaterial({
  color: BUILDING_CREATION_CONTOUR_COLOR,
  toneMapped: false,
  dashed: false,
  linewidth: DEFAULT_DRAWING_FAT_LINE_WIDTH,
  depthTest: false,
});

export const C_FatLineBorderMaterial = new LineMaterial({
  toneMapped: false,
  dashed: false,
  linewidth: DEFAULT_BORDER_FAT_LINE_WIDTH,
  color: BUILDING_BORDER_COLOR,
  polygonOffset: true,
  polygonOffsetFactor: POLYGON_OFFSET_FACTOR_LEVELS.MEDIUM,
});

export const C_FatLineSelectedBorderMaterial = new LineMaterial({
  toneMapped: false,
  dashed: false,
  linewidth: DEFAULT_BORDER_FAT_LINE_WIDTH,
  color: BUILDING_SELECTED_CONTOUR_COLOR,
  polygonOffset: true,
  polygonOffsetFactor: POLYGON_OFFSET_FACTOR_LEVELS.MEDIUM,
});

export const C_DashedFatLineContourMaterial = new LineMaterial({
  color: BUILDING_CREATION_CONTOUR_COLOR,
  toneMapped: false,
  linewidth: DEFAULT_DRAWING_FAT_LINE_WIDTH,
  dashed: true,
  dashSize: 0.8,
  dashScale: 30,
  depthTest: false,
});

export const C_DashedSplitLine = new LineMaterial({
  color: SPLIT_LINE_COLOR,
  toneMapped: false,
  transparent: true,
  linewidth: DEFAULT_DRAWING_FAT_LINE_WIDTH,
  dashed: true,
  gapSize: 0.1,
  dashSize: 0.1,
  dashScale: 30,
  depthTest: false,
  depthWrite: false,
});

export const C_LeftSplitFaceMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  color: LEFT_SPLIT_FACE_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
  opacity: 0.1,
  depthTest: false,
});

export const C_RightSplitFaceMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  color: RIGHT_SPLIT_FACE_COLOR,
  toneMapped: false,
  side: THREE.DoubleSide,
  opacity: 0.1,
  depthTest: false,
});

export const C_SplitWallMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  color: new THREE.Color('#000000'),
  toneMapped: false,
  side: THREE.DoubleSide,
  opacity: 0,
});
