import React, { useMemo, useState } from 'react';
import * as THREE from 'three';
import { ThreeEvent } from '@react-three/fiber';

import { FlatVector3, UserBuildingBlock, UserBuildingSurface } from '@/models';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import CutSurface from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutSurface';
import CutFace from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutFace';

import { getProcessingEntity } from '@/store/slices/canvasExternalElementsSlice';
import { useAppSelector } from '@/store/hooks';
import {
  generateTopBorderArray,
  generateWallArrayFromBlock,
  translateWallPointsFromBuildingCenter,
} from '@/routes/dashboard/projects/project/UserBuilding/helpers/editing-tools.helpers';
import useMouseOutsideMesh from '@/shared/hooks/useMouseOutsideMesh';
import { useSplitCutDirectionalInput } from '@/routes/dashboard/projects/project/UserBuilding/helpers/useSplitCutDirectionalInput';
import { getIsCameraRotating } from '@/store/slices/canvasCamerasSlice';

interface CutToolProps {
  block: UserBuildingBlock;
  buildingGUID: string;
}

export const MIN_WALL_DISTANCE = 0.001;

const CutTool: React.FC<CutToolProps> = ({ block, buildingGUID }) => {
  const processingEntity = useAppSelector(getProcessingEntity);
  const isCameraRotating = useAppSelector(getIsCameraRotating);

  const [mousePosition, setMousePosition] = useState<THREE.Vector3 | null>(
    null
  );
  const [currentWall, setCurrentWall] = useState<UserBuildingSurface | null>(
    null
  );
  const [cutFaceWallPoints, setCutFaceWallPoints] = useState<FlatVector3[]>();
  const [isAllowedToCut, setIsAllowedToCut] = useState(false);
  const [isMouseOnWall, setIsMouseOnWall] = useState(false);

  const wallArray = useMemo(() => {
    return generateWallArrayFromBlock(block);
  }, [block]);

  const facadeSurfaceGeometry = useMemo(() => {
    return cutFaceWallPoints
      ? createGeometryFromVectorList(
          translateWallPointsFromBuildingCenter(
            convertFlatVector3ToVectors(cutFaceWallPoints),
            block.storeys[0].floor.points
          ),
          'vertical'
        )
      : null;
  }, [cutFaceWallPoints, block]);

  const handleMove = (position: THREE.Vector3, wall?: UserBuildingSurface) => {
    if (processingEntity?.active || isCameraRotating) return;
    !isAllowedToCut && setIsAllowedToCut(true);

    if (wall) {
      const pos = position.clone();
      pos.setY(0);
      const left = convertFlatVector3ToVector(wall.points[0]);
      left.setY(0);
      const right = convertFlatVector3ToVector(wall.points[1]);
      right.setY(0);

      if (
        pos.distanceTo(left) < MIN_WALL_DISTANCE ||
        pos.distanceTo(right) < MIN_WALL_DISTANCE
      ) {
        return;
      }
    }

    wall && setCurrentWall(wall);
    setMousePosition(position);
  };

  const borderEdgeArray = useMemo(
    () => generateTopBorderArray(block, isMouseOnWall),
    [block, isMouseOnWall]
  );

  const borderArray = useMemo(() => {
    const handleMousePosition = (
      wall: UserBuildingSurface,
      mouse: THREE.Vector3
    ) => {
      if (processingEntity?.active) return;
      const start = convertFlatVector3ToVector(wall.points[0]);
      const end = convertFlatVector3ToVector(wall.points[1]);

      const closestPoint = new THREE.Vector3();
      new THREE.Line3(start, end).closestPointToPoint(
        mouse,
        true,
        closestPoint
      );
      handleMove(closestPoint, wall);
    };

    return borderEdgeArray.map((edge) => (
      <primitive
        object={edge.border}
        onPointerMove={(e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          edge.wall && handleMousePosition(edge.wall, e.point);
        }}
        onPointerEnter={() => setIsAllowedToCut(true)}
        key={edge.border.id}
        position={new THREE.Vector3(0, 0.001, 0)}
        visible={false}
      />
    ));
  }, [block, processingEntity]);

  useMouseOutsideMesh({
    meshes: [...wallArray, ...borderEdgeArray.map((a) => a.border)],
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsAllowedToCut(false),
  });

  //add storey shapes
  useMouseOutsideMesh({
    meshes: [...wallArray],
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsMouseOnWall(false),
    onMouseIntersectMeshes: () => !isCameraRotating && setIsMouseOnWall(true),
  });

  useSplitCutDirectionalInput({
    facePoints: cutFaceWallPoints,
    mousePosition,
    isAllowed: isAllowedToCut,
    setMousePosition,
  });

  return (
    <group>
      {borderArray}
      {wallArray.map((edge) => (
        <primitive
          object={edge}
          onPointerMove={(event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation();
            const position = event.pointOnLine ?? event.point;
            handleMove(position, event.object.userData.wallData);
          }}
          key={edge.uuid}
          onPointerEnter={() => setIsAllowedToCut(true)}
          visible={false}
        />
      ))}
      {isAllowedToCut && (
        <>
          <CutSurface
            block={block}
            mousePosition={mousePosition}
            currentWall={currentWall}
            buildingGUID={buildingGUID}
            isAllowedToCut={isAllowedToCut}
            isCameraRotating={isCameraRotating}
          />
          <CutFace
            block={block}
            mousePosition={mousePosition}
            setCutFaceWallPoints={setCutFaceWallPoints}
            cutFaceWallPoints={cutFaceWallPoints}
            activeWall={currentWall}
            isCameraRotating={isCameraRotating}
          />
          {facadeSurfaceGeometry && (
            <mesh
              geometry={facadeSurfaceGeometry}
              material={
                new THREE.MeshBasicMaterial({
                  side: THREE.DoubleSide,
                })
              }
              visible={false}
              onPointerMove={(event) => {
                event.stopPropagation();
                handleMove(event.pointOnLine ?? event.point);
              }}
            />
          )}
        </>
      )}
    </group>
  );
};

export default CutTool;
