import React, { useMemo } from 'react';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { calculateCommonArea } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { PanelFrameIcon } from '@/shared/icons';
import { PanelPlacementData, UnitSystemTypes } from '@/models';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { compact } from 'lodash';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import {
  getMaterialDescription,
  getMaterialAdditionalInfo,
  getMaterialImage,
} from '@/components/WindowCreator/helpers/config';
import UsedColorsPalette from '@/shared/elements/UsedColorsPalette/UsedColorsPalette';
import IguConfiguratorView from '@/shared/elements/IguConfiguratorView';

interface PanelInfoFrameProps {
  panelsPlacementData: PanelPlacementData[];
}

const PanelMetrics: React.FC<PanelInfoFrameProps> = ({
  panelsPlacementData,
}) => {
  const { id } = useParams();
  const panelsInfo = useGetAllPanelsQuery(id!).data;
  const configData = useFetchWindowConfigQuery().data!;

  const colorsConfig = configData.colors;
  const materialsConfig = configData.panelMaterials;

  const isImperialUnits =
    useAppSelector(getProjectUnits(id!)) === UnitSystemTypes.Imperial;

  const { getAreaUnit, getMeterUnits } = useFrameProperties();

  const panelsData = useMemo(
    () =>
      compact(
        panelsPlacementData.map((placementData) =>
          panelsInfo?.find(
            (panelInfo) => panelInfo?.id === placementData.panelId
          )
        )
      ),
    [panelsPlacementData]
  );

  const insidePanelColorsData = useMemo(() => {
    const result = new Set<string>();

    panelsData.forEach((panel) => {
      result.add(panel.frameInsideColor);
    });

    return result;
  }, [panelsData]);

  const outsidePanelColorsData = useMemo(() => {
    const result = new Set<string>();

    panelsData.forEach((panel) => {
      result.add(panel.frameOutsideColor);
    });

    return result;
  }, [panelsData]);

  const panelsMaterialData = useMemo(() => {
    return Object.keys(
      panelsData.reduce((acc, curr) => {
        return { ...acc, [curr.frameMaterial]: true };
      }, {})
    );
  }, [panelsData]);

  return (
    <>
      <div className="text-xs">
        <div
          className={
            'flex flex-col border border-b-0 border-l-0 border-r-0 border-solid border-light-gray-20  overflow-y-auto text-dark-gray-100 mb-3'
          }
        >
          <div
            className={
              'flex flex-col text-xs border-0 !bg-white overflow-y-auto text-dark-gray-100'
            }
          >
            <div className="flex items-center justify-start gap-1 px-3 pt-3">
              <PanelFrameIcon />
              <span className="font-medium ">PANELS</span>
            </div>
            <PropertyList
              properties={[
                {
                  name: 'Count',
                  value: panelsPlacementData.length.toString(),
                  isDisabled: false,
                  isEditable: false,
                },
                {
                  name: 'Perimeter',
                  value: calculateCommonArea({
                    nodes: panelsData,
                    isImperialUnits,
                    calculateAreaFunction: (panel) =>
                      convertMillimetersToMeters(panel.width) +
                      convertMillimetersToMeters(panel.height),
                  }),
                  isDisabled: false,
                  isEditable: false,
                  units: getMeterUnits(),
                },
                {
                  name: 'Total area',
                  value: calculateCommonArea({
                    nodes: panelsData,
                    isImperialUnits,
                    calculateAreaFunction: (panel) =>
                      convertMillimetersToMeters(panel.width) *
                      convertMillimetersToMeters(panel.height),
                  }),
                  isDisabled: false,
                  isEditable: false,
                  units: getAreaUnit(),
                },
                {
                  name: 'IGU area',
                  value: calculateCommonArea({
                    nodes: panelsData,
                    isImperialUnits,
                    calculateAreaFunction: (panel) => panel.iguArea,
                  }),
                  isDisabled: false,
                  isEditable: false,
                  units: getAreaUnit(),
                },
              ]}
              removeBottomMargin
            />
          </div>
          <UsedColorsPalette
            paletteName={'Outside color'}
            colors={Array.from(outsidePanelColorsData)}
            colorsData={colorsConfig}
          />
          <UsedColorsPalette
            paletteName={'Inside color'}
            colors={Array.from(insidePanelColorsData)}
            colorsData={colorsConfig}
          />
          <div className="font-light leading-5 mb-0.5 px-3">
            <div className={'pb-1.5'}>Material</div>
            {panelsMaterialData.map((material) => (
              <div
                key={material}
                className={`pb-2 flex gap-2 box-border items-center`}
              >
                {getMaterialImage(material)}
                <div className="flex flex-col justify-center min-w-0">
                  <div className="text-xs font-normal leading-5">
                    {getMaterialDescription(material, materialsConfig)}
                  </div>
                  <div className="text-[11px] font-light text-xs leading-4">
                    {getMaterialAdditionalInfo(material, materialsConfig)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <IguConfiguratorView
            iguIds={compact(panelsData.map((panel) => panel.panelIguId))}
          />
        </div>
      </div>
    </>
  );
};

export default PanelMetrics;
