import React, { useMemo } from 'react';
import { IntusModal } from '@/shared/elements';
import { QuoteStatus } from '@/models/quote.model';
import {
  QuoteFooter,
  QuoteHeader,
  QuoteErrorContent,
  QuoteRequestContent,
  QuoteReadyContent,
  QuoteProcessingContent,
} from '@/routes/dashboard/projects/quote/components';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getQuoteRequestStatusErrorMessage,
  getQuoteRequestStatusReport,
  setQuoteRequestStatus,
} from '@/store/slices/quoteSlice';
import { useUnmount } from 'react-use';

interface QuoteModalContentProps {
  setIsOpen: (isOpen: boolean) => void;
}

const QuoteModalContent: React.FC<QuoteModalContentProps> = ({ setIsOpen }) => {
  const dispatch = useAppDispatch();

  const status = useAppSelector(getQuoteRequestStatusReport);
  const errorMessage = useAppSelector(getQuoteRequestStatusErrorMessage);
  const isProcessing = status === QuoteStatus.PROCESSING_EXPORT;

  const modals = useMemo(() => {
    switch (status) {
      case QuoteStatus.PROCESSING_EXPORT:
        return <QuoteProcessingContent />;
      case QuoteStatus.ERROR:
        return <QuoteErrorContent />;
      case QuoteStatus.READY:
        return <QuoteReadyContent setIsOpen={setIsOpen} />;
      case QuoteStatus.DEFAULT:
      default:
        return <QuoteRequestContent />;
    }
  }, [status]);

  useUnmount(() => {
    dispatch(setQuoteRequestStatus(QuoteStatus.DEFAULT));
  });

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IntusModal
        centered
        open
        footer={null}
        styles={{ body: { padding: 0 } }}
        onCancel={() => handleCancel()}
        maskClosable={!isProcessing}
        closable={!isProcessing}
        keyboard={!isProcessing}
        width={580}
      >
        <div className="flex flex-col items-center pt-[60px]">
          <QuoteHeader status={status} errorMessage={errorMessage} />
          {modals}
          <QuoteFooter status={status} />
        </div>
      </IntusModal>
    </>
  );
};

export default QuoteModalContent;
