export interface IGU {
  id: number;
  name: string;
  description: string;
}

export enum IGUFilterGroups {
  Spacers = 'spacers',
  Coatings = 'coatings',
  GlassTypes = 'glassTypes',
  GlassFilters = 'glassFilters',
}

// We use number for the values of the enums because the values are used as filter values in the API
export enum Spacer {
  PHI = 8,
  PHIUS = 4,
  Warm = 2,
}

export enum Coating {
  NoCoating = 5,
  AvisafeSuncool7040 = 7,
  Bird1stEtch = 30,
  Bird1stUVSNX5123 = 4,
  Bird1stUVSNX6272 = 8,
  CG = 2,
  NU = 6,
  Prem = 1,
  SN51 = 3,
}

export enum GlassType {
  AN = 2,
  HS = 0,
  HST = 3,
  T = 1,
}

export enum GlassFilter {
  BirdSafe = 2,
  Enamel = 1,
  Regular = 0,
}

export interface IguFilters {
  spacers: {
    [key in Spacer]: boolean;
  };
  coatings: {
    [key in Coating]: boolean;
  };
  glassTypes: {
    [key in GlassType]: boolean;
  };
  glassFilters: {
    [key in GlassFilter]: boolean;
  };
}
