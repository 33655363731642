import React, { useEffect, useState } from 'react';
import * as THREE from 'three';
import { C_DashedSplitLine } from '@/shared/materials';
import {
  createLine2,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { ExtendedMinMaxCoordinatesPairs } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { RENDER_ORDERS } from '@/shared/constants';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { useUnmount } from 'react-use';
import { FlatVector3 } from '@/models';
import { disposeNode } from '@/shared/helpers/canvas';

const SplitLine = ({
  position,
  minMaxCoordinates,
}: {
  position: THREE.Vector3;
  minMaxCoordinates: ExtendedMinMaxCoordinatesPairs;
}) => {
  const [splitLine, setSplitLine] = useState<Line2 | null>(null);

  useEffect(() => {
    const start: FlatVector3 = [
      position.x,
      minMaxCoordinates.min.y,
      position.z,
    ];
    const end: FlatVector3 = [position.x, minMaxCoordinates.max.y, position.z];
    if (!splitLine) {
      const material = C_DashedSplitLine.clone();
      material.depthTest = false;
      const line = createLine2([start, end].flat(2), material);
      line.renderOrder = RENDER_ORDERS.EDIT_NODE_LINE;
      setSplitLine(line);
    } else {
      updateLine2Position(splitLine, [start, end].flat(2));
    }
  }, [minMaxCoordinates, position]);

  const [endDot] = useState(
    new THREE.Mesh(
      new THREE.SphereGeometry(0.0003, 32),
      new THREE.MeshBasicMaterial({
        color: C_DashedSplitLine.color,
        depthTest: false,
        toneMapped: false,
      })
    )
  );

  useUnmount(() => {
    disposeNode(endDot);
    splitLine && disposeNode(splitLine);
  });

  return (
    <>
      <primitive
        object={endDot}
        position={[position.x, minMaxCoordinates.max.y, position.z]}
      />
      {splitLine && <primitive object={splitLine} />}
    </>
  );
};

export default SplitLine;
