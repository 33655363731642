import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  GridLineDataForFD,
  PanelPlacementDataForFD,
  WindowPlacementDataForFD,
} from '@/components/FacadeDesigner/models';

export enum FacadeDesignerPlacementType {
  Window = 'window',
  GridLine = 'gridLine',
}

interface PlacementError {
  state: boolean;
  message?: string;
}

interface FacadeDesignerState {
  modifiedWalls: string[]; // GUID's
  selectedWindowFromLibrary?: SavedWindow;
  selectedWindowPosition?: { x: number; y: number };
  facadeDesignerMode: FacadeDesignerModes;
  selectedPlacedWindow: WindowPlacementDataForFD[];
  hoveredPlacedWindow: WindowPlacementDataForFD | null;
  selectedGridLines: GridLineDataForFD[];
  hoveredGridLine: GridLineDataForFD | null;
  selectedPlacedPanels: { [guid: string]: PanelPlacementDataForFD };
  hoveredPlacedPanelGUID: string | null;

  draggedWindowFromLibrary?: SavedWindow;
  gridPlacementOffset?: number;
  // Wall, which currently has Active MultiMeasurement input (Active === user can input something)
  measurementActiveWall: string | null;
  // Wall, which is currently hovered by user
  hoveredWall: string | null;

  placementErrors: {
    // Key: WallGUID
    [key: string]: PlacementError;
  };

  dragNode: FacadeDesignerPlacementType | null;
}

const initialState: FacadeDesignerState = {
  modifiedWalls: [],
  selectedPlacedWindow: [],
  hoveredPlacedWindow: null,
  hoveredWall: null,
  hoveredGridLine: null,
  selectedGridLines: [],
  selectedPlacedPanels: {},
  hoveredPlacedPanelGUID: null,
  measurementActiveWall: null,
  facadeDesignerMode: FacadeDesignerModes.WindowPlacement,
  placementErrors: {},
  dragNode: null,
};

export const facadeDesignerSlice = createSlice({
  name: 'facadeDesigner',
  initialState,
  reducers: {
    setSelectedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow>
    ) => {
      state.selectedWindowFromLibrary = action.payload;
    },
    resetFacadeDesignerSlice: () => initialState,
    resetSelectedWindowFromLibrary: (state) => {
      state.selectedWindowFromLibrary = undefined;
    },
    addSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD>
    ) => {
      state.selectedPlacedWindow.push(action.payload);
    },
    setSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD[]>
    ) => {
      state.selectedPlacedWindow = action.payload;
    },
    setModifiedWalls: (state, action: PayloadAction<string[]>) => {
      state.modifiedWalls = action.payload;
    },
    setHoveredWall: (state, action: PayloadAction<string | null>) => {
      state.hoveredWall = action.payload;
    },
    setHoveredPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD | null>
    ) => {
      state.hoveredPlacedWindow = action.payload;
    },
    setFacadeDesignerMode: (
      state,
      action: PayloadAction<FacadeDesignerModes>
    ) => {
      state.facadeDesignerMode = action.payload;
    },
    setDraggedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow | undefined>
    ) => {
      state.draggedWindowFromLibrary = action.payload;
    },

    setGridPlacementAbsoluteOffset: (state, action: PayloadAction<number>) => {
      state.gridPlacementOffset = action.payload;
    },
    setSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD[]>
    ) => {
      state.selectedGridLines = action.payload;
    },
    addToSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD>
    ) => {
      state.selectedGridLines.push(action.payload);
    },
    resetSelectedGridLines: (state) => {
      state.selectedGridLines = [];
    },
    setMeasurementActiveWall: (state, action: PayloadAction<string | null>) => {
      state.measurementActiveWall = action.payload;
    },

    setPlacementError: (
      state,
      action: PayloadAction<{ key: string; state: boolean; message?: string }>
    ) => {
      state.placementErrors[action.payload.key] = {
        state: action.payload.state,
        message: action.payload.message,
      };
    },
    resetPlacementErrors: (state) => {
      state.placementErrors = {};
    },

    setHoveredGridLine: (
      state,
      action: PayloadAction<GridLineDataForFD | null>
    ) => {
      state.hoveredGridLine = action.payload;
    },
    setSelectedPlacedPanel: (
      state,
      { payload }: PayloadAction<PanelPlacementDataForFD>
    ) => {
      state.selectedPlacedPanels = { [payload.guid]: payload };
    },
    addToSelectedPlacedPanel: (
      state,
      { payload }: PayloadAction<PanelPlacementDataForFD>
    ) => {
      state.selectedPlacedPanels = {
        ...state.selectedPlacedPanels,
        [payload.guid]: payload,
      };
    },
    removeFromSelectedPlacedPanels: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      delete state.selectedPlacedPanels[payload];
    },
    resetSelectedPlacedPanels: (state) => {
      state.selectedPlacedPanels = {};
    },
    setHoveredPlacedPanel: (state, action: PayloadAction<string | null>) => {
      state.hoveredPlacedPanelGUID = action.payload;
    },
    setDragNode: (
      state,
      { payload }: PayloadAction<FacadeDesignerPlacementType | null>
    ) => {
      state.dragNode = payload;
    },
  },
});

export const {
  setSelectedWindowFromLibrary,
  resetSelectedWindowFromLibrary,
  resetFacadeDesignerSlice,
  setSelectedPlacedWindow,
  setHoveredPlacedWindow,
  addSelectedPlacedWindow,
  setModifiedWalls,
  setFacadeDesignerMode,
  setDraggedWindowFromLibrary,
  setGridPlacementAbsoluteOffset,
  setSelectedGridLines,
  addToSelectedGridLines,
  setHoveredGridLine,
  setMeasurementActiveWall,
  setHoveredWall,
  resetSelectedGridLines,
  setPlacementError,
  resetPlacementErrors,
  setSelectedPlacedPanel,
  addToSelectedPlacedPanel,
  setHoveredPlacedPanel,
  resetSelectedPlacedPanels,
  removeFromSelectedPlacedPanels,
  setDragNode,
} = facadeDesignerSlice.actions;

export const getSelectedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedWindowFromLibrary;

export const isWindowFromLibrarySelected = (id: number) => (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedWindowFromLibrary?.id === id;

export const getSelectedPlacedWindows = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedPlacedWindow;

export const getHoveredPlacedWindow = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredPlacedWindow;

export const getModifiedWalls = (state: RootState) =>
  state.windowsReducer.facadeDesigner.modifiedWalls;

export const getDraggedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.facadeDesigner.draggedWindowFromLibrary;

export const getFacadeDesignerMode = (state: RootState) =>
  state.windowsReducer.facadeDesigner.facadeDesignerMode;

export const getGridPlacementAbsoluteOffset = (state: RootState) =>
  state.windowsReducer.facadeDesigner.gridPlacementOffset;

export const getSelectedGridlines = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedGridLines;

export const isGridLineSelected = (guid: string) => (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedGridLines.some(
    (line) => guid === line.guid
  );

export const isItemSelectedOnWall =
  (wallGUID: string) => (state: RootState) => {
    const { selectedGridLines, selectedPlacedWindow } =
      state.windowsReducer.facadeDesigner;

    return [...selectedGridLines, ...selectedPlacedWindow].some(
      (item) => wallGUID === item.wallGUID
    );
  };

export const getHoveredGridLine = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredGridLine;

export const getMeasurementActiveWall = (state: RootState) =>
  state.windowsReducer.facadeDesigner.measurementActiveWall;

export const getHoveredWall = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredWall;

export const getPlacementErrors = (state: RootState) =>
  state.windowsReducer.facadeDesigner.placementErrors;

export const getIsPanelHovered = (guid: string) => (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredPlacedPanelGUID === guid;

export const getIsPanelSelected = (guid: string) => (state: RootState) =>
  !!state.windowsReducer.facadeDesigner.selectedPlacedPanels?.[guid];

export const getSelectedPlacedPanels = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedPlacedPanels;

export const getDragNode = (state: RootState) =>
  state.windowsReducer.facadeDesigner.dragNode;

export const getIsSomePanelHovered = (state: RootState) =>
  !!state.windowsReducer.facadeDesigner.hoveredPlacedPanelGUID;

export const getIsSomeGridLinesSelected = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedGridLines.length > 0;

export const getIsSomeWindowSelected = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedPlacedWindow.length > 0;

export const getIsOnlyOneWindowSelected = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedPlacedWindow.length === 1;
