import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';
import { IGU, IGUFilterGroups } from '@/models/webcalc';

const webCalcApi = createApi({
  reducerPath: 'webcalc',
  tagTypes: ['IGU'],
  baseQuery,
  endpoints: (build) => ({
    fetchIgu: build.query<IGU[], void>({
      providesTags: ['IGU'],
      query: () => ({
        url: `/webcalc/igu`,
        method: 'GET',
      }),
    }),
    filterIgu: build.query<IGU[], Partial<Record<IGUFilterGroups, string[]>>>({
      query: (filters) => {
        const queryParams = new URLSearchParams();

        if (filters) {
          Object.entries(filters).forEach(([key, values]) => {
            values?.forEach((value) => {
              queryParams.append(key, value);
            });
          });
        }

        return {
          url: `/webcalc/igu?${queryParams.toString()}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useFetchIguQuery, useLazyFilterIguQuery } = webCalcApi;

export { webCalcApi };
