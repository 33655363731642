import { DrawModes, FlatVector3 } from '@/models/canvas.model';
import { OperationType } from './window-configurator.model';

export interface CommonConstructionData {
  guid: string;
  name: string;
  userData?: CustomUserData;
}

export interface CreateStoreyCoordinates {
  storeyNumber: number;
  floor: FlatVector3[];
  ceiling: FlatVector3[];
  walls: FlatVector3[][];
}

export interface CreateUserBuildingData {
  drawMode: DrawModes;
  storeys: CreateStoreyCoordinates[];
  centerLineCoordinates?: FlatVector3[];
  centerLineWidth?: number;
}

export type CustomUserData = {
  isHidden?: boolean;
  isLocked?: boolean;
  isIsolated?: boolean;
} | null;

export interface UserBuildingSurface extends CommonConstructionData {
  points: FlatVector3[];
}

export enum CornerGridAlign {
  Left = 'Left',
  Right = 'Right',
}
export interface GridLineData {
  guid: string;
  offsetFromLeftEdge: number;
  cornerAlign: CornerGridAlign | null;
}

export interface UserBuildingPanel {
  id: number;
  width: number;
  height: number;
  fullHeight: number;
  fullWidth: number;
  fullSideWidth: number;
  isCorner: boolean;
  frameOutsideColor: string;
  frameInsideColor: string;
  frameMaterial: string;
  fillerOutsideColor: string;
  fillerInsideColor: string;
  fillerMaterial: string;
  panelIguId: number | null;
  panelWindows: PanelWindowData[];
  glazedArea: number;
  iguArea: number;
  fullCornerGlazedArea: number;
  model: string;
  sideWidth?: number;
  mullions: {
    x: number;
    y: number;
    length: number;
    orientation: 'Horizontal' | 'Vertical';
  }[];
  innerFrames: PanelInnerFrame[];
}

export interface PanelWindowData {
  id: number;
  glazing: string;
  outsideColor: string;
  insideColor: string;
  panelInnerFrames: {
    operationType: OperationType;
    glassWidth: number;
    glassHeight: number;
  }[];
  glassArea: number;
  offsetFromLeftEdge: number;
}

export interface PanelInnerFrame {
  windowId: number | null;
  offsetFromLeftEdge: number;
  x: number;
  y: number;
  width: number;
  height: number;
  iguId: number;
  operationType: OperationType;
  outerFrameColor: string;
  innerFrameColor: string;
  number: string;
}

export interface WindowPlacementData {
  offsetFromLeftEdge: number;
  windowId: number;
  guid: string;
}

export interface PanelPlacementData {
  isInitialCorner?: boolean;
  offsetFromLeftEdge: number;
  guid: string;
  panelId: number;
}

export interface WallPanelError {
  message: string;
  offsetFromLeftEdge: number;
  value: number;
  width: number;
}

export interface UserBuildingWall extends UserBuildingSurface {
  windowPlacements: WindowPlacementData[];
  gridLines: GridLineData[];
  wallPanels: PanelPlacementData[];
  wallPanelErrors: WallPanelError[];
}

export interface UserBuildingStorey extends CommonConstructionData {
  storeyNumber: number;
  floor: UserBuildingSurface;
  ceiling: UserBuildingSurface;
  walls: UserBuildingWall[];
}

export interface UserBuildingBlock extends CommonConstructionData {
  storeys: UserBuildingStorey[];
}

export interface UserBuildingCenterLineCoordinates
  extends CommonConstructionData {
  points: FlatVector3[];
  width: number;
}

export interface UserBuildingCoordinatesObject extends CommonConstructionData {
  projectId: number;
  drawMode: DrawModes;
  blocks: UserBuildingBlock[];

  centerLineCoordinates?: UserBuildingCenterLineCoordinates;
}
