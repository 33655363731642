import { QuoteStatus } from '@/models/quote.model';
import React from 'react';

export const QuoteFooter = ({ status }: { status: QuoteStatus }) => {
  switch (status) {
    case QuoteStatus.READY:
      return (
        <div className="w-full mt-auto flex flex-col py-[14px] bg-light-gray-10">
          <p className="text-xs leading-5 font-normal text-dark-gray-100 m-0 ml-6">
            The BlueScreen tool received the project data along with images and
            calculations.
          </p>
        </div>
      );
    case QuoteStatus.ERROR:
      return (
        <div className="w-full mt-auto flex flex-col py-[14px] bg-light-gray-10">
          <p className="text-xs font-normal leading-5 m-0 px-6 text-dark-gray-100">
            There was an error and the quote request failed. Please try again
          </p>
        </div>
      );
    case QuoteStatus.PROCESSING_EXPORT:
      return null;
    default:
      return (
        <div className="w-full mt-auto flex items-center justify-between py-2 bg-light-gray-10">
          <p className="text-xs leading-5 m-0 ml-6 text-dark-gray-100">
            The BlueScreen tool will receive the project data along with images
            and calculations to prepare a quote for the client.
          </p>
        </div>
      );
  }
};
