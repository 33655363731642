import React from 'react';
import KonvaErrorList from '@/shared/components/KonvaErrorList';
import { useAppSelector } from '@/store/hooks';
import { getPlacementErrors } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { compact } from 'lodash';
import { getMeasurementErrors } from '@/store/slices/sharedSlice';

interface FacadeDesignerErrors {
  scale: number;
}

const FacadeDesignerErrors = ({ scale }: FacadeDesignerErrors) => {
  const customErrors = useAppSelector(getPlacementErrors);
  const measurementErrors = useAppSelector(getMeasurementErrors);

  const errors = [
    ...Object.values(customErrors).map((err) => err.message),
    ...measurementErrors,
  ];
  return <KonvaErrorList errors={compact(errors)} scale={scale} />;
};

export default FacadeDesignerErrors;
