import React from 'react';
import { useAppSelector } from '@/store/hooks';
import { getMeasurementErrors } from '@/store/slices/sharedSlice';
import KonvaErrorList from '@/shared/components/KonvaErrorList';

interface WindowCreatorErrorsProps {
  scale: number;
}
const WindowCreatorErrors = ({ scale }: WindowCreatorErrorsProps) => {
  const measurementErrors = useAppSelector(getMeasurementErrors);

  return <KonvaErrorList errors={measurementErrors} scale={scale} />;
};

export default WindowCreatorErrors;
