import React, { useEffect, useState } from 'react';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import * as THREE from 'three';
import { uuidv7 } from 'uuidv7';
import { flatten, flattenDeep } from 'lodash';

import {
  FlatVector3,
  NodeType,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingSurface,
} from '@/models';
import {
  checkLineIntersection,
  createLine2,
  getCenterFromFlatVectorsArray,
  getCenterFromVectorsArray,
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  getXYZ,
  isPointInsideArea,
  isPointsInOneLine,
  isVectorLeftSide,
  triangulateGeometryAndUpdate,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { C_DashedSplitLine } from '@/shared/materials';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  generateStorey,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import {
  PROJECT_CANVAS_ID,
  removeClosePoints,
} from '@/shared/helpers/canvas-verifiers';
import { isRightClick } from '@/shared/helpers';
import { switchIsolatedFlags } from '@/store/slices/canvasBuildingSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getStatusIsolateMode } from '@/store/slices/canvasModesSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { RENDER_ORDERS } from '@/shared/constants';
import { checkIsWallsAreParallel } from '@/routes/dashboard/projects/project/UserBuilding/helpers/editing-tools.helpers';
import { getProcessingEntity } from '@/store/slices/canvasExternalElementsSlice';

interface CutSurfaceProps {
  block: UserBuildingBlock;
  mousePosition: THREE.Vector3 | null;
  currentWall: UserBuildingSurface | null;
  buildingGUID: string;
  isAllowedToCut: boolean;
  isCameraRotating: boolean;
}

const CutSurface: React.FC<CutSurfaceProps> = ({
  block,
  mousePosition,
  currentWall,
  buildingGUID,
  isAllowedToCut,
  isCameraRotating,
}) => {
  const dispatch = useAppDispatch();
  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);
  const processingEntity = useAppSelector(getProcessingEntity);
  const { cutBuildingBlock } = useUpdateUserBuildingData();
  const { addNodesToSelectedNodes } = useSelectedNodes();

  const [cutSurfaceBorder, setCutSurfaceBorder] = useState<Line2>(null!);
  const [cutSurface, setCutSurface] = useState<THREE.Mesh>(null!);

  const getPerpendicularDirectionForWall = (
    wall: UserBuildingSurface,
    point: THREE.Vector3
  ) => {
    const perpendicularDirectionNormalized = getPerpendicularVectorToVectors(
      [
        convertFlatVector3ToVector(wall.points[0]),
        convertFlatVector3ToVector(wall.points[1]),
      ],
      true
    );
    return getTranslatedVector(point, 1, perpendicularDirectionNormalized);
  };

  const findClosestWall = ({
    storeyWalls,
    wall,
    lineEnd,
    lineStart,
  }: {
    storeyWalls: UserBuildingSurface[];
    wall: UserBuildingSurface;
    lineStart: THREE.Vector3;
    lineEnd: THREE.Vector3;
    needToExcludeWall?: boolean;
  }): {
    intersection: THREE.Vector3;
    closestWall: UserBuildingSurface;
  } | null => {
    const wallCenter = getCenterFromFlatVectorsArray(wall.points);

    const intersection = new THREE.Vector3();

    const closestWall = storeyWalls.reduce(
      (acc: UserBuildingSurface | null, curr): UserBuildingSurface | null => {
        const lineIntersection = checkLineIntersection(
          lineStart,
          lineEnd,
          convertFlatVector3ToVector(curr.points[0]),
          convertFlatVector3ToVector(curr.points[1])
        );
        if (lineIntersection.onLine1 && lineIntersection.onLine2) {
          if (!checkIsWallsAreParallel(wall, curr)) {
            return acc;
          }
          if (!acc) {
            intersection.set(
              lineIntersection.x!,
              lineStart.y,
              lineIntersection.y!
            );
            return curr;
          }
          const currCenter = getCenterFromFlatVectorsArray(curr.points);
          const currDistance = currCenter.distanceTo(wallCenter);
          const accCenter = getCenterFromFlatVectorsArray(acc.points);
          const accDistance = accCenter.distanceTo(wallCenter);
          if (currDistance < accDistance) {
            intersection.set(
              lineIntersection.x!,
              lineStart.y,
              lineIntersection.y!
            );
            return curr;
          } else {
            return acc;
          }
        }
        return acc;
      },
      null
    );

    if (!closestWall) return null;

    return { intersection, closestWall };
  };

  const findOppositeWall = (
    point: THREE.Vector3,
    wall: UserBuildingSurface,
    storeyWalls: UserBuildingSurface[]
  ): {
    intersection: THREE.Vector3;
    closestWall: UserBuildingSurface;
  } | null => {
    const perpendicularDirection = getPerpendicularDirectionForWall(
      wall,
      point
    );

    const lineEnd = getExtendedVector(point, perpendicularDirection, 10);

    return findClosestWall({
      storeyWalls: storeyWalls.filter(
        (storeyWall) => storeyWall.guid !== wall.guid
      ),
      wall,
      lineEnd,
      lineStart: point,
    });
  };

  const findFacadeWall = (
    point: THREE.Vector3,
    wall: UserBuildingSurface,
    storeyWalls: UserBuildingSurface[]
  ): {
    intersection: THREE.Vector3;
    closestWall: UserBuildingSurface;
  } | null => {
    const perpendicularDirection = getPerpendicularDirectionForWall(
      wall,
      point
    );

    const lineEnd = getExtendedVector(point, perpendicularDirection, -10);
    const lineStart = getExtendedVector(point, perpendicularDirection, 0.01);

    return findClosestWall({
      storeyWalls,
      wall,
      lineEnd,
      lineStart,
      needToExcludeWall: true,
    });
  };

  const handleMove = (position: THREE.Vector3, wall: UserBuildingSurface) => {
    const facadeWalls: {
      intersection: THREE.Vector3;
      closestWall: UserBuildingSurface;
    }[] = [];
    const oppositeWalls: {
      intersection: THREE.Vector3;
      closestWall: UserBuildingSurface;
    }[] = [];

    const storey = block.storeys.find((storey) =>
      storey.walls.find((storeyWall) => storeyWall.guid === wall.guid)
    );
    for (let i = 0; i < block.storeys.length; i++) {
      //create a projection of wall at current storey
      const wallProjection = {
        ...wall,
        points: wall.points.map((point) => [
          point[0],
          block.storeys[i].floor.points[0][1],
          point[2],
        ]) as FlatVector3[],
      };
      let facadeWall = findFacadeWall(
        position.clone().setY(block.storeys[i].floor.points[0][1]),
        wallProjection,
        block.storeys[i].walls
      );

      //find if the wall is located in the opposite direction from the projection
      if (!facadeWall) {
        facadeWall = findOppositeWall(
          position.clone().setY(block.storeys[i].floor.points[0][1]),
          wallProjection,
          block.storeys[i].walls
        );
      }

      if (!facadeWall?.closestWall) continue;

      const oppositeWall = findOppositeWall(
        position.clone().setY(block.storeys[i].floor.points[0][1]),
        facadeWall.closestWall,
        block.storeys[i].walls
      );
      if (!oppositeWall?.closestWall) continue;

      // if the center between the mouse and the opposite wall is not inside the floor shape, it means that we have no nearest points on the current floor
      if (
        isPointInsideArea(
          getCenterFromVectorsArray([
            position.clone().setY(block.storeys[i].floor.points[0][1]),
            oppositeWall.intersection,
          ]),
          convertFlatVector3ToVectors(storey!.floor.points)
        ) ||
        processingEntity?.active
      ) {
        facadeWalls.push({
          intersection: facadeWall.intersection,
          closestWall: facadeWall.closestWall,
        });
        oppositeWalls.push({
          intersection: oppositeWall.intersection,
          closestWall: oppositeWall.closestWall,
        });
      }
    }

    if (!facadeWalls.length || !oppositeWalls.length) return null;
    const firstContourPoints = oppositeWalls
      .map((oppositeWall) => [
        getXYZ(
          oppositeWall.intersection.setY(oppositeWall.closestWall.points[2][1])
        ),
        getXYZ(
          oppositeWall.intersection.setY(oppositeWall.closestWall.points[0][1])
        ),
      ])
      .reverse();

    const secondContourPoints: FlatVector3[][] = facadeWalls.map(
      (facadeWall) => [
        getXYZ(
          facadeWall.intersection.setY(facadeWall.closestWall.points[0][1])
        ),
        getXYZ(
          facadeWall.intersection.setY(facadeWall.closestWall.points[2][1])
        ),
      ]
    );
    const contourPoints = flattenDeep([
      getXYZ(oppositeWalls[0].intersection),
      ...secondContourPoints,
      ...firstContourPoints,
      getXYZ(oppositeWalls[0].intersection),
    ]);

    const cutSurfaceBorderMaterial = C_DashedSplitLine.clone();
    cutSurfaceBorderMaterial.color = new THREE.Color('#D23736');
    cutSurfaceBorderMaterial.depthTest = false;

    //need to always create new because of computing dashed lines
    const line = createLine2(contourPoints, cutSurfaceBorderMaterial);
    line.renderOrder = RENDER_ORDERS.EDIT_NODE_LINE;
    setCutSurfaceBorder(line);

    const surfacePoints = convertFlatVector3ToVectors([
      getXYZ(oppositeWalls[0].intersection),
      ...flatten(secondContourPoints),
      ...flatten(firstContourPoints),
      getXYZ(oppositeWalls[0].intersection),
    ]);
    if (!cutSurface) {
      const material = new THREE.MeshBasicMaterial({
        color: new THREE.Color('#D23736'),
        toneMapped: false,
        opacity: 0.1,
        transparent: true,
        depthTest: false,
        side: THREE.DoubleSide,
      });
      const geometry = createGeometryFromVectorList(surfacePoints, 'vertical');
      const surface = new THREE.Mesh(geometry, material);
      setCutSurface(surface);
    } else {
      cutSurface.geometry.setFromPoints(surfacePoints);
      triangulateGeometryAndUpdate(
        cutSurface.geometry,
        surfacePoints,
        'vertical'
      );
    }
  };

  const cutShapePoints = (
    points: FlatVector3[],
    cutPoints: THREE.Vector3[]
  ): { shape1: THREE.Vector3[]; shape2: THREE.Vector3[] } => {
    let shape1: THREE.Vector3[] = [];
    let shape2: THREE.Vector3[] = [];

    //show us in which shape we need to push points
    let cutFlag = true;

    points.forEach((point, i) => {
      const start = convertFlatVector3ToVector(point);

      //last point not needed
      if (i === points.length - 1) {
        return;
      }

      const end = convertFlatVector3ToVector(points[i + 1]);

      //is out cut surface intersect points
      const intersectedCutPoint = cutPoints.find((cutPoint) =>
        isPointsInOneLine(
          [start.x, start.z],
          [cutPoint.x, cutPoint.z],
          [end.x, end.z]
        )
      );
      intersectedCutPoint?.setY(start.y);

      //not to duplicate similar points
      const isLastPointEqualsNext1 = shape1[shape1.length - 1]?.equals(start);
      const isLastPointEqualsNext2 = shape2[shape2.length - 1]?.equals(start);

      // if we have an intersection then we need to add the start point and the intersection point to one figure and the rest points to the other until we intersect the cut surface again and get the flags back again
      if (intersectedCutPoint) {
        if (cutFlag) {
          shape1.push(start);
          shape1.push(intersectedCutPoint.clone());
          shape2.push(intersectedCutPoint.clone());
          shape2.push(end);
        } else {
          shape2.push(start);
          shape2.push(intersectedCutPoint.clone());
          shape1.push(intersectedCutPoint.clone());
          shape1.push(end);
        }

        //when we have intersection, we change shape in which we need to push shape points
        cutFlag = !cutFlag;
      } else {
        if (cutFlag) {
          !isLastPointEqualsNext1 && shape1.push(start);
        } else {
          !isLastPointEqualsNext2 && shape2.push(start);
        }
      }
    });

    // if the shape does not have the same point at the end as at the beginning, then need to add it
    if (shape1[0] && !shape1[0].equals(shape1[shape1.length - 1])) {
      shape1.push(shape1[0]);
    }
    if (shape2[0] && !shape2[0].equals(shape2[shape2.length - 1])) {
      shape2.push(shape2[0]);
    }

    //shape1 should always be on one side
    if (
      isVectorLeftSide(
        cutPoints[0],
        cutPoints[1],
        getCenterFromVectorsArray(shape1)
      )
    ) {
      const temp = shape2;
      shape2 = shape1;
      shape1 = temp;
    }

    const filteredShape1 = removeClosePoints(shape1);
    const filteredShape2 = removeClosePoints(shape2);

    return { shape1: filteredShape1, shape2: filteredShape2 };
  };

  const finishCut = (event?: PointerEvent) => {
    if (!cutSurface || !isAllowedToCut) return;
    if (event && isRightClick(event)) return;
    event?.stopPropagation();

    const cutSurfacePosition = cutSurface.geometry.getAttribute('position');

    const cutPoints: THREE.Vector3[] = [];
    for (let i = 0; i < cutSurfacePosition.count; i++) {
      const vector = new THREE.Vector3().fromBufferAttribute(
        cutSurfacePosition,
        i
      );
      cutPoints.push(vector);
    }

    const block1: UserBuildingBlock = {
      guid: uuidv7(),
      name: `${block.name} 1`,
      userData: {},
      storeys: [],
    };
    const block2: UserBuildingBlock = {
      guid: uuidv7(),
      name: `${block.name} 2`,
      userData: {},
      storeys: [],
    };

    block.storeys.forEach((storey) => {
      const newFloors = cutShapePoints(storey.floor.points, cutPoints);
      const newCeilings = cutShapePoints(storey.ceiling.points, cutPoints);

      // if the shape length is 0 - it means that the floor does not exist
      if (newFloors.shape1.length > 0 && newCeilings.shape1.length > 0) {
        const newStorey1: UserBuildingStorey = generateStorey(
          newFloors.shape1,
          storey.storeyNumber,
          newCeilings.shape1,
          storey
        );
        block1.storeys.push(newStorey1);
      }
      if (newFloors.shape2.length > 0 && newCeilings.shape2.length > 0) {
        const newStorey2: UserBuildingStorey = generateStorey(
          newFloors.shape2,
          storey.storeyNumber,
          newCeilings.shape2,
          storey
        );
        block2.storeys.push(newStorey2);
      }
    });

    cutBuildingBlock({
      buildingGUID,
      blockGUID: block.guid,
      newBlocks: [block1, block2],
    });

    //TODO remove it when isolate tool will be rewritten
    const newGuidsToIsolate: string[] = flatten([
      block1.guid,
      block2.guid,
      block1.storeys.map((s) => s.guid),
      block1.storeys.map((s) => s.ceiling.guid),
      block1.storeys.map((s) => s.floor.guid),
      ...block1.storeys.map((s) => s.walls.flatMap((w) => w.guid)),
      block2.storeys.map((s) => s.guid),
      block2.storeys.map((s) => s.ceiling.guid),
      block2.storeys.map((s) => s.floor.guid),
      ...block2.storeys.map((s) => s.walls.flatMap((w) => w.guid)),
    ]);

    isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));

    addNodesToSelectedNodes([
      { guid: block1.guid, type: NodeType.Block },
      { guid: block2.guid, type: NodeType.Block },
    ]);
  };

  const keydownEvent = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        finishCut();
        break;
      }
    }
  };

  useEffect(() => {
    const isAbleToMove = !isCameraRotating && mousePosition && currentWall;
    isAbleToMove && handleMove(mousePosition, currentWall);
  }, [mousePosition, currentWall, block, processingEntity, isCameraRotating]);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    !isCameraRotating && canvas?.addEventListener('pointerdown', finishCut);
    !isCameraRotating && document.addEventListener('keydown', keydownEvent);
    return () => {
      canvas?.removeEventListener('pointerdown', finishCut);
      document.removeEventListener('keydown', keydownEvent);
    };
  }, [
    mousePosition,
    cutSurface,
    isAllowedToCut,
    processingEntity,
    isCameraRotating,
  ]);

  return (
    <group>
      {cutSurfaceBorder && <primitive object={cutSurfaceBorder} />}
      {cutSurface && <primitive object={cutSurface} />}
    </group>
  );
};

export default CutSurface;
