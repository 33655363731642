import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const ExtrudeDotIcon: React.FC<BaseIconProps> = ({
  fill = '#758DDB',
  ...rest
}) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        opacity="1"
        cx="5"
        cy="5"
        r="4"
        fill={fill}
        stroke="#FFFFFF"
        strokeWidth="2"
      />
    </svg>
  );
};
