import * as THREE from 'three';
import {
  FlatVector3,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingSurface,
  UserBuildingWall,
} from '@/models';
import {
  convert3DVectorsTo2D,
  createLine2,
  getXYZ,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Earcut } from 'three/src/extras/Earcut';
import { isEqual } from 'lodash';
import { uuidv7 } from 'uuidv7';
import * as polyclip from 'polyclip-ts';
import { C_FatLineSelectedBorderMaterial } from '@/shared/materials';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';

export const FLOOR_HEIGHT = 0.03;
export const DEFAULT_FLOOR_HEIGHT_IN_METERS = 3.048;
export const DEFAULT_CORNER_GRID_WIDTH = 523.87;
export const MAX_CORNER_GRID_WIDTH = 1981.2;
export const DEFAULT_PANEL_WIDTH = 1100;
export const MIN_PANEL_WIDTH = 304.8;
export const MAX_PANEL_WIDTH = 2300;

export const convertPositionAttributeToVectors = (
  position: THREE.BufferAttribute | THREE.InterleavedBufferAttribute
): FlatVector3[] => {
  const vectors: FlatVector3[] = [];
  for (let i = 0; i < position.count; i++) {
    vectors.push(getXYZ(new THREE.Vector3().fromBufferAttribute(position, i)));
  }
  return vectors;
};

export const convertFlatVector3ToVectors = (
  points: FlatVector3[]
): THREE.Vector3[] => {
  return points.map((point) => new THREE.Vector3(point[0], point[1], point[2]));
};

export const convertFlatVector3ToVector = (
  point: FlatVector3
): THREE.Vector3 => {
  return new THREE.Vector3(point[0], point[1], point[2]);
};

export const convertFlatVector3ToVector2 = (point: FlatVector3) =>
  new THREE.Vector2(point[0], point[2]);

export const createGeometryFromVectorList = (
  points: THREE.Vector3[],
  direction: 'horizontal' | 'vertical'
) => {
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const triangulate = Earcut.triangulate(
    convert3DVectorsTo2D(
      points,
      direction === 'horizontal' ? ['x', 'z'] : ['x', 'y']
    )
      .map((point) => [point.x, point.y])
      .flat()
  );

  geometry.setIndex(triangulate);
  geometry.computeBoundingBox();

  return geometry;
};
export const updateGeometryFromVectorList = (
  geometry: THREE.BufferGeometry,
  points: THREE.Vector3[],
  direction: 'horizontal' | 'vertical'
) => {
  geometry.setFromPoints(points);

  const triangulate = Earcut.triangulate(
    convert3DVectorsTo2D(
      points,
      direction === 'horizontal' ? ['x', 'z'] : ['x', 'y']
    )
      .map((point) => [point.x, point.y])
      .flat()
  );

  geometry.setIndex(triangulate);
  geometry.computeBoundingBox();
};

export const getAllWallsAtOneSideAtBlock = (
  block: UserBuildingBlock,
  wallGuid: string
) => {
  const allWallsAtOneSide = [];

  for (let i = 0; i < block.storeys[0].walls.length; i++) {
    const oneSideWalls = [];
    let wall = block.storeys[0].walls[i];
    oneSideWalls.push(wall);
    for (let j = 1; j < block.storeys.length; j++) {
      const foundedWall = block.storeys[j]?.walls.find((storeyWall) => {
        const amountOfSamePoints = storeyWall?.points?.reduce((acc, curr) => {
          return wall.points.some(
            (point) =>
              point[0].toFixed(3) === curr[0].toFixed(3) &&
              point[1].toFixed(3) === curr[1].toFixed(3) &&
              point[2].toFixed(3) === curr[2].toFixed(3)
          )
            ? acc + 1
            : acc;
        }, 0);
        return amountOfSamePoints === 2;
      });
      if (foundedWall) {
        oneSideWalls.push(foundedWall);
        wall = foundedWall;
      }
    }
    allWallsAtOneSide.push(oneSideWalls);
  }

  const sideWallsOnAllFloors = allWallsAtOneSide.find((sideWalls) =>
    sideWalls.some((wall) => wall.guid === wallGuid)
  );

  return block.storeys.length === sideWallsOnAllFloors?.length
    ? sideWallsOnAllFloors
    : [];
};

export const generateWalls = (
  floorPoints: THREE.Vector3[],
  ceilingPoints: THREE.Vector3[],
  previousWallsData?: UserBuildingWall[],
  newNamePattern?: string
): UserBuildingWall[] => {
  const walls: UserBuildingWall[] = [];
  let newWallCount = 1;
  for (let i = 0; i < floorPoints.length - 1; i++) {
    const wallPoints = [
      getXYZ(floorPoints[i]),
      getXYZ(floorPoints[i + 1]),
      getXYZ(ceilingPoints[i + 1]),
      getXYZ(ceilingPoints[i]),
    ];
    const existedWallData = previousWallsData?.find((wallData) =>
      isEqual(wallData.points, wallPoints)
    );
    walls.push({
      name: `${newNamePattern || 'New Wall'} ${newWallCount}`,
      gridLines: [],
      windowPlacements: [],
      wallPanels: [],
      wallPanelErrors: [],
      userData: {},
      guid: uuidv7(),
      ...existedWallData,
      points: wallPoints,
    });
    if (!existedWallData) {
      newWallCount += 1;
    }
  }

  return walls;
};

export const generateStorey = (
  floorPoints: THREE.Vector3[],
  storeyNumber: number,
  ceilingPoints: THREE.Vector3[],
  previousStoreyData?: Partial<UserBuildingStorey>
) => {
  const calculatedHeight = ceilingPoints[0].y - floorPoints[0].y;

  const ceiling: UserBuildingSurface = {
    name: `Ceiling ${storeyNumber}`,
    userData: {},
    ...previousStoreyData?.ceiling,
    guid: uuidv7(),
    points: ceilingPoints
      ? ceilingPoints.map((point) => getXYZ(point))
      : floorPoints.map((point) => [
          point.x,
          point.y + calculatedHeight,
          point.z,
        ]),
  };
  const floor: UserBuildingSurface = {
    name: `Floor ${storeyNumber}`,
    userData: {},
    ...previousStoreyData?.floor,
    guid: uuidv7(),
    points: floorPoints.map((point) => getXYZ(point)),
  };

  return {
    name: `Floor ${storeyNumber}`,
    userData: {},
    storeyNumber: storeyNumber,
    ...previousStoreyData,
    guid: uuidv7(),
    floor,
    ceiling,
    walls: generateWalls(
      floorPoints,
      convertFlatVector3ToVectors(ceiling.points),
      previousStoreyData?.walls
    ),
  };
};

const generateTopStoreyContour = (
  storey: UserBuildingStorey,
  nextStorey?: UserBuildingStorey
): FlatVector3[][] => {
  if (!nextStorey) return [storey.ceiling.points];

  const diff = polyclip.xor(
    [storey.floor.points.map((p) => [p[0], p[2]])] as polyclip.Geom,
    [nextStorey.floor.points.map((p) => [p[0], p[2]])] as polyclip.Geom
  );
  return diff
    .flat()
    .map((contour) =>
      contour.map((point) => [point[0], storey.ceiling.points[0][1], point[1]])
    );
};

const generateBottomStoreyContour = (
  storey: UserBuildingStorey,
  previousStorey?: UserBuildingStorey
): FlatVector3[][] => {
  if (!previousStorey) {
    return [storey.floor.points];
  }
  return [];
};

export const findStoreyContour = (
  storey: UserBuildingStorey,
  previousStorey?: UserBuildingStorey,
  nextStorey?: UserBuildingStorey
) => {
  return [
    generateTopStoreyContour(storey, nextStorey),
    generateBottomStoreyContour(storey, previousStorey),
  ].flat();
};

export const getBlockContour = (block: UserBuildingBlock) =>
  block.storeys.map((storey, i) =>
    findStoreyContour(storey, block.storeys[i - 1], block.storeys[i + 1])
  );

export const getEdgePoints = (storey: UserBuildingStorey): FlatVector3[] => {
  const points = storey.floor.points;

  const edgePoint: FlatVector3[] = [];

  for (let i = 0; i < points.length - 1; i++) {
    const previousIndex = i === 0 ? points.length - 1 : i - 1;
    const nextIndex = i === points.length - 1 ? 0 : i + 1;

    const isInLine = isPointsInOneLine(
      [points[previousIndex][0], points[previousIndex][2]],
      [points[i][0], points[i][2]],
      [points[nextIndex][0], points[nextIndex][2]]
    );
    !isInLine && edgePoint.push(points[i]);
  }
  return edgePoint;
};

export const getBlockContourMaterial = () => {
  const material = C_FatLineSelectedBorderMaterial.clone();
  material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.HIGH;
  return material;
};

export const generateVerticalBlockContour = (
  point: FlatVector3,
  storeyCeilingPoint: FlatVector3
) => {
  return createLine2(
    [point, [point[0], storeyCeilingPoint[1], point[2]]].flat(),
    getBlockContourMaterial()
  );
};

export const generateBlock = (
  storeysData: UserBuildingStorey[],
  name?: string
): UserBuildingBlock => {
  return {
    guid: uuidv7(),
    name: name || 'New Block',
    userData: {},
    storeys: storeysData,
  };
};
