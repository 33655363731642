import React, { useEffect } from 'react';
import { EditModes, NodeType, UserBuildingCoordinatesObject } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getEditedNode,
  getExtrudeNode,
  getIsNodeHovered,
  getSelectedNodeByGUID,
  setIsEditToolsAvailable,
} from '@/store/slices/canvasBuildingSlice';
import Block from '@/routes/dashboard/projects/project/UserBuilding/components/Block';
import ExtrudeBlock from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeBlock';
import CutTool from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutTool';
import { getEditMode } from '@/store/slices/canvasModesSlice';
import { useBuildingInfo } from '@/shared/hooks/useBuildingInfo';

interface BuildingProps {
  building: UserBuildingCoordinatesObject;
}

const Building: React.FC<BuildingProps> = ({ building }) => {
  const dispatch = useAppDispatch();

  const isBuildingSelected = !!useAppSelector(
    getSelectedNodeByGUID(building.guid)
  );

  const isBuildingVisible = !building.userData?.isHidden;
  const extrudeNode = useAppSelector(getExtrudeNode);
  const editMode = useAppSelector(getEditMode);
  const editedNode = useAppSelector(getEditedNode);
  const isBuildingHovered = useAppSelector(getIsNodeHovered(building.guid));
  const { hasCustomPlacedElements } = useBuildingInfo(building.guid);

  useEffect(() => {
    dispatch(
      setIsEditToolsAvailable({
        buildingGUID: building.guid,
        value: !hasCustomPlacedElements(),
      })
    );
  }, [building]);

  return (
    <>
      {isBuildingVisible && (
        <group
          key={`building-${building.guid}`}
          userData={{
            ...building.userData,
            nodeType: NodeType.Building,
            guid: building.guid,
          }}
        >
          {building.blocks
            .filter((block) => !block.userData?.isHidden)
            .map((block) => {
              const isBlockInExtrudeMode =
                extrudeNode && extrudeNode.node.guid === block.guid;

              if (isBlockInExtrudeMode) {
                return (
                  <ExtrudeBlock
                    block={block}
                    key={block.guid}
                    extrudeNode={extrudeNode}
                    buildingGuid={building.guid}
                  />
                );
              }

              return (
                <Block
                  buildingGUID={building.guid}
                  block={block}
                  key={block.guid}
                  isParentSelected={isBuildingSelected}
                  isParentHovered={isBuildingHovered}
                  isParentLocked={building.userData?.isLocked}
                />
              );
            })}
        </group>
      )}
      {editMode === EditModes.Cut &&
        editedNode?.guid === building.guid &&
        building.blocks.length === 1 && (
          <CutTool block={building.blocks[0]} buildingGUID={building.guid} />
        )}
    </>
  );
};

export default Building;
