import { publish, subscribe, unsubscribe } from '@/core/events';
import {
  GENERATE_CAMERA_SNAPSHOTS,
  GENERATE_CAMERA_SNAPSHOTS_SUCCESS,
} from '@/core/event-names';

import { dataURLtoFile } from '@/shared/helpers/format-data';
import refFile from '@/images/reference_image.jpeg';
import axios from 'axios';
import { Filter } from '@/models/camera.model';
import { useEffect, useState } from 'react';
import { uuidv7 } from 'uuidv7';

interface AIResponse {
  data: { task_id: string };
  error_message: string;
  success: boolean;
}

export const useAIGeneration = () => {
  const [pollingController] = useState(new AbortController());

  const onSnapshot = async (
    urls: string[],
    prompts: string[]
  ): Promise<AIResponse[]> => {
    try {
      const expectedFile = dataURLtoFile(urls[0], 'example.jpeg');
      const exampleFile = new File(
        [await (await fetch(refFile)).blob()],
        'example.jpeg'
      );

      const fileDataToUpdate = await axios<{ data: string }>({
        url: 'https://open.lookx.ai/api/v1/files',
        headers: {
          Accept: '*/*',
          'Content-Type': 'multipart/form-data',
          authorization: process.env.REACT_APP_LOOKX_KEY,
        },
        method: 'POST',
        data: {
          file: expectedFile,
        },
      });
      const refFileToUse = await axios<{ data: string }>({
        url: 'https://open.lookx.ai/api/v1/files',
        headers: {
          Accept: '*/*',
          'Content-Type': 'multipart/form-data',
          authorization: process.env.REACT_APP_LOOKX_KEY,
        },
        method: 'POST',
        data: {
          file: exampleFile,
        },
      });

      const createTaskUrl =
        'https://open.lookx.ai/api/v1/generation/images/render';

      const results = Promise.all(
        prompts.map((prompt) => {
          const requestToUpdateData = {
            render_application:
              'ARCHITECTURE_MODEL_CONCEPTUAL_NORMAL|DEFAULT_STYLE',
            style_image_similarity: 0.7,
            style_image_file: refFileToUse.data.data,
            seed: -1,
            negative_prompt: 'tree',
            struct_image_similarity: 1,
            struct_image_file: fileDataToUpdate.data.data,
            output_image_count: 1,
            prompt: prompt,
            render_scene: 'STRICT_RENDER',
          };

          return axios<AIResponse>({
            url: createTaskUrl,
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              authorization: process.env.REACT_APP_LOOKX_KEY,
            },
            method: 'POST',
            data: requestToUpdateData,
          });
        })
      );

      return (await results).map((task) => task.data);
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const checkProgress = async (taskId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        try {
          const progress = await axios({
            url: `https://open.lookx.ai/api/v1/tasks/${taskId}`,
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              authorization: process.env.REACT_APP_LOOKX_KEY,
            },
            method: 'GET',
            signal: pollingController.signal,
          });
          if (progress) {
            const results = progress.data?.data?.result;
            if (results[0]) {
              resolve(results[0]);
              clearInterval(intervalId);
            }
          }
        } catch (err) {
          if (!axios.isCancel(err)) reject(err);
          clearInterval(intervalId);
        }
      }, 10000);
    });
  };

  const getProcessData = async (
    filters: Filter[],
    prompts: string[]
  ): Promise<AIResponse[]> => {
    return new Promise((resolve) => {
      const processId = uuidv7();

      const handleSnapshotSuccess = async (res: {
        detail: { urls: string[]; processId: string };
      }) => {
        const { processId: eventProcessId } = res.detail;

        if (res?.detail?.urls?.length && eventProcessId === processId) {
          unsubscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, handleSnapshotSuccess);

          return resolve(await onSnapshot(res.detail.urls, prompts));
        }
      };

      subscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, handleSnapshotSuccess);
      publish(GENERATE_CAMERA_SNAPSHOTS, {
        processId,
        filters,
      });
    });
  };

  useEffect(() => {
    return () => {
      pollingController.abort();
    };
  }, []);

  return { getProcessData, checkProgress };
};
