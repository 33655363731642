import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { DistanceInput, MetricLimits, NumberedInput } from '@/models';
import leftSideIcon from '@/images/left-side-icon.svg';
import rightSideIcon from '@/images/right-side-icon.svg';

export interface DirectionalInputEntity {
  type: DistanceInput | NumberedInput;
  display: boolean;
  locked: boolean;
  processing: boolean;
  active: boolean;
  value: string;
  prependIcon?: string;
  min: number | null;
  max: number | null;
  validationMessage: null | string;
}
interface CanvasExternalElementsState {
  showDirectionalInput: boolean;
  directionalInputValues: DirectionalInputEntity[];
}

const initialState: CanvasExternalElementsState = {
  showDirectionalInput: false,

  directionalInputValues: [
    {
      type: NumberedInput.Floors,
      display: false,
      locked: false,
      active: false,
      processing: false,
      value: '0',
      min: MetricLimits.FloorsMin,
      max: MetricLimits.FloorsMax,
      validationMessage: null,
    },
    {
      type: DistanceInput.FloorHeight,
      display: false,
      locked: false,
      processing: false,
      active: false,
      value: '0',
      min: MetricLimits.FloorHeightMin,
      max: MetricLimits.FloorHeightMax,
      validationMessage: null,
    },
    {
      type: DistanceInput.Length,
      display: false,
      locked: false,
      processing: false,
      active: false,
      value: '0',
      min: MetricLimits.WidthLengthMin,
      max: MetricLimits.WidthLengthMax,
      validationMessage: null,
    },
    {
      type: DistanceInput.Width,
      display: false,
      locked: false,
      processing: false,
      active: false,
      value: '0',
      min: MetricLimits.WidthLengthMin,
      max: MetricLimits.WidthLengthMax,
      validationMessage: null,
    },
    //Default value
    {
      type: DistanceInput.Distance,
      display: false,
      locked: false,
      processing: true,
      active: false,
      value: '0',
      min: null,
      max: null,
      validationMessage: null,
    },
    {
      type: DistanceInput.BuildingWidth,
      display: false,
      locked: false,
      processing: false,
      active: false,
      value: '0',
      min: MetricLimits.CenterLineBuildingWidthMin,
      max: MetricLimits.CenterLineBuildingWidthLengthMax,
      validationMessage: null,
    },
    {
      type: DistanceInput.LeftEdgeDistance,
      display: false,
      locked: false,
      processing: false,
      active: false,
      value: '0',
      prependIcon: leftSideIcon,
      min: null,
      max: null,
      validationMessage: null,
    },
    {
      type: DistanceInput.RightEdgeDistance,
      display: false,
      locked: false,
      processing: false,
      active: false,
      value: '0',
      prependIcon: rightSideIcon,
      min: null,
      max: null,
      validationMessage: null,
    },
  ],
};

export const canvasExternalElementsSlice = createSlice({
  name: 'canvasExternalElements',
  initialState,
  reducers: {
    setShowDirectionalInput: (
      state,
      action: PayloadAction<{
        isShow: boolean;
      }>
    ) => {
      state.showDirectionalInput = action.payload.isShow;
    },
    setDirectionalInputValues: (
      state,
      action: PayloadAction<Partial<DirectionalInputEntity>[]>
    ) => {
      const newProcessingInput = action.payload.find((v) => v.processing);
      const newActiveInput = action.payload.find((v) => v.active);
      state.directionalInputValues = state.directionalInputValues.map((cur) => {
        const valueToMergeInCurrent = action.payload.find(
          (newVal) => newVal.type === cur.type
        );
        return {
          ...cur,
          ...valueToMergeInCurrent,
          processing: newProcessingInput
            ? !!valueToMergeInCurrent?.processing
            : false,
          active: newActiveInput ? !!valueToMergeInCurrent?.active : false,
        };
      });
    },
    resetExternalElementsState: () => initialState,
  },
});

export const {
  setShowDirectionalInput,
  resetExternalElementsState,
  setDirectionalInputValues,
} = canvasExternalElementsSlice.actions;

export const getShowDirectionalInput = (state: RootState) => {
  return state.canvasReducer.canvasExternalElements.showDirectionalInput;
};

export const getDirectionalInputValues = (state: RootState) => {
  return state.canvasReducer.canvasExternalElements.directionalInputValues;
};

export const getProcessingEntity = createSelector(
  [getDirectionalInputValues],
  (values) => values.find((val) => val.processing)
);
