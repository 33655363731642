import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MetricLimits, NodeType, UserBuildingBlock } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import {
  BlockSearchResults,
  useFindNodeData,
} from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useUpdateUserBuildingBlockMutation } from '@/store/apis/projectsApi';
import { useAppSelector } from '@/store/hooks';
import { getIsEditToolsAvailable } from '@/store/slices/canvasBuildingSlice';
import { isEqual } from 'lodash';
import PanelMetrics from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/PanelMetrics';
import WindowsMetrics from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/WindowsMetrics';

interface BlockFrameProps {
  blocksGUIDs: string[];
  isProjectLocked: boolean;
}

const BlockFrame: React.FC<BlockFrameProps> = ({
  blocksGUIDs,
  isProjectLocked,
}) => {
  const { findDataForBlock } = useFindNodeData();
  const [updateUserBuildingBlock] = useUpdateUserBuildingBlockMutation();
  const userBuildingUtils = useUpdateUserBuildingData();

  const selectedBlocksData = blocksGUIDs.map(
    (blockGUID) => findDataForBlock(blockGUID)!
  );

  const buildingGUID = selectedBlocksData[0]?.parentNodes.find(
    (node) => node.type === NodeType.Building
  )?.guid;

  const isEditToolsAvailable = useAppSelector(
    getIsEditToolsAvailable(buildingGUID || '')
  );

  const isEditable = !isProjectLocked && isEditToolsAvailable;

  const getBlockName = (block: BlockSearchResults) => {
    return block?.name;
  };

  const [initialBuildingBlocks, setInitialBuildingBlocks] =
    useState<UserBuildingBlock[]>(selectedBlocksData);

  const updateInitialBuildingBlocks = useCallback(
    (prevBlocks: UserBuildingBlock[]) =>
      !isEqual(
        blocksGUIDs,
        prevBlocks.map((block) => block.guid)
      )
        ? selectedBlocksData
        : prevBlocks,
    [blocksGUIDs, selectedBlocksData]
  );

  useEffect(() => {
    setInitialBuildingBlocks(updateInitialBuildingBlocks);
  }, [updateInitialBuildingBlocks]);

  const handleSubmit = () => {
    selectedBlocksData.forEach((userBuildingBlock) => {
      updateUserBuildingBlock({
        data: userBuildingBlock,
      });
    });
    setInitialBuildingBlocks(selectedBlocksData);
  };

  const changeStoreysCountMetric = (value: string) => {
    initialBuildingBlocks.forEach((userBuildingBlock) => {
      const updatedBlock = changeStoreysNumberInBlock(value, userBuildingBlock);

      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock,
      });
    });
  };

  const changeFloorHeightMetric = (value: string) => {
    initialBuildingBlocks.forEach((userBuildingBlock) => {
      const updatedBlock = updateFloorHeightInBlock(value, userBuildingBlock);

      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock,
      });
    });
  };

  const {
    getBlockStoreysCountMetric,
    getFloorHeightInBlock,
    getBlockHeightMetric,
    getFacadesAreaMetricForBlocks,
    getGrossInternalAreaForBlocks,
    changeStoreysNumberInBlock,
    updateFloorHeightInBlock,
  } = useFrameProperties();

  const blocksStoreysCountMetric =
    getBlockStoreysCountMetric(selectedBlocksData);

  const panelsPlacementData = useMemo(
    () =>
      selectedBlocksData.flatMap((block) =>
        block.storeys.flatMap((storey) =>
          storey.walls.flatMap((wall) => wall.wallPanels)
        )
      ),
    [selectedBlocksData]
  );
  const windowPlacementIds = useMemo(() => {
    return selectedBlocksData.flatMap((block) =>
      block.storeys.flatMap((storey) =>
        storey.walls.flatMap((wall) =>
          wall.windowPlacements.flatMap((placement) => placement.windowId)
        )
      )
    );
  }, [selectedBlocksData]);

  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box border-solid border border-t-0 border-x-0 border-light-gray-20">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {selectedBlocksData.length > 1
            ? `Block (${selectedBlocksData.length})`
            : getBlockName(selectedBlocksData[0])}
        </span>
      </div>
      <div className={'overflow-y-auto '}>
        <div
          className={
            'flex flex-col text-xs border border-l-0 border-r-0 border-t-0 border-solid border-light-gray-20 !bg-white text-dark-gray-100'
          }
        >
          <MetricsHeader />
          <PropertyList
            alignValueLeft
            key={blocksGUIDs.join()}
            properties={[
              {
                name: 'Floor height',
                value: getFloorHeightInBlock(selectedBlocksData),
                isEditable: isEditable,
                onEdit: changeFloorHeightMetric,
                onSubmit: handleSubmit,
                min: MetricLimits.FloorHeightMin,
                max: MetricLimits.FloorHeightMax,
              },
              {
                name: 'Floor count',
                value: blocksStoreysCountMetric,
                isEditable: isEditable,
                onEdit: changeStoreysCountMetric,
                onSubmit: handleSubmit,
                min: MetricLimits.FloorsMin,
                max: MetricLimits.FloorsMax,
                staticValue: isEditToolsAvailable,
              },
              {
                name: 'Block height',
                value: getBlockHeightMetric(selectedBlocksData),
              },
            ]}
          />
        </div>
        <div>
          <TotalSurface
            facadesArea={getFacadesAreaMetricForBlocks(selectedBlocksData)}
            grossInternalArea={getGrossInternalAreaForBlocks(
              selectedBlocksData
            )}
          />

          {panelsPlacementData.length > 0 && (
            <PanelMetrics panelsPlacementData={panelsPlacementData} />
          )}

          {windowPlacementIds.length > 0 && (
            <WindowsMetrics windowIds={windowPlacementIds} />
          )}
        </div>
      </div>
    </>
  );
};

export default BlockFrame;
