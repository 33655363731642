import React, { useEffect, useMemo, useState } from 'react';
import {
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  isPointInsideArea,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as THREE from 'three';
import { CanvasCameraType, FlatVector3, SelectedNode } from '@/models';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { Html } from '@react-three/drei';
import { ExtrudeDotIcon } from '@/shared/icons/ExtrudeDotIcon';
import { calculationMatrix2D } from '@/shared/helpers/matrix';
import { getScaleRatioForCameraZoom } from '@/shared/helpers/canvas-verifiers';
import { useThree } from '@react-three/fiber';

export interface ExtrudeHandlerData {
  defaultCenter: FlatVector3;
  extendAnchor: FlatVector3;
  wallCoordinates: FlatVector3[];
  node: SelectedNode;
  facadeWallsGuids?: string[];
}

interface ExtrudeHandlersProps {
  extrudeHandlerData: ExtrudeHandlerData;
  active?: boolean;
  clickAction?: () => void;
  shapeCoordinates?: FlatVector3[];
  position?: THREE.Vector3;
}

export const EXTRUDE_DOT_MARGIN = 0.001;

const ExtrudeDotHandler: React.FC<ExtrudeHandlersProps> = ({
  extrudeHandlerData,
  active,
  clickAction,
  shapeCoordinates,
  position,
}) => {
  const { defaultCenter, extendAnchor, wallCoordinates } = extrudeHandlerData;

  const { camera, size, controls } = useThree();
  const [scale, setScale] = useState<number>(1);
  const [hovered, setHovered] = useState(false);

  const handleCameraMove = () => {
    const scaleValue = getScaleRatioForCameraZoom(
      camera,
      size,
      convertFlatVector3ToVector(extrudeHandlerData.defaultCenter),
      0.058
    );

    const restrictedScale = () => {
      const cameraScale =
        camera.type === CanvasCameraType.Perspective
          ? scaleValue / 2
          : scaleValue;
      if (cameraScale < 1) return 1;
      if (cameraScale > 9) return 9;

      return cameraScale;
    };

    setScale(restrictedScale());
  };

  useEffect(() => {
    handleCameraMove();
    controls?.addEventListener('change', handleCameraMove);

    return () => {
      controls?.removeEventListener('change', handleCameraMove);
    };
  }, []);

  const getPosition = (positiveMargin?: boolean) => {
    const min = Math.min(...wallCoordinates.map((c) => c[1]));
    const bottomCoordinates = wallCoordinates.filter(
      (points) => points[1] === min
    );

    const normal = getPerpendicularVectorToVectors(
      convertFlatVector3ToVectors(bottomCoordinates),
      true
    );
    return getTranslatedVector(
      convertFlatVector3ToVector(defaultCenter),
      positiveMargin ? EXTRUDE_DOT_MARGIN : -EXTRUDE_DOT_MARGIN,
      normal
    );
  };

  const handlerPosition = useMemo(() => {
    const positionWithNegativeMargin = getPosition();

    if (shapeCoordinates) {
      const isPointInside = isPointInsideArea(
        positionWithNegativeMargin,
        shapeCoordinates.map((p) => convertFlatVector3ToVector(p))
      );

      if (isPointInside) {
        return getPosition(true);
      }
    }
    return positionWithNegativeMargin;
  }, [shapeCoordinates, defaultCenter, extendAnchor]);

  return (
    <Html
      center
      occlude
      position={position ?? handlerPosition}
      zIndexRange={[0, 0]}
      className="select-none"
      calculatePosition={calculationMatrix2D(0, 0)}
    >
      <button
        className="border-none bg-transparent outline-none select-none p-0"
        onPointerDown={(event) => {
          event.stopPropagation();
          clickAction && clickAction();
        }}
        onPointerEnter={() => setHovered(true)}
        onPointerLeave={() => setHovered(false)}
        onPointerMove={(event) => event.stopPropagation()}
      >
        <ExtrudeDotIcon
          className={`w-[${17 - scale}px] h-[${19 - scale}px]`}
          fill={active || hovered ? '#05299E' : '#758DDB'}
        />
      </button>
    </Html>
  );
};

export default ExtrudeDotHandler;
