import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { BuildingFacadeData, NodeType, SelectedNode } from '@/models';
import { ExtrudeHandlerData } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';

interface CanvasBuildingState {
  selectedNodes: { [guid: string]: SelectedNode };
  hoveredNodeGUID?: string;
  isolatedNodes: { [guid: string]: boolean };
  extrudeNode?: ExtrudeHandlerData;
  editedNode?: SelectedNode;
  buildingFacadeData: BuildingFacadeData;
  isEditToolsAvailable: { [buildingGUID: string]: boolean };
}

const initialState: CanvasBuildingState = {
  selectedNodes: {},
  isolatedNodes: {},
  buildingFacadeData: [],
  isEditToolsAvailable: {},
};

const defaultSelectedNodes: SelectedNode[] = [];

export const canvasBuildingSlice = createSlice({
  name: 'canvasBuilding',
  initialState,
  reducers: {
    selectOneNode: (state, { payload }: PayloadAction<SelectedNode>) => {
      state.selectedNodes = { ...{ [payload.guid]: payload } };
    },
    addToSelectNode: (state, { payload }: PayloadAction<SelectedNode>) => {
      state.selectedNodes = {
        ...state.selectedNodes,
        ...{ [payload.guid]: payload },
      };
    },
    selectNodes: (state, { payload }: PayloadAction<SelectedNode[]>) => {
      state.selectedNodes = payload.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.guid]: curr,
        };
      }, {});
    },
    addNodesToSelectNodes: (
      state,
      { payload }: PayloadAction<SelectedNode[]>
    ) => {
      state.selectedNodes = payload.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.guid]: curr,
          };
        },
        { ...state.selectedNodes }
      );
    },
    removeFromSelectNode: (state, { payload }: PayloadAction<string>) => {
      delete state.selectedNodes[payload];
    },
    removeFromSelectedNodeArray: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      payload.forEach((key) => delete state.selectedNodes[key]);
    },
    clearSelectedNodes: (state) => {
      state.selectedNodes = initialState.selectedNodes;
    },
    setHoveredNode: (state, { payload }: PayloadAction<string>) => {
      state.hoveredNodeGUID = payload;
    },
    switchIsolatedFlags: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach(
        (guid) => (state.isolatedNodes[guid] = !state.isolatedNodes[guid])
      );
    },
    resetHoveredNode: (state) => {
      state.hoveredNodeGUID = initialState.hoveredNodeGUID;
    },
    clearIsolatedNodes: (state) => {
      state.isolatedNodes = initialState.isolatedNodes;
    },
    setExtrudeNode: (
      state,
      { payload }: PayloadAction<ExtrudeHandlerData | undefined>
    ) => {
      state.extrudeNode = payload;
    },
    setEditedNode: (
      state,
      { payload }: PayloadAction<SelectedNode | undefined>
    ) => {
      state.editedNode = payload;
    },
    setBuildingFacadeData: (
      state,
      { payload }: PayloadAction<BuildingFacadeData>
    ) => {
      state.buildingFacadeData = payload;
    },
    setIsEditToolsAvailable: (
      state,
      { payload }: PayloadAction<{ buildingGUID: string; value: boolean }>
    ) => {
      state.isEditToolsAvailable = {
        ...state.isEditToolsAvailable,
        [payload.buildingGUID]: payload.value,
      };
    },
  },
});

export const {
  selectOneNode,
  clearSelectedNodes,
  setHoveredNode,
  resetHoveredNode,
  addToSelectNode,
  removeFromSelectNode,
  removeFromSelectedNodeArray,
  switchIsolatedFlags,
  setBuildingFacadeData,
  clearIsolatedNodes,
  setExtrudeNode,
  setEditedNode,
  selectNodes,
  setIsEditToolsAvailable,
  addNodesToSelectNodes,
} = canvasBuildingSlice.actions;

export const getSelectedNodes = (state: RootState) =>
  state.canvasReducer.canvasBuilding.selectedNodes;

export const getHoveredNode = (state: RootState) =>
  state.canvasReducer.canvasBuilding.hoveredNodeGUID;

export const getSelectedNodeByGUID = (guid: string) => (state: RootState) =>
  state.canvasReducer.canvasBuilding.selectedNodes[guid];

export const getIsNodeSelected = (guid: string) => (state: RootState) =>
  !!state.canvasReducer.canvasBuilding.selectedNodes[guid];

export const getIsSingleNodeSelected = (state: RootState): boolean =>
  Object.keys(state.canvasReducer.canvasBuilding.selectedNodes).length === 1;

export const getSelectedNodesByType =
  (type: NodeType) =>
  (state: RootState): SelectedNode[] => {
    const selectedNodes = Object.values(
      state.canvasReducer.canvasBuilding.selectedNodes
    ).filter((node) => type === node.type);
    return selectedNodes.length > 0 ? selectedNodes : defaultSelectedNodes;
  };

export const getLastSelectedNode = (state: RootState) => {
  const selectedNodes = Object.values(
    state.canvasReducer.canvasBuilding.selectedNodes
  );
  return selectedNodes?.length ? selectedNodes[selectedNodes.length - 1] : null;
};

export const getIsNodeHovered = (guid: string) => (state: RootState) =>
  state.canvasReducer.canvasBuilding.hoveredNodeGUID === guid;

export const getIsNodeIsolated =
  (guid: string) =>
  (state: RootState): boolean =>
    state.canvasReducer.canvasBuilding.isolatedNodes[guid];

export const getIsolatedNodes = (state: RootState) =>
  state.canvasReducer.canvasBuilding.isolatedNodes;

export const getExtrudeNode = (state: RootState) =>
  state.canvasReducer.canvasBuilding.extrudeNode;

export const getIsNodeEdited = (guid: string) => (state: RootState) =>
  state.canvasReducer.canvasBuilding.editedNode?.guid === guid;

export const getEditedNode = (state: RootState) =>
  state.canvasReducer.canvasBuilding.editedNode;

export const isSingleNodeIsolated = (state: RootState) =>
  Object.keys(state.canvasReducer.canvasBuilding.isolatedNodes).length === 1;

export const isSurroundingBuildingSelected = (state: RootState) =>
  Object.values(state.canvasReducer.canvasBuilding.selectedNodes).some(
    (node) => node.type === NodeType.SurroundingBuilding
  );

export const getBuildingFacadeData = (state: RootState) =>
  state.canvasReducer.canvasBuilding.buildingFacadeData;

export const getIsEditToolsAvailable =
  (buildingGUID: string) => (state: RootState) =>
    state.canvasReducer.canvasBuilding.isEditToolsAvailable[buildingGUID];
