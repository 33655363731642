import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  updateGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  C_FatLineBorderMaterial,
  C_LeftSplitFaceMaterial,
  C_RightSplitFaceMaterial,
  LEFT_SPLIT_FACE_COLOR,
  RIGHT_SPLIT_FACE_COLOR,
} from '@/shared/materials';
import { FlatVector3 } from '@/models';
import {
  createLine2,
  ExtendedMinMaxCoordinatesPairs,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { BufferGeometry } from 'three';
import { useUnmount } from 'react-use';
import { disposeNode } from '@/shared/helpers/canvas';

const SplitFaces = ({
  position,
  minMaxCoordinates,
  wallPoints,
  isBuildingClockwise,
}: {
  position: THREE.Vector3;
  minMaxCoordinates: ExtendedMinMaxCoordinatesPairs;
  wallPoints: FlatVector3[];
  isBuildingClockwise?: boolean;
}) => {
  const [leftLine, setLeftLine] = useState<Line2>();
  const [rightLine, setRightLine] = useState<Line2>();
  const [leftFaceGeometry, setLeftFaceGeometry] = useState<BufferGeometry>();
  const [rightFaceGeometry, setRightFaceGeometry] = useState<BufferGeometry>();
  const [bottomLeftLine, setBottomLeftLine] = useState<Line2>();
  const [bottomRightLine, setBottomRightLine] = useState<Line2>();

  const positionMinY: FlatVector3 = [
    position.x,
    minMaxCoordinates.min.y,
    position.z,
  ];
  const positionMaxY: FlatVector3 = [
    position.x,
    minMaxCoordinates.max.y,
    position.z,
  ];

  const faceCoordinates1 = useMemo(
    () =>
      convertFlatVector3ToVectors([
        [wallPoints[1][0], minMaxCoordinates.min.y, wallPoints[1][2]],
        [wallPoints[2][0], minMaxCoordinates.max.y, wallPoints[2][2]],
        positionMaxY,
        positionMinY,
      ]),
    [position]
  );

  const faceCoordinates2 = useMemo(
    () =>
      convertFlatVector3ToVectors([
        positionMinY,
        positionMaxY,
        [wallPoints[3][0], minMaxCoordinates.max.y, wallPoints[3][2]],
        [wallPoints[0][0], minMaxCoordinates.min.y, wallPoints[0][2]],
      ]),
    [position]
  );

  useEffect(() => {
    const points = isBuildingClockwise ? faceCoordinates2 : faceCoordinates1;
    if (!leftFaceGeometry) {
      const geometry = createGeometryFromVectorList(points, 'vertical');
      setLeftFaceGeometry(geometry);
    } else {
      updateGeometryFromVectorList(leftFaceGeometry, points, 'vertical');
    }
    return () => {
      leftFaceGeometry && leftFaceGeometry.dispose();
    };
  }, [position, isBuildingClockwise]);

  useEffect(() => {
    const points = isBuildingClockwise ? faceCoordinates1 : faceCoordinates2;

    if (!rightFaceGeometry) {
      const geometry = createGeometryFromVectorList(points, 'vertical');
      setRightFaceGeometry(geometry);
    } else {
      updateGeometryFromVectorList(rightFaceGeometry, points, 'vertical');
    }
    return () => {
      rightFaceGeometry && rightFaceGeometry.dispose();
    };
  }, [position, isBuildingClockwise]);

  const updateLines = useCallback(
    (cursorPosition: THREE.Vector3) => {
      const leftLinePoints = [
        wallPoints[2][0],
        minMaxCoordinates.max.y,
        wallPoints[2][2],
        cursorPosition.x,
        minMaxCoordinates.max.y,
        cursorPosition.z,
      ];
      const bottomLeftLinePoints = [
        wallPoints[2][0],
        minMaxCoordinates.min.y,
        wallPoints[2][2],
        cursorPosition.x,
        minMaxCoordinates.min.y,
        cursorPosition.z,
      ];
      const rightLinePoints = [
        cursorPosition.x,
        minMaxCoordinates.max.y,
        cursorPosition.z,
        wallPoints[3][0],
        minMaxCoordinates.max.y,
        wallPoints[3][2],
      ];
      const bottomRightLinePoints = [
        cursorPosition.x,
        minMaxCoordinates.min.y,
        cursorPosition.z,
        wallPoints[3][0],
        minMaxCoordinates.min.y,
        wallPoints[3][2],
      ];

      if (!leftLine) {
        const material = C_FatLineBorderMaterial.clone();
        material.color = LEFT_SPLIT_FACE_COLOR;
        material.linewidth = material.linewidth * 3;
        material.depthTest = false;
        setLeftLine(createLine2(leftLinePoints, material));
        setBottomLeftLine(createLine2(bottomLeftLinePoints, material));
      }
      if (!rightLine) {
        const material = C_FatLineBorderMaterial.clone();
        material.color = RIGHT_SPLIT_FACE_COLOR;
        material.linewidth = material.linewidth * 3;
        material.depthTest = false;
        setRightLine(createLine2(rightLinePoints, material));
        setBottomRightLine(createLine2(bottomRightLinePoints, material));
      }

      leftLine && updateLine2Position(leftLine, leftLinePoints);
      rightLine && updateLine2Position(rightLine, rightLinePoints);

      bottomLeftLine &&
        updateLine2Position(bottomLeftLine, bottomLeftLinePoints);
      bottomRightLine &&
        updateLine2Position(bottomRightLine, bottomRightLinePoints);
    },
    [wallPoints, minMaxCoordinates]
  );

  useEffect(() => {
    updateLines(position);
  }, [position]);

  useUnmount(() => {
    leftLine && disposeNode(leftLine);
    rightLine && disposeNode(rightLine);
    bottomLeftLine && disposeNode(bottomLeftLine);
    bottomRightLine && disposeNode(bottomRightLine);
    leftFaceGeometry && leftFaceGeometry.dispose();
    rightFaceGeometry && rightFaceGeometry.dispose();
  });

  return (
    <group>
      {leftFaceGeometry && (
        <mesh geometry={leftFaceGeometry} material={C_LeftSplitFaceMaterial} />
      )}
      {rightFaceGeometry && (
        <mesh
          geometry={rightFaceGeometry}
          material={C_RightSplitFaceMaterial}
        />
      )}
      {leftLine && <primitive object={leftLine} position={[0, 0.0001, 0]} />}
      {rightLine && <primitive object={rightLine} position={[0, 0.0001, 0]} />}
      {bottomLeftLine && <primitive object={bottomLeftLine} />}
      {bottomRightLine && <primitive object={bottomRightLine} />}
    </group>
  );
};

export default SplitFaces;
