import React, { useEffect, useMemo, useState } from 'react';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { Vector3, Line3 } from 'three';
import { flatten } from 'lodash';
import { uuidv7 } from 'uuidv7';

import {
  CanvasActionsModes,
  DrawModes,
  EditModes,
  FlatVector3,
  NodeType,
  SelectedNode,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getEditedNode,
  setEditedNode,
  switchIsolatedFlags,
} from '@/store/slices/canvasBuildingSlice';
import {
  generateTopBorderSurface,
  generateWallArrayFromStorey,
  generateWallMesh,
} from '@/routes/dashboard/projects/project/UserBuilding/helpers/editing-tools.helpers';
import {
  getProcessingEntity,
  resetExternalElementsState,
} from '@/store/slices/canvasExternalElementsSlice';
import SplitFaces from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitFaces';
import SplitLine from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitLine';
import {
  ExtendedMinMaxCoordinatesPairs,
  getMinMaxCoordinatesAtVector3,
  getXYZ,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  getAllWallsAtOneSideAtBlock,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import useMouseOutsideMesh from '@/shared/hooks/useMouseOutsideMesh';
import { useSplitCutDirectionalInput } from '@/routes/dashboard/projects/project/UserBuilding/helpers/useSplitCutDirectionalInput';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import {
  getStatusIsolateMode,
  setEditMode,
  setMode,
} from '@/store/slices/canvasModesSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { isRightClick } from '@/shared/helpers';
import { getIsCameraRotating } from '@/store/slices/canvasCamerasSlice';
import { useUnmount } from 'react-use';
import { disposeNode } from '@/shared/helpers/canvas';

interface SplitToolProps {
  block: UserBuildingBlock;
  buildingGUID: string;
}

const SplitTool: React.FC<SplitToolProps> = ({ block, buildingGUID }) => {
  const dispatch = useAppDispatch();

  const editedNode = useAppSelector(getEditedNode);
  const processingEntity = useAppSelector(getProcessingEntity);
  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);
  const isCameraRotating = useAppSelector(getIsCameraRotating);
  const { scene } = useThree();

  const [isAllowedToSplit, setIsAllowedToSplit] = useState(false);
  const [mousePosition, setMousePosition] = useState<Vector3 | null>(null);
  const [currentFacePoints, setCurrentFacePoints] = useState<FlatVector3[]>();
  const [minMax, setMinMax] = useState<ExtendedMinMaxCoordinatesPairs>();
  const [currentWall, setCurrentWall] = useState<UserBuildingWall>();
  const [isMouseOnWall, setIsMouseOnWall] = useState(false);

  const {
    updateBlockStoreys,
    updateUserBuildingStoreyData,
    updateUserBuildingDrawMode,
  } = useUpdateUserBuildingData();
  const { addNodesToSelectedNodes } = useSelectedNodes();

  const findWall = () => {
    let wallData: UserBuildingWall | undefined;
    const storeyData = block.storeys.find((storey) => {
      const wall = storey.walls.find((wall) => wall.guid === editedNode?.guid);
      if (wall) {
        wallData = wall;
      }

      return wall;
    });
    return { storeyData, wallData };
  };

  const findStorey = () => {
    let wallData: UserBuildingWall | undefined;
    const storeyData = block.storeys.find((storey) => {
      if (storey.guid === editedNode?.guid) {
        const wall = storey.walls.find(
          (wall) => wall.guid === currentWall?.guid
        );
        if (wall) {
          wallData = wall;
        }
        return true;
      }

      return false;
    });
    return { storeyData, wallData };
  };
  const findWallOrStorey = () => {
    return editedNode?.type === NodeType.Wall ? findWall() : findStorey();
  };

  const isBlockSplitted =
    editedNode?.type === NodeType.Block ||
    editedNode?.type === NodeType.Building;
  const isWallSplitted = editedNode?.type === NodeType.Wall;
  const isStoreySplitted = editedNode?.type === NodeType.Storey;

  const wallArray = useMemo(() => {
    if (!editedNode) return [];
    if (isBlockSplitted) {
      const allAvailableFacadesForSplit = block.storeys[0].walls.map((wall) =>
        getAllWallsAtOneSideAtBlock(block, wall.guid)
      );
      const facadeMeshes = allAvailableFacadesForSplit.map((walls) => {
        return walls.map((wall, storeyIndex) => {
          return generateWallMesh(
            wall.points,
            block.storeys[storeyIndex].floor.points,
            block,
            storeyIndex
          );
        });
      });
      return facadeMeshes.flat();
    }
    if (isStoreySplitted) {
      return generateWallArrayFromStorey(block, editedNode.guid);
    }
    if (isWallSplitted) {
      const { storeyData, wallData } = findWall();
      if (!storeyData) return [];
      return [
        generateWallMesh(
          wallData!.points,
          storeyData.floor.points,
          block,
          storeyData.storeyNumber - 1
        ),
      ];
    }
    return [];
  }, [block]);

  const topBorderEdgeArray = useMemo(() => {
    return block.storeys[0].walls
      .map((wall) => getAllWallsAtOneSideAtBlock(block, wall.guid))
      .map((facade) => facade[facade.length - 1])
      .filter((facade) => facade)
      .map((edge) =>
        generateTopBorderSurface(
          edge.points[3],
          edge.points[2],
          block,
          block.storeys.length - 1
        )
      );
  }, [block]);

  const topBorderArray = useMemo(() => {
    const handleMousePosition = (wall: UserBuildingWall, mouse: Vector3) => {
      if (processingEntity?.active) return;
      const start = convertFlatVector3ToVector(wall.points[0]);
      const end = convertFlatVector3ToVector(wall.points[1]);
      const closestPoint = new Vector3();
      new Line3(start, end).closestPointToPoint(mouse, true, closestPoint);
      handlePointerMove(closestPoint, wall);
    };

    return topBorderEdgeArray.map((edge) =>
      edge ? (
        <primitive
          object={edge.border}
          onPointerMove={(e: ThreeEvent<PointerEvent>) => {
            if (isMouseOnWall) return;
            e.stopPropagation();
            edge.wall && handleMousePosition(edge.wall, e.point);
          }}
          onPointerEnter={() => setIsAllowedToSplit(true)}
          key={edge.border.id}
          position={[0, 0.001, 0]}
          visible={false}
        />
      ) : null
    );
  }, [block]);

  const findFacePoints = (wall: UserBuildingWall) => {
    if (isBlockSplitted) {
      const facadeWalls = getAllWallsAtOneSideAtBlock(block, wall.guid);
      const facadeMinMax = getMinMaxCoordinatesAtVector3(
        convertFlatVector3ToVectors(
          flatten(facadeWalls.map((wall) => wall.points))
        )
      );
      setMinMax(facadeMinMax);
      setCurrentFacePoints([
        facadeWalls[0].points[0],
        facadeWalls[0].points[1],
        facadeWalls[facadeWalls.length - 1].points[2],
        facadeWalls[facadeWalls.length - 1].points[3],
      ]);
    }
    if ((isWallSplitted || isStoreySplitted) && wall) {
      const minMax = getMinMaxCoordinatesAtVector3(
        convertFlatVector3ToVectors(wall.points)
      );
      setMinMax(minMax);
      setCurrentFacePoints(wall.points);
    }
  };

  const handlePointerMove = (position: Vector3, wall: UserBuildingWall) => {
    if (isCameraRotating) return;
    !isAllowedToSplit && setIsAllowedToSplit(true);
    if (processingEntity?.active) return;
    setCurrentWall(wall);
    setMousePosition(position);
    findFacePoints(wall);
  };

  const updateShapePoints = (
    floorPoints: FlatVector3[],
    ceilingPoints: FlatVector3[],
    insertPoint: Vector3
  ) => {
    let insertionIndex = 0;
    const insertionPoint = new Vector3(
      insertPoint.x,
      floorPoints[0][1],
      insertPoint.z
    );
    for (let i = 0; i < floorPoints.length - 1; i++) {
      const p = isPointsInOneLine(
        [floorPoints[i][0], floorPoints[i][2]],
        [insertPoint.x, insertPoint.z],
        [floorPoints[i + 1][0], floorPoints[i + 1][2]]
      );
      if (p) {
        insertionIndex = i + 1;
      }
    }
    const newPoints: FlatVector3[] = [
      ...floorPoints.slice(0, insertionIndex),
      getXYZ(insertionPoint),
      ...floorPoints.slice(insertionIndex),
    ];

    return {
      newFloorPoints: newPoints,
      newCeilingPoints: newPoints.map(
        (point): FlatVector3 => [point[0], ceilingPoints[0][1], point[2]]
      ),
    };
  };

  const getNewWallsAfterSplit = (
    previousWallData: UserBuildingWall,
    walls: UserBuildingWall[],
    splitPosition: Vector3
  ) => {
    const wallPoints = previousWallData.points;

    const newWalls: UserBuildingWall[] = [];

    const newWall1Points: FlatVector3[] = [
      wallPoints[0],
      [splitPosition.x, wallPoints[1][1], splitPosition.z],
      [splitPosition.x, wallPoints[2][1], splitPosition.z],
      wallPoints[3],
    ];

    const newWall2Points: FlatVector3[] = [
      [splitPosition.x, wallPoints[0][1], splitPosition.z],
      wallPoints[1],
      wallPoints[2],
      [splitPosition.x, wallPoints[3][1], splitPosition.z],
    ];

    walls.forEach((wall, wallIndex) => {
      if (wall.guid === previousWallData.guid) {
        newWalls.push({
          ...wall,
          name: wall.name ? `${wall.name} 1` : `Wall ${wallIndex + 1} 1`,
          guid: uuidv7(),
          points: newWall1Points,
        });
        newWalls.push({
          ...wall,
          guid: uuidv7(),
          name: wall.name ? `${wall.name} 2` : `Wall ${wallIndex + 1} 2`,
          points: newWall2Points,
        });
      } else {
        newWalls.push({ ...wall, name: wall.name ?? `Wall ${wallIndex + 1}` });
      }
    });

    return newWalls;
  };

  const generateSplittedStorey = (
    storey: UserBuildingStorey,
    wallData: UserBuildingWall,
    splitPosition: Vector3
  ): UserBuildingStorey => {
    const splitPositionCopy = splitPosition.clone();

    const { newFloorPoints, newCeilingPoints } = updateShapePoints(
      storey.floor.points,
      storey.ceiling.points,
      splitPositionCopy
    );

    const newWalls = getNewWallsAfterSplit(
      wallData,
      storey.walls,
      splitPositionCopy
    );
    return {
      ...storey,
      ceiling: { ...storey.ceiling, points: newCeilingPoints },
      floor: { ...storey.floor, points: newFloorPoints },
      walls: newWalls,
    };
  };

  const generateSplittedBlock = () => {
    const mousePositionCopy = mousePosition!.clone();

    return block.storeys.map((storey, i) => {
      const wall = getAllWallsAtOneSideAtBlock(block, currentWall!.guid)[i];
      return generateSplittedStorey(storey, wall, mousePositionCopy);
    });
  };

  const finishSplit = (event?: PointerEvent) => {
    if (event && isRightClick(event)) return;
    if (!mousePosition || !minMax) return;

    if (isWallSplitted || isStoreySplitted) {
      const { storeyData, wallData } = findWallOrStorey();
      if (!storeyData || !wallData) return;

      const newStorey = generateSplittedStorey(
        storeyData,
        wallData,
        mousePosition
      );

      updateUserBuildingStoreyData({
        buildingGuid: buildingGUID,
        blockGuid: block.guid,
        storeyGuid: storeyData.guid,
        newStorey,
      });

      updateUserBuildingDrawMode({
        buildingGuid: buildingGUID,
        drawMode: DrawModes.FreeDraw,
      });

      const newGuidsToIsolate = newStorey.walls
        .filter(
          (wall) =>
            !storeyData.walls.find(
              (storeyWall) => storeyWall.guid === wall.guid
            )
        )
        .map((wall) => wall.guid);

      isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));

      if (isWallSplitted) {
        const newSelectedNodes: SelectedNode[] = newGuidsToIsolate.map(
          (guid) => ({
            type: NodeType.Wall,
            guid: guid,
          })
        );
        addNodesToSelectedNodes(newSelectedNodes);
      }

      if (isStoreySplitted) {
        addNodesToSelectedNodes([editedNode]);
      }
    }
    if (isBlockSplitted) {
      const newStoreys = generateSplittedBlock();

      updateBlockStoreys({
        buildingGUID,
        blockGUID: block.guid,
        newStoreys,
      });
      updateUserBuildingDrawMode({
        buildingGuid: buildingGUID,
        drawMode: DrawModes.FreeDraw,
      });

      if (editedNode) {
        addNodesToSelectedNodes([editedNode]);
      }

      const newGuidsToIsolate = flatten(
        newStoreys.map((storey) =>
          storey.walls
            .filter(
              (wall) =>
                !block.storeys.find((storey) =>
                  storey.walls.find(
                    (storeyWall) => storeyWall.guid === wall.guid
                  )
                )
            )
            .map((wall) => wall.guid)
        )
      );
      isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));
    }

    resetEditMode();
  };

  const resetEditMode = () => {
    dispatch(setEditMode(EditModes.Unset));
    dispatch(setEditedNode(undefined));
    dispatch(resetExternalElementsState());
    dispatch(setMode(CanvasActionsModes.selection));
  };

  const keydownEvent = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      finishSplit();
      resetEditMode();
    }
  };

  useMouseOutsideMesh({
    meshes: [wallArray, topBorderEdgeArray.map((edge) => edge!.border)].flat(),
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsAllowedToSplit(false),
  });

  useMouseOutsideMesh({
    meshes: [...wallArray],
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsMouseOnWall(false),
    onMouseIntersectMeshes: () => !isCameraRotating && setIsMouseOnWall(true),
  });

  useSplitCutDirectionalInput({
    facePoints: currentFacePoints,
    mousePosition,
    isAllowed: isAllowedToSplit,
    setMousePosition,
  });

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    const isAbleToSplit = isAllowedToSplit && !isCameraRotating;
    isAbleToSplit && canvas?.addEventListener('pointerdown', finishSplit);
    isAbleToSplit && document.addEventListener('keydown', keydownEvent);
    return () => {
      canvas?.removeEventListener('pointerdown', finishSplit);
      document.removeEventListener('keydown', keydownEvent);
    };
  }, [mousePosition, isAllowedToSplit, isCameraRotating]);

  useUnmount(() => {
    wallArray.forEach((wall) => {
      disposeNode(wall, scene);
    });
    topBorderEdgeArray.forEach(
      (edgeData) => edgeData && disposeNode(edgeData.border, scene)
    );
  });

  if (!wallArray) return null;

  return (
    <>
      {isBlockSplitted && topBorderArray}
      {wallArray.map((edge) => (
        <primitive
          object={edge}
          onPointerMove={(event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation();
            const position = event.pointOnLine ?? event.point;
            handlePointerMove(position, event.object.userData.wallData);
          }}
          key={edge.uuid}
          onPointerEnter={() => setIsAllowedToSplit(true)}
          visible={false}
        />
      ))}
      {isAllowedToSplit && mousePosition && minMax && currentFacePoints && (
        <>
          <SplitFaces
            position={mousePosition}
            minMaxCoordinates={minMax}
            wallPoints={currentFacePoints}
          />
          <SplitLine position={mousePosition} minMaxCoordinates={minMax} />
        </>
      )}
    </>
  );
};

export default SplitTool;
