import React, { useMemo } from 'react';
import {
  FlatVector2Axis,
  MeasurementElementType,
  SavedWindow,
} from '@/components/WindowCreator/models';
import { Measurement } from '@/shared/components/MeasurementLine';
import WindowLabelIcon from '@/images/window-lable.svg';
import { FlatVector2 } from '@/models';
import useImage from 'use-image';
import { Image, Line } from 'react-konva';
import { getWindowWidth } from '@/shared/helpers';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { round } from 'lodash';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import { convert2DPointsToDistanceInMeters } from '@/shared/helpers/metrics';
import { WINDOW_INFORMATION_PADDING } from '../constants';

interface WindowsInformationMeasurementsProps {
  wallsData: WallSearchResults[];
  scale: number;
  wallsTotalWidth: number;
  startPoint: {
    x: number;
    z: number;
  };
}

const WindowsInformationMeasurements = ({
  wallsData,
  scale,
  startPoint,
  wallsTotalWidth,
}: WindowsInformationMeasurementsProps) => {
  const { id } = useParams();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const [windowLabelIcon] = useImage(WindowLabelIcon);
  const multiplyRate = useAppSelector(getMultiplyRate(id!));

  const getWindowDataById = (id: number): SavedWindow | undefined =>
    windowsData.find((window) => window.id === id);

  const [gridLinePoints, windowPoints] = useMemo(() => {
    const gridLinePoints: number[] = [];
    const windowPoints: number[] = [];

    wallsData.forEach((wall) => {
      const wallBottomLeftPoint: FlatVector2 = [
        wall.points[1][0],
        wall.points[1][2],
      ];

      const wallOffset = round(
        Number(
          convertMetersToMillimeters(
            convert2DPointsToDistanceInMeters(
              wallBottomLeftPoint,
              [startPoint.x, startPoint.z],
              multiplyRate
            )
          )
        ),
        2
      );

      wall.gridLines.forEach((line) =>
        gridLinePoints.push(wallOffset + line.offsetFromLeftEdge)
      );

      wall.windowPlacements.forEach((window) => {
        const windowData = getWindowDataById(window.windowId);
        windowData &&
          windowPoints.push(
            wallOffset + window.offsetFromLeftEdge,
            wallOffset + window.offsetFromLeftEdge + getWindowWidth(windowData)
          );
      });
    });

    const uniqGridLinePoints = Array.from(
      new Set([0, ...gridLinePoints, wallsTotalWidth])
    );

    const uniquePairs = new Set<string>();

    for (let i = 0; i < windowPoints.length - 1; i += 2) {
      uniquePairs.add(`${windowPoints[i]},${windowPoints[i + 1]}`);
    }

    const uniqueWindowLinePoints = Array.from(uniquePairs, (pair) =>
      pair.split(',').map(Number)
    ).flat();

    return [uniqGridLinePoints, uniqueWindowLinePoints];
  }, [wallsData]);

  const generatedPoints = useMemo(() => {
    const allPoints = [...gridLinePoints, ...windowPoints].sort(
      (a, b) => a - b
    );

    const pairPoints: FlatVector2Axis[] = windowPoints.map(
      (windowPoint, index) => {
        const leftPointIndex = allPoints.lastIndexOf(windowPoint);
        const rightPointIndex = allPoints.indexOf(windowPoint);

        return index % 2 === 0
          ? [
              [allPoints[leftPointIndex - 1], -WINDOW_INFORMATION_PADDING],
              [windowPoint, -WINDOW_INFORMATION_PADDING],
            ]
          : [
              [windowPoint, -WINDOW_INFORMATION_PADDING],
              [allPoints[rightPointIndex + 1], -WINDOW_INFORMATION_PADDING],
            ];
      }
    );

    return pairPoints.filter((pair) =>
      pair.every((innerPair) => !isNaN(innerPair[0]))
    );
  }, [gridLinePoints]);

  return (
    <>
      <Image
        image={windowLabelIcon}
        width={29}
        height={24}
        y={-WINDOW_INFORMATION_PADDING - 12 * (1 / scale)}
        scaleX={1 / scale}
        scaleY={1 / scale}
        x={-57 * (1 / scale)}
      />

      {generatedPoints.map((points, idx) => (
        <React.Fragment key={`measurement_group_${idx}`}>
          <Measurement
            key={`measurement_windows_${idx}`}
            points={points}
            scale={scale}
            units={unitSystem}
            level={0}
            disabled={true}
            type={MeasurementElementType.Information}
          />

          {points.map((point, childIndex) => (
            <Line
              key={`measurement_line-${idx}-${childIndex}`}
              points={[point[0], 0, point[0], points[0][1]]}
              stroke="#B3B2B4"
              strokeWidth={1}
              strokeScaleEnabled={false}
              dash={[12, 3, 4, 3]}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default WindowsInformationMeasurements;
