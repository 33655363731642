import React from 'react';
import { IntusButton } from '@/shared/elements';
import { WarningIcon } from '@/shared/icons/WarningIcon';
import { ReportGenerationIcon } from '@/shared/icons/ReportGenerationIcon';
import { ReportStatus } from '@/models/report';
import { useAppDispatch } from '@/store/hooks';
import { setStatusReport } from '@/store/slices/reportSlice';
import { useParams } from 'react-router';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { sleep } from '@/shared/helpers/sleep';
import { useHoveredNode } from '@/shared/hooks/useHoveredNode';

const GenerateContent: React.FC = () => {
  const { id } = useParams();

  if (!id) return <></>;

  const dispatch = useAppDispatch();
  const { clearAllSelectedNodes } = useSelectedNodes();
  const { resetHoveredNode } = useHoveredNode();

  const handelGenerateReport = async () => {
    clearAllSelectedNodes();
    resetHoveredNode();
    await sleep(100);
    dispatch(
      setStatusReport({ id, status: ReportStatus.PROCESSING_AI_GENERATION })
    );
  };

  return (
    <>
      <ReportGenerationIcon className="mb-10" />
      <IntusButton
        id="report__generate-button"
        className="rounded-lg py-[5px] px-[18px] mb-10"
        onClick={handelGenerateReport}
      >
        Generate report
      </IntusButton>
      <div className="flex gap-[10px] items-center mx-6 mb-6 p-3 bg-light-gray-10">
        <WarningIcon className="w-6 h-6" />
        <p className="text-xs leading-5 m-0 max-w-[478px] text-dark-gray-100 font-normal">
          Please make sure that this is the final version of the project before
          proceeding. After the report generation starts, editing of the project
          will be blocked.
        </p>
      </div>
    </>
  );
};

export default GenerateContent;
