import { UnitSystemTypes, UserBuildingPanel } from '@/models';
import IntusTable from '@/shared/elements/IntusTable/IntusTable';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { addSpacesToThousands } from '@/shared/helpers/format-data';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import React from 'react';
import { useParams } from 'react-router';
import { ColorDisplay } from '../PanelColorsTable/PanelColorsTable';
import {
  getOperationTypeData,
  getOperationTypeIcon,
} from '@/components/WindowCreator/helpers/config';
import {
  OperationType,
  WindowConfigurator,
} from '@/models/window-configurator.model';
import { formatAreaValue } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import useBuildingMetrics from '@/shared/hooks/useBuildingMetrics';

const WindowPropertiesTable = ({
  panelData,
}: {
  panelData: UserBuildingPanel;
}) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const configData = useFetchWindowConfigQuery().data!;
  const windowsData = useFetchWindowsQuery(id!).data!;
  const { getAreaUnit, getUnit } = useFrameProperties();
  const { countWindowsByPanel } = useBuildingMetrics();

  const columns = [
    {
      title: '',
      dataIndex: 'windowName',
      rowScope: 'row' as const, //   For some reason without it the engine throws an error Type 'string' is not assignable to type 'RowScopeType | undefined'
      key: `panel-id-${panelData.id}-window-name-column`,
      width: '63px',
    },
    {
      title: <div className="text-end">Count</div>,
      dataIndex: 'count',
      key: `panel-id-${panelData.id}-window-count-column`,
      width: '47px',
    },
    {
      title: <div className="text-start">Operation type</div>,
      dataIndex: 'operationType',
      key: `panel-id-${panelData.id}-window-operation-column`,
      width: '96px',
    },
    {
      title: <div className="text-start"> Glazing</div>,
      dataIndex: 'glazing',
      key: `panel-id-${panelData.id}-window-glazing-column`,
      width: '48px',
    },
    {
      title: (
        <div className="text-end">
          Glass length <br />
          <span className="text-[8px] text-dark-gray-60">({getUnit()})</span>
        </div>
      ),
      dataIndex: 'glassLength',
      key: `panel-id-${panelData.id}-window-glass-length-column`,
      width: '77px',
    },
    {
      title: (
        <div className="text-end">
          Glass width <br />
          <span className="text-[8px] text-dark-gray-60">({getUnit()})</span>
        </div>
      ),
      dataIndex: 'glassWidth',
      key: `panel-id-${panelData.id}-window-glass-width-column`,
      width: '77px',
    },
    {
      title: (
        <div className="leading-[14px] text-end">
          Glass Area <br />
          <span className="text-[8px] text-dark-gray-60">
            ({getAreaUnit()})
          </span>
        </div>
      ),
      dataIndex: 'glassArea',
      key: `panel-id-${panelData.id}-window-glass-area-column`,
      width: '59px',
    },
    {
      title: <div className="leading-[14px] text-start">Inside Color</div>,
      dataIndex: 'insideColor',
      key: `panel-id-${panelData.id}-window-inside-color-column`,
      width: '104px',
    },
    {
      title: <div className="leading-[14px] text-start"> Outside Color</div>,
      dataIndex: 'outsideColor',
      key: `panel-id-${panelData.id}-window-outside-color-column`,
      width: '104px',
    },
  ];

  const uniquePanelWindows = Array.from(
    new Set(panelData.panelWindows.map((window) => window.id))
  ).map((id) => panelData.panelWindows.find((window) => window.id === id)!);

  const dataSource = uniquePanelWindows.map((panelWindow) => {
    const windowData = windowsData.find((w) => w.id === panelWindow.id)!;
    const windowUsageAmount = countWindowsByPanel(panelWindow.id, panelData);

    return {
      key: `panel-id-${panelData.id}-window-${panelWindow.id}`,
      windowName: <div className="text-[8px]">{windowData.name}</div>,
      count: <div className="text-end">{windowUsageAmount}</div>,
      operationType: (
        <div className="flex flex-col justify-center items-start">
          {panelWindow.panelInnerFrames.map((innerFrame, index) => {
            return (
              <WindowOperationTypeDisplay
                key={index}
                operationTypeId={innerFrame.operationType}
                configData={configData}
              />
            );
          })}
        </div>
      ),
      glazing: <div className=" text-start">{panelWindow.glazing}</div>,
      glassLength: (
        <div className="flex flex-col justify-center items-center">
          {panelWindow.panelInnerFrames.map((innerFrame, index) => {
            return (
              <div
                key={index}
                className="h-[30px] flex items-center justify-end leading-5 w-full text-end"
              >
                {addSpacesToThousands(
                  isImperialUnits
                    ? convertMillimetersToFtInch(innerFrame.glassWidth)
                    : innerFrame.glassWidth.toString(),
                  isImperialUnits
                )}
              </div>
            );
          })}
        </div>
      ),
      glassWidth: (
        <div className="flex flex-col justify-center items-center">
          {panelWindow.panelInnerFrames.map((innerFrame, index) => {
            return (
              <div
                key={index}
                className="h-[30px] flex items-center justify-end leading-5 w-full text-end"
              >
                {addSpacesToThousands(
                  isImperialUnits
                    ? convertMillimetersToFtInch(innerFrame.glassHeight)
                    : innerFrame.glassHeight.toString(),
                  isImperialUnits
                )}
              </div>
            );
          })}
        </div>
      ),
      glassArea: (
        <div className="text-end">
          {formatAreaValue(Number(panelWindow.glassArea), isImperialUnits)}
        </div>
      ),
      insideColor: (
        <ColorDisplay
          colorName={windowData.innerColor}
          colors={configData.colors}
        />
      ),
      outsideColor: (
        <ColorDisplay
          colorName={windowData.outerColor}
          colors={configData.colors}
        />
      ),
    };
  });

  return (
    <IntusTable
      dataSource={dataSource}
      columns={columns}
      bordered={false}
      pagination={false}
    />
  );
};

export default WindowPropertiesTable;

const WindowOperationTypeDisplay = ({
  operationTypeId,
  configData,
}: {
  operationTypeId: OperationType;
  configData: WindowConfigurator;
}) => {
  return (
    <div className="flex gap-2 h-[30px] items-center justify-center text-[10px]">
      {getOperationTypeIcon(operationTypeId, '20')}
      <div className="flex flex-col">
        <div>
          {
            getOperationTypeData(operationTypeId, configData.operationTypes)
              ?.description
          }
        </div>

        <div className="leading-[14px] text-dark-gray-60">
          {
            getOperationTypeData(operationTypeId, configData.operationTypes)
              ?.swing
          }
        </div>
      </div>
    </div>
  );
};
