import React from 'react';

const SearchEmptyIcon = () => (
  <svg
    width="220"
    height="166"
    viewBox="0 0 220 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_18849_163373)">
      <circle cx="26" cy="23.5" r="8" fill="#E8F5E5" />
      <circle cx="47" cy="9.5" r="6" fill="#E8F5E5" />
      <circle cx="25" cy="141.5" r="10" fill="#E8F5E5" />
      <circle cx="210" cy="41.5" r="10" fill="#E8F5E5" />
      <circle cx="191" cy="14.5" r="7" fill="#E8F5E5" />
      <g opacity="0.8" clipPath="url(#clip1_18849_163373)">
        <path
          d="M175.459 30.7109H46.7478C45.0114 30.712 43.3463 31.4023 42.1184 32.6302C40.8906 33.858 40.2003 35.5231 40.1992 37.2595V155.354C40.2003 157.09 40.8906 158.755 42.1184 159.983C43.3463 161.211 45.0114 161.901 46.7478 161.902H175.459C177.196 161.901 178.861 161.211 180.089 159.983C181.317 158.755 182.008 157.09 182.009 155.354V37.2595C182.008 35.523 181.317 33.8578 180.089 32.6299C178.861 31.402 177.196 30.7118 175.459 30.7109Z"
          fill="#C6C6C6"
        />
        <path
          d="M79.0156 92.9844L100.189 114.158H79.0156V92.9844Z"
          fill="url(#paint0_linear_18849_163373)"
        />
        <path
          d="M182.009 87.903V39.1854L173.535 30.7109V87.9024H182.009V87.903Z"
          fill="#C6C6C6"
        />
        <path
          d="M191.934 112.826L182.804 158.518C182.348 160.802 179.583 162.5 176.32 162.5H45.8876C42.6248 162.5 39.8597 160.802 39.4033 158.518L27.0655 96.7776C26.509 93.9903 29.5682 91.5 33.5499 91.5H73.83C77.0928 91.5 79.8579 93.1977 80.3144 95.4815L81.9312 103.567C82.3877 105.851 85.1528 107.549 88.4156 107.549H185.451C189.431 107.549 192.491 110.039 191.934 112.826H191.934Z"
          fill="url(#paint1_linear_18849_163373)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.995 109.118C151.528 112.961 149.829 114.74 146.719 117.249C146.491 118.292 146.479 119.283 146.685 120.155C146.891 121.026 147.31 121.758 147.913 122.299L187.237 156.156C188.343 157.145 189.994 157.432 191.829 156.954C193.664 156.477 194.865 156.019 196.354 154.355C197.844 152.69 198.167 151.446 198.438 149.57C198.71 147.694 198.242 146.084 197.137 145.095L159.15 109.749C158.546 109.208 157.772 108.872 156.882 108.763C155.992 108.654 155.007 108.775 153.995 109.118Z"
        fill="url(#paint2_radial_18849_163373)"
      />
      <path
        d="M154.299 109.196C151.668 107.213 150.346 105.953 148.425 103.324C146.682 105.76 145.37 107.054 143.742 108.66C142.953 109.439 142.09 110.29 141.067 111.381C143.624 113.17 144.932 114.414 146.941 117.254C150.076 114.776 151.793 113.014 154.299 109.196Z"
        fill="url(#paint3_linear_18849_163373)"
      />
      <g
        style={{ mixBlendMode: 'lighten' }}
        filter="url(#filter0_b_18849_163373)"
      >
        <path
          d="M156.371 84.0299C155.069 96.9097 148.421 107.52 138.745 114.247C129.069 120.975 116.363 123.821 102.947 121.174C77.8285 116.22 59.0221 93.7208 58.8492 69.7368C58.8472 66.356 59.0843 64.3127 59.8265 60.0372C64.7406 37.0587 87.2674 22.7149 112.388 27.6698C139.221 32.9635 158.972 58.2714 156.371 84.0299Z"
          fill="url(#paint4_linear_18849_163373)"
        />
        <path
          d="M155.86 83.9756C154.572 96.7207 147.997 107.207 138.438 113.853C128.878 120.499 116.319 123.317 103.044 120.698L102.947 121.174L103.044 120.698C78.1735 115.792 59.5343 93.5015 59.3622 69.7431C59.3603 66.4016 59.5928 64.3872 60.3311 60.1329C65.1934 37.4193 87.451 23.2465 112.291 28.1462C138.865 33.3887 158.436 58.4666 155.86 83.9756Z"
          stroke="#999999"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.962 115.876C128.154 122.695 115.284 125.572 101.717 122.896C76.3355 117.889 57.3559 95.1739 57.1813 70.9536L57.1813 70.9503C57.1793 67.5308 57.421 65.4569 58.1659 61.1658L58.1676 61.1562L58.1696 61.1468C59.3396 55.6756 61.4977 50.6844 64.4478 46.3157L65.4131 46.827C63.6083 50.1872 62.2462 53.8592 61.4049 57.7891C60.6669 62.0413 60.4347 64.0537 60.4365 67.3919C60.6081 91.1391 79.2413 113.421 104.097 118.324C117.361 120.941 129.904 118.125 139.448 111.489C143.588 108.611 147.167 105.012 149.997 100.821L150.963 101.332C147.819 107.187 143.356 112.126 137.962 115.876Z"
        fill="url(#paint5_linear_18849_163373)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.956 81.7365C157.21 89.1117 154.73 95.7585 150.935 101.376L149.97 100.865C152.477 96.1959 154.126 90.9241 154.706 85.1926C157.279 59.6981 137.716 34.6298 111.158 29.3902C92.1732 25.6457 74.7185 33.0508 65.3856 46.8713L64.4203 46.36C73.2714 29.8809 92.5235 20.6737 113.537 24.8185C140.646 30.1666 160.583 55.7139 157.956 81.7365Z"
        fill="url(#paint6_linear_18849_163373)"
      />
      <path
        d="M103.978 118.836C78.8593 113.882 60.0529 91.3827 59.88 67.3988C59.878 64.0179 60.1151 61.9746 60.8573 57.6992C61.7083 53.7199 63.0875 49.9995 64.9169 46.5935C73.6513 30.3314 92.6481 21.2356 113.418 25.3325C140.252 30.6262 160.002 55.9341 157.401 81.6926C156.664 88.9872 154.212 95.5529 150.466 101.098C147.598 105.344 143.972 108.992 139.776 111.909C130.1 118.637 117.394 121.483 103.978 118.836Z"
        stroke="url(#paint7_linear_18849_163373)"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_18849_163373"
        x="57.8496"
        y="25.6953"
        width="99.752"
        height="97.4609"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_18849_163373"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_18849_163373"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_18849_163373"
        x1="93.0048"
        y1="117.556"
        x2="67.4115"
        y2="91.9633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C2CECE" stopOpacity="0" />
        <stop offset="0.179" stopColor="#AFBCBC" stopOpacity="0.179" />
        <stop offset="1" stopColor="#5B6A6A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_18849_163373"
        x1="109.5"
        y1="91.5"
        x2="109.5"
        y2="162.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EEF0F4" />
        <stop offset="0.927" stopColor="#E4E4E4" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_18849_163373"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(148.561 111.007) rotate(42.1549) scale(66.3535 119.948)"
      >
        <stop stopColor="#A8AAAD" />
        <stop offset="0.114148" stopColor="#DCE0E2" />
        <stop offset="0.90874" stopColor="#E4E6E8" />
        <stop offset="1" stopColor="#D4D9DF" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_18849_163373"
        x1="144.5"
        y1="107.003"
        x2="152.5"
        y2="114.503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7C7C7" />
        <stop offset="1" stopColor="#D9D9D9" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_18849_163373"
        x1="148.541"
        y1="36.0209"
        x2="87.1884"
        y2="116.63"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3F7FF" stopOpacity="0" />
        <stop offset="0.673774" stopColor="#ECF2FF" stopOpacity="0.66" />
        <stop offset="1" stopColor="#D5DEEF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_18849_163373"
        x1="97.6193"
        y1="115.342"
        x2="141.063"
        y2="3.36085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E1E1E1" />
        <stop offset="0.25" stopColor="#C2BDBD" />
        <stop offset="1" stopColor="#A3A3A3" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_18849_163373"
        x1="81.2159"
        y1="109.92"
        x2="126.099"
        y2="30.5738"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.385" stopColor="white" />
        <stop offset="0.846294" stopColor="#A4A4A4" />
        <stop offset="1" stopColor="#B7B7B7" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_18849_163373"
        x1="104.403"
        y1="106.554"
        x2="89.9224"
        y2="14.0446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1F1F1" />
        <stop offset="1" stopColor="#C3C3C3" />
      </linearGradient>
      <clipPath id="clip0_18849_163373">
        <rect
          width="220"
          height="165"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_18849_163373">
        <rect
          width="165"
          height="135"
          fill="white"
          transform="translate(27 30.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SearchEmptyIcon;
