import React from 'react';
import { useSelectionHandlers } from '@/shared/components/CanvasActionHandlers/useSelectionHandlers';
import { CanvasActionsModes } from '@/models';

const CanvasFacadeDesignerActionHandlers = () => {
  const { facadeContour } = useSelectionHandlers(
    CanvasActionsModes.facadeDesigner
  );

  return <>{facadeContour && <primitive object={facadeContour} />}</>;
};

export default CanvasFacadeDesignerActionHandlers;
