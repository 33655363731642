import React, { useEffect, useRef, useState } from 'react';
import { Stats } from '@react-three/drei';
import { ProjectStructure } from '@/models';
import ProjectCanvasMap from './ProjectCanvasMap';
import CameraControl from './CameraControls';
import CanvasActionHandlers from '@/shared/components/CanvasActionHandlers/CanvasActionHandlers';
import UserBuilding from '@/routes/dashboard/projects/project/UserBuilding';
import ProjectEnvironment from './Environment/ProjectEnvironment';
import { useThree } from '@react-three/fiber';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { centerCamera } from '@/shared/helpers/camera';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { findObjectByGuid } from '@/shared/helpers/canvas';
import { getBuildingsCoordinates } from '@/shared/helpers';
import { flattenDeep } from 'lodash';
import { AmbientLight, Box3, DirectionalLight } from 'three';
import CanvasInternalElements from './CanvasInternalElements/CanvasInternalElements';
import CameraSnapshot from './CameraSnapshot';
import ProjectCover from './ProjectCover';
import { HIDDEN_LAYER } from '@/models/camera.model';
import { useAppDispatch } from '@/store/hooks';
import { setIsAppFullyLoaded } from '@/store/slices/projectSlice';

const ProjectCanvas = ({
  map,
  project,
}: {
  map: string;
  project: ProjectStructure;
}) => {
  const { scene, controls, camera } = useThree();
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;
  const [controlsIsReady, setControlsIsReady] = useState(false);
  const lightsRef = useRef<(AmbientLight | DirectionalLight | null)[]>([]);
  const dispatch = useAppDispatch();

  const { isIsolateModeEnabled } = useIsolationHandlers();
  const updateCameraBoundingBox = () => {
    const mapObject = findObjectByGuid(scene, 'map_guid');

    const buildingsOccupiedPoints = flattenDeep(
      getBuildingsCoordinates(userBuildings!)
    );

    const targetedBBox = buildingsOccupiedPoints.length
      ? new Box3().setFromArray(buildingsOccupiedPoints)
      : new Box3().setFromObject(mapObject!);

    if (controlsIsReady) {
      centerCamera({
        boundingBox: targetedBBox,
        camera,
        controls,
        onCameraAnimationEnd: () => dispatch(setIsAppFullyLoaded(true)),
      });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setIsAppFullyLoaded(false));
    };
  }, []);

  useEffect(() => {
    if (controls !== null && !controlsIsReady) {
      setControlsIsReady(true);
    }
  }, [controls]);

  useEffect(() => {
    updateCameraBoundingBox();

    lightsRef.current.forEach((light) => {
      if (light) {
        light.layers.enable(HIDDEN_LAYER);
      }
    });
  }, [controlsIsReady]);

  return (
    <>
      <ambientLight
        intensity={1.3}
        color={0x000000}
        ref={(el) => lightsRef.current.push(el)}
      />
      <directionalLight
        position={[-20, 10, -20]}
        intensity={6}
        ref={(el) => lightsRef.current.push(el)}
      />
      <directionalLight
        position={[20, 10, 20]}
        intensity={6}
        ref={(el) => lightsRef.current.push(el)}
      />
      <ProjectCanvasMap map={map} project={project} />
      {!isIsolateModeEnabled && <ProjectEnvironment project={project} />}
      <CanvasInternalElements />

      {!!userBuildings?.length && (
        <>
          <CameraSnapshot blocks={userBuildings[0].blocks} />
          <ProjectCover project={project} />
        </>
      )}
      <UserBuilding />
      <CameraControl />
      <CanvasActionHandlers isProjectLocked={project.locked} />
      <Stats className="!absolute !right-[230px] !bottom-6 flex flex-col-reverse !top-auto  !left-auto" />
    </>
  );
};
export default ProjectCanvas;
