import React from 'react';
import FacadeDesignerWallPanelsView from '@/components/FacadeDesigner/elements/FacadeDesignerWallPanelsView';
import FacadeDesignerGridLines from '@/components/FacadeDesigner/elements/FacadeDesignerGridLines';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import FacadeDesignerWindows from './FacadeDesignerWindows';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes } from '@/models/shared.model';
import { WindowPlacementDataForFD } from '@/components/FacadeDesigner/models';
import { getSelectedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';

interface FacadeDesignerConstructionElementsProps {
  scale: number;
  wallData: WallSearchResults;
  wallHeight: number;
  wallWidth: number;
  facadeDesignerMode: FacadeDesignerModes;
  reportView?: boolean;
  placedWindows: WindowPlacementDataForFD[];
  wallOffset: { x: number; y: number };
}

const FacadeDesignerConstructionElements = ({
  scale,
  wallData,
  wallHeight,
  wallWidth,
  facadeDesignerMode,
  reportView,
  placedWindows,
  wallOffset,
}: FacadeDesignerConstructionElementsProps) => {
  const { id } = useParams();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );
  const isWindowPlacementProcessing = !!selectedWindowFromLibrary;
  return (
    <>
      <FacadeDesignerWindows
        placedWindows={placedWindows}
        scale={scale}
        windowsData={windowsData}
        unitSystem={unitSystem}
        wallData={wallData}
        isWindowPlacementProcessing={isWindowPlacementProcessing}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        facadeDesignerMode={facadeDesignerMode}
      />
      <FacadeDesignerWallPanelsView
        wallData={wallData}
        scale={scale}
        wallHeight={wallHeight}
        unitSystem={unitSystem}
        reportView={reportView}
      />

      <FacadeDesignerGridLines
        scale={scale}
        unitSystem={unitSystem}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        wallData={wallData}
        xWallOffset={wallOffset.x}
        reportView={reportView}
      />
    </>
  );
};

export default FacadeDesignerConstructionElements;
