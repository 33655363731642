import { Vector3 } from 'three';

interface Size {
  width: number;
  height: number;
}

export const calculationMatrix2D =
  (epsilon_x: number, epsilon_y: number) =>
  (
    el: THREE.Object3D<THREE.Object3DEventMap>,
    camera: THREE.Camera,
    size: Size
  ) => {
    const objectPos = new Vector3().setFromMatrixPosition(el.matrixWorld);

    objectPos.project(camera);

    const widthHalf = size.width / 2;
    const heightHalf = size.height / 2;

    return [
      objectPos.x * widthHalf + widthHalf + epsilon_x,
      -(objectPos.y * heightHalf) + heightHalf + epsilon_y,
    ];
  };
