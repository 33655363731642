import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { Orientation, PanelPlacementData, UserBuildingPanel } from '@/models';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { Group, Rect, Text } from 'react-konva';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getDragNode,
  getIsPanelHovered,
  getIsPanelSelected,
  getMeasurementActiveWall,
  isItemSelectedOnWall,
  setHoveredPlacedPanel,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useFacadeDesignerSelection } from '@/components/FacadeDesigner/hooks';
import { KonvaEventObject } from 'konva/lib/Node';
import { PANEL_MULLION_WIDTH } from '../constants';
import { REPORT_VIEW_SCALE } from '@/components/WindowCreator/constants';
import PanelWindowView from './PanelWindowView';

interface PanelViewProps {
  panelPlacementData?: PanelPlacementData;
  panelInfo: UserBuildingPanel;
  scale: number;
  wallGUID?: string;
  reportView?: boolean;
  panelWidth: number;
  offsetX?: number;
  showPanelCellNumbers?: boolean;
}

const SELECTION_AREA_OFFSET = 15;

const PanelView: React.FC<PanelViewProps> = ({
  panelPlacementData,
  panelInfo,
  offsetX,
  panelWidth,
  scale,
  wallGUID,
  reportView,
  showPanelCellNumbers,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const windowsInLibrary = useFetchWindowsQuery(id!).data!;
  const configColors = useFetchWindowConfigQuery().data!.colors;
  const panelsConfig = useFetchWindowConfigQuery().data!.panel;
  const dragNode = useAppSelector(getDragNode);
  const isMeasurementActiveWall = !!useAppSelector(getMeasurementActiveWall);
  const isSelectedWall = useAppSelector(isItemSelectedOnWall(wallGUID!));

  const isHovered = panelPlacementData
    ? useAppSelector(getIsPanelHovered(panelPlacementData.guid))
    : false;
  const isSelected = panelPlacementData
    ? useAppSelector(getIsPanelSelected(panelPlacementData.guid))
    : false;

  const { handleSelectPanel, isSelectionMode } = useFacadeDesignerSelection();

  const isDragNodeActive = !!dragNode;
  const isAbleToHover = isSelectionMode && !isDragNodeActive;
  const isHidden = isSelectedWall && (dragNode || isMeasurementActiveWall);

  const panelColor = useMemo(() => {
    if (panelInfo?.fillerMaterial === 'Glass') return 'rgba(204,213,213,1)';
    return getHexByName(configColors, panelInfo?.frameOutsideColor || '');
  }, [configColors, panelInfo]);

  const handleSelect = (event: KonvaEventObject<PointerEvent>) => {
    if (!panelPlacementData || !wallGUID || !isAbleToHover) return;
    handleSelectPanel({
      panelData: {
        guid: panelPlacementData.guid,
        wallGUID,
        isInitialCorner: !!panelPlacementData.isInitialCorner,
      },
      multiSelect: event.evt.shiftKey,
    });
  };

  if (!panelInfo) return null;

  const panelCenterX = panelWidth / 2;
  const panelCenterY = panelInfo.height / 2;

  const renderMullions = () => {
    return panelInfo.mullions.map((mullion) => {
      const { x, y, length, orientation } = mullion;

      const rectWidth =
        orientation === Orientation.HORIZONTAL ? length : PANEL_MULLION_WIDTH;
      const rectHeight =
        orientation === Orientation.HORIZONTAL ? PANEL_MULLION_WIDTH : length;

      const rectX = panelCenterX + x - rectWidth / 2;
      const rectY = panelCenterY - y - rectHeight / 2;

      return (
        <Rect
          key={`panel_mullion__pos:${mullion.x} ${mullion.y}`}
          x={rectX}
          y={rectY}
          width={rectWidth}
          height={rectHeight}
          fill={panelColor}
          stroke="#8D8B8F"
          strokeWidth={0.5}
          opacity={isHidden ? 0 : 1}
          strokeScaleEnabled={false}
        />
      );
    });
  };

  const renderFrame = () => {
    const frames = [
      {
        key: 'top-frame',
        x: 0,
        y: 0,
        width: panelWidth,
        height: panelsConfig.topWidth,
      },
      {
        key: 'bottom-frame',
        x: 0,
        y: panelInfo.height - panelsConfig.bottomWidth,
        width: panelWidth,
        height: panelsConfig.bottomWidth,
      },
      {
        key: 'left-frame',
        x: 0,
        y: panelsConfig.topWidth,
        width: panelsConfig.sideWidth,
        height:
          panelInfo.height - panelsConfig.topWidth - panelsConfig.bottomWidth,
      },
      {
        key: 'right-frame',
        x: panelWidth - panelsConfig.sideWidth,
        y: panelsConfig.topWidth,
        width: panelsConfig.sideWidth,
        height:
          panelInfo.height - panelsConfig.topWidth - panelsConfig.bottomWidth,
      },
    ];

    return frames.map(({ key, x, y, width, height }) => (
      <Rect
        key={key}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={panelColor}
        stroke="#8D8B8F"
        strokeWidth={0.5}
        opacity={isHidden ? 0 : 1}
        strokeScaleEnabled={false}
      />
    ));
  };

  const renderInnerFramesNumbers = () => {
    return panelInfo.innerFrames.map((frame, index) => {
      const textX = panelCenterX + frame.x - frame.width / 2;
      const textY = panelCenterY - frame.y - frame.height / 2;

      return (
        <Text
          key={`frame-number-${index}`}
          text={frame.number}
          x={textX}
          y={textY}
          width={frame.width}
          height={frame.height}
          fontSize={4 / scale}
          fill="#8D8B8F"
          align="center"
          verticalAlign="middle"
          wrap="none"
        />
      );
    });
  };

  return (
    <Group onPointerDown={handleSelect} x={offsetX} listening={isAbleToHover}>
      {/* We use this rect in order to add padding for snapshot, in some cases toImage method crop borders */}
      <Rect
        width={panelWidth}
        height={panelInfo.height}
        fill={panelColor}
        opacity={isHidden ? 0 : 1}
        stroke={reportView ? '#B3B2B4' : undefined}
        strokeWidth={0.5 / scale}
        onPointerEnter={() =>
          panelPlacementData
            ? dispatch(setHoveredPlacedPanel(panelPlacementData.guid))
            : undefined
        }
      />

      {(isHovered || isSelected) && (
        <>
          <Rect
            x={SELECTION_AREA_OFFSET / 2}
            y={SELECTION_AREA_OFFSET / 2}
            width={panelWidth - SELECTION_AREA_OFFSET}
            height={panelInfo.height - SELECTION_AREA_OFFSET}
            fill={'#65BD51'}
            opacity={isSelected ? 0.2 : 0}
            onPointerLeave={() => dispatch(setHoveredPlacedPanel(null))}
          />
          <Rect
            x={SELECTION_AREA_OFFSET / 2}
            y={SELECTION_AREA_OFFSET / 2}
            width={panelWidth - SELECTION_AREA_OFFSET}
            height={panelInfo.height - SELECTION_AREA_OFFSET}
            stroke={'#65BD51'}
            strokeWidth={1.2 / scale}
            onPointerLeave={() => dispatch(setHoveredPlacedPanel(null))}
          />
        </>
      )}
      {panelInfo.panelWindows.map((panelWindow, i) => {
        const windowData = windowsInLibrary.find(
          (window) => window.id === panelWindow.id
        );
        if (!windowData) return null;

        return (
          <PanelWindowView
            key={`panel-window__${panelWindow.id}-${i}`}
            offsetX={panelWindow.offsetFromLeftEdge}
            offsetY={panelInfo.height - windowData.distanceToFloor}
            scale={reportView ? REPORT_VIEW_SCALE : scale}
            windowInnerFrames={panelInfo.innerFrames.filter(
              (frame) => frame.windowId === panelWindow.id
            )}
            panelCenterX={panelCenterX}
            panelCenterY={panelCenterY}
          />
        );
      })}
      {renderMullions()}
      {renderFrame()}
      {showPanelCellNumbers && renderInnerFramesNumbers()}
    </Group>
  );
};

export default PanelView;
