export const BUILDING_WIDTH_IN_METERS = 20;
export const FLOORS_INITIAL_COUNT = 2;
export const INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS = 3048;

export enum RENDER_ORDERS {
  DOT = 10,
  DOT_BACKGROUND = 15,
  WINDOW_MODEL = 16,
  EDIT_NODE_LINE = 20,
}

export const POLYGON_OFFSET_FACTOR_LEVELS = {
  LOW: -1,
  MEDIUM: -3,
  HIGH: -5,
};

export const WINDOW_CREATOR_STAGE_ID = 'konva__window-creator__stage';
export const WINDOW_CREATOR_VIEW = '2d-konva__window-creator__window-view';
export const FACADE_VIEW = '2d-konva__facade-view';

export const LEFT_PANEL_DEFAULT_WIDTH = 210;

export const MULTIPLE_VALUES_SYMBOL = '-';
