import React, { useMemo } from 'react';

import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { FlatVector3, GridLineData } from '@/models';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  getExtendedVector,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three-stdlib';
import {
  BUILDING_GRID_LINE_COLOR,
  C_FatLineBorderMaterial,
} from '@/shared/materials';

interface ViewerGridLineProps {
  scale: number;
  placementData: GridLineData;
  wallData: FlatVector3[];
}

const ViewerGridLine: React.FC<ViewerGridLineProps> = ({
  scale,
  placementData,
  wallData,
}) => {
  const leftSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallData[1]),
    [wallData]
  );
  const rightSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallData[0]),
    [wallData]
  );
  const leftSideTop = useMemo(
    () => convertFlatVector3ToVector(wallData[2]),
    [wallData]
  );
  const rightSideTop = useMemo(
    () => convertFlatVector3ToVector(wallData[3]),
    [wallData]
  );

  const generateGridLine = (): Line2 => {
    const bottomPosition = getExtendedVector(
      leftSideBottom,
      rightSideBottom,
      convertMillimetersToMeters(placementData.offsetFromLeftEdge) * scale
    );
    const topPosition = getExtendedVector(
      leftSideTop,
      rightSideTop,
      convertMillimetersToMeters(placementData.offsetFromLeftEdge) * scale
    );
    const geometry = new LineGeometry();
    geometry.setPositions([...getXYZ(bottomPosition), ...getXYZ(topPosition)]);

    const material = C_FatLineBorderMaterial.clone();
    material.color = BUILDING_GRID_LINE_COLOR;

    const line = new Line2(geometry, material);
    line.computeLineDistances();
    return line;
  };

  return <primitive object={generateGridLine()} />;
};

export default ViewerGridLine;
