import {
  OperationType,
  OperationTypeDescription,
  WindowFrame,
} from '@/models/window-configurator.model';

export const getWindowWidth = (window: WindowFrame): number =>
  window.points[1][0] - window.points[0][0];

export const getWindowHeight = (window: WindowFrame): number =>
  window.points[2][1] - window.points[0][1];

export const getOperationTypeWidth = (
  config: OperationTypeDescription[],
  operationType: OperationType
) => {
  return config.find((op) => op.name === operationType)!.innerFrameWidth;
};
