import React, { useEffect, useMemo, useRef, useState } from 'react';
import FacadeDesignerGridPlacement from '@/components/FacadeDesigner/elements/FacadeDesignerGridPlacement';
import { Group } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';
import PlacedWindowStates from '@/components/FacadeDesigner/elements/PlacedWindowStates';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models';
import {
  FacadeDesignerPlacementType,
  getDragNode,
  getHoveredWall,
  getMeasurementActiveWall,
  getPlacementErrors,
  getSelectedPlacedWindows,
  getSelectedWindowFromLibrary,
  setGridPlacementAbsoluteOffset,
  setHoveredWall,
  setMeasurementActiveWall,
  setPlacementError,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import { FacadeDesignerModes, PointerPosition } from '@/models/shared.model';
import {
  useCustomElementPlacement,
  useFDElementValidation,
} from '@/components/FacadeDesigner/hooks';
import { round } from 'mathjs';
import { get2DDistance } from '@/shared/helpers/konva';
import { limitValue } from '@/shared/helpers/format-data';
import { KonvaEventObject } from 'konva/lib/Node';
import { isLeftClick } from '@/shared/helpers';
import { WindowPlacementData } from '@/models';
import { uuidv7 } from 'uuidv7';
import { WindowPlacementDataForFD } from '@/components/FacadeDesigner/models';
import WallView from '@/components/FacadeDesigner/elements/WallView';

interface FacadeDesignerElementPlacementProps {
  wallData: WallSearchResults;
  wallWidth: number;
  wallHeight: number;
  wallOffset: { x: number; y: number };
  scale: number;
  facadeDesignerMode: FacadeDesignerModes;
  onAddWindow: (data: WindowPlacementData[]) => void;
  onGridPlacement: () => void;
  handleWindowDragEnd?: (newOffset: number) => void;
  placedWindows: WindowPlacementDataForFD[];
  reportView?: boolean;
}

const FacadeDesignerElementPlacement = ({
  wallData,
  wallWidth,
  wallHeight,
  wallOffset,
  scale,
  facadeDesignerMode,
  onAddWindow,
  onGridPlacement,
  handleWindowDragEnd,
  placedWindows,
  reportView,
}: FacadeDesignerElementPlacementProps) => {
  const dragNode = useAppSelector(getDragNode);
  const pointerPositionRef = useRef<PointerPosition | null>(null);

  const { id } = useParams();
  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const placementErrors = useAppSelector(getPlacementErrors);
  const hoveredWall = useAppSelector(getHoveredWall);
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const selectedWindows = useAppSelector(getSelectedPlacedWindows);
  const activeWall = useAppSelector(getMeasurementActiveWall);
  const dispatch = useAppDispatch();
  const [placedWindowOffset, setPlacedWindowOffset] = useState<number>(-1);
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );

  useEffect(() => {
    setMeasurementPoints([]);
  }, [facadeDesignerMode]);

  useEffect(() => {
    setMeasurementPoints([]);
  }, [dragNode]);

  const handleWindowStartDragPosition = () => {
    if (!selectedWindowFromLibrary || !pointerPositionRef.current) return;
    const windowWidth = get2DDistance(
      selectedWindowFromLibrary.points[0],
      selectedWindowFromLibrary.points[1]
    );

    const roundedPointerPosition = {
      x: round(pointerPositionRef.current.x, 0),
      y: round(pointerPositionRef.current.y, 0),
    };
    const maxXPosition = limitValue(wallWidth - windowWidth, 0, wallWidth);
    const xPosition = roundedPointerPosition.x - windowWidth / 2;

    const offset = limitValue(xPosition, 0, maxXPosition);
    setPlacedWindowOffset(offset);
  };

  useEffect(() => {
    dragNode === FacadeDesignerPlacementType.Window &&
      handleWindowStartDragPosition();
  }, [dragNode, selectedWindowFromLibrary]);

  const hasAnyPlacementError = useMemo(
    () => Object.values(placementErrors).some((v) => v.state),
    [placementErrors]
  );

  const { validateWindowPlacement } = useFDElementValidation(
    wallData,
    wallWidth,
    placedWindowOffset,
    selectedWindows
  );

  useEffect(() => {
    selectedWindowFromLibrary &&
      placedWindowOffset >= 0 &&
      [activeWall, hoveredWall].includes(wallData.guid) &&
      validateWindowPlacement(selectedWindowFromLibrary);
  }, [placedWindowOffset, selectedWindowFromLibrary, activeWall, hoveredWall]);

  const isGridPlacementMode =
    facadeDesignerMode === FacadeDesignerModes.GridLinePlacement ||
    dragNode === FacadeDesignerPlacementType.GridLine;

  const handleActiveMeasurementStatus = (isActive: boolean) => {
    dispatch(setMeasurementActiveWall(isActive ? wallData.guid : null));
  };

  const handleMeasurementEscape = () => {
    dispatch(setMeasurementActiveWall(null));
  };

  const isWindowPlacementProcessing = !!selectedWindowFromLibrary;

  const { handleGridMovePlacement, handleWindowMovePlacement } =
    useCustomElementPlacement({
      wallData,
      yPosition: wallHeight / 2,
    });

  useEffect(() => {
    !hoveredWall && !activeWall && resetMeasurementTool();

    hoveredWall === wallData.guid &&
      !activeWall &&
      pointerPositionRef.current &&
      updateMeasurementTool(pointerPositionRef.current);
  }, [activeWall, hoveredWall, pointerPositionRef.current]);

  const updateMeasurementTool = (pointerPosition: PointerPosition) => {
    const roundedPointerPosition = {
      x: round(pointerPosition.x, 0),
      y: round(pointerPosition.y, 0),
    };
    if (isWindowPlacementProcessing) {
      const windowWidth = get2DDistance(
        selectedWindowFromLibrary.points[0],
        selectedWindowFromLibrary.points[1]
      );

      const maxXPosition = limitValue(wallWidth - windowWidth, 0, wallWidth);
      const xPosition = roundedPointerPosition.x - windowWidth / 2;

      const offset = limitValue(xPosition, 0, maxXPosition);
      setPlacedWindowOffset(offset);
      setMeasurementPoints(
        handleWindowMovePlacement(offset, selectedWindowFromLibrary)
      );
    } else if (isGridPlacementMode) {
      const offset = round(wallOffset.x + roundedPointerPosition.x, 0);
      dispatch(setGridPlacementAbsoluteOffset(offset));
      setMeasurementPoints(handleGridMovePlacement(roundedPointerPosition.x));
    }
  };

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    setMeasurementPoints(points);

    if (isGridPlacementMode) {
      dispatch(
        setGridPlacementAbsoluteOffset(round(points[1][0][0] + wallOffset.x, 2))
      );
      setMeasurementPoints(handleGridMovePlacement(points[1][0][0]));
    } else if (isWindowPlacementProcessing) {
      setPlacedWindowOffset(points[0][1][0]);
      setMeasurementPoints(
        handleWindowMovePlacement(points[0][1][0], selectedWindowFromLibrary)
      );
    }
  };

  const resetMeasurementTool = () => {
    setPlacedWindowOffset(-1);
    dispatch(setMeasurementActiveWall(null));
    dispatch(setGridPlacementAbsoluteOffset(0));
    pointerPositionRef.current = null;
    setMeasurementPoints([]);
  };

  const handleMeasurementSubmit = () => {
    if (isGridPlacementMode) {
      onGridPlacement();
    } else if (isWindowPlacementProcessing) {
      onWindowPlacement();
    }
    resetMeasurementTool();
  };

  const handlePointerUp = (e: KonvaEventObject<PointerEvent>) => {
    if (hasAnyPlacementError) return;
    if (dragNode === FacadeDesignerPlacementType.Window) {
      e.cancelBubble = true;
      handleWindowDragEnd && handleWindowDragEnd(placedWindowOffset);
    }
  };

  // Should be pointer down event, to go faster than cancelling active measurement
  const handlePointerDown = (event: KonvaEventObject<PointerEvent>) => {
    if (activeWall || !isLeftClick(event.evt) || hasAnyPlacementError) return;
    if (isWindowPlacementProcessing && measurementPoints?.length) {
      onWindowPlacement();
    } else if (isGridPlacementMode && measurementPoints?.length) {
      onGridPlacement();
    }
    setMeasurementPoints([]);
  };

  const handleMouseLeave = () => {
    !activeWall && dispatch(setGridPlacementAbsoluteOffset(0));
    !activeWall &&
      dispatch(setPlacementError({ key: wallData.guid, state: false }));
    dispatch(setHoveredWall(null));
  };

  const handleMouseEnter = () => {
    dispatch(setHoveredWall(wallData.guid));
  };

  const onWindowPlacement = () => {
    if (!selectedWindowFromLibrary) return;
    const placements: WindowPlacementData[] = [
      ...placedWindows,
      {
        windowId: selectedWindowFromLibrary.id,
        offsetFromLeftEdge: placedWindowOffset,
        guid: uuidv7(),
      },
    ];
    onAddWindow(placements);
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const pointerPosition = event.currentTarget?.getRelativePointerPosition();

    if (!pointerPosition) return;
    pointerPositionRef.current = pointerPosition;

    if (activeWall) return;
    updateMeasurementTool(pointerPosition);
  };

  return (
    <Group
      onPointerDown={handlePointerDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onPointerUp={handlePointerUp}
    >
      <WallView
        wallWidth={wallWidth}
        wallHeight={wallHeight}
        scale={scale}
        reportView={reportView}
        isContour={true}
      />
      {isGridPlacementMode && (
        <FacadeDesignerGridPlacement
          scale={scale}
          wallData={wallData}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          xWallOffset={wallOffset.x}
          unitSystem={unitSystem}
        />
      )}

      {isWindowPlacementProcessing &&
        (activeWall
          ? activeWall === wallData.guid
          : hoveredWall === wallData.guid) && (
          <Group opacity={0.75} listening={false}>
            <WindowView
              data={selectedWindowFromLibrary}
              scale={scale}
              units={unitSystem}
              offsetX={placedWindowOffset}
              offsetY={wallHeight - selectedWindowFromLibrary.distanceToFloor}
              viewOnly
            />
            <PlacedWindowStates
              data={selectedWindowFromLibrary}
              isHovered={false}
              isSelected={false}
              hasError={hasAnyPlacementError}
              offsetX={placedWindowOffset}
              offsetY={wallHeight - selectedWindowFromLibrary.distanceToFloor}
            />
          </Group>
        )}

      {!!measurementPoints?.length &&
        !dragNode &&
        (activeWall
          ? activeWall === wallData.guid
          : hoveredWall === wallData.guid) && (
          <MultiMeasurementLine
            multiPoints={measurementPoints}
            scale={scale}
            units={unitSystem}
            type={
              isGridPlacementMode
                ? MeasurementElementType.GridDistance
                : MeasurementElementType.WindowDistance
            }
            onActiveStatusChange={handleActiveMeasurementStatus}
            onEscape={handleMeasurementEscape}
            onChange={handleMeasurementChange}
            onSubmit={handleMeasurementSubmit}
            customErrorMessage={
              Object.values(placementErrors).find((err) => err.state)?.message
            }
          />
        )}
    </Group>
  );
};

export default FacadeDesignerElementPlacement;
