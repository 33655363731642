import React, { useEffect, useRef, useState } from 'react';
import { CustomModalProps } from '@/models/shared.model';
import { REPORT_BACKGROUND_COLOR, ReportStatus } from '@/models/report';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  useFetchProjectQuery,
  useLazyFetchProjectQuery,
  useSaveReportMutation,
  useUpdateProjectMutation,
} from '@/store/apis/projectsApi';
import {
  getStatusReport,
  setErrorMessageReport,
  setStatusReport,
} from '@/store/slices/reportSlice';
import { useParams } from 'react-router';
import Report from '@/components/Report/Report';
import { ProjectStructure } from '@/models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import WrapperModalContent from './report/WrapperModalContent';
import { useAIGeneration } from '@/shared/hooks/useAIGeneration';
import { Color } from 'three';
import { Side } from '@/models/camera.model';
import moment from 'moment';

interface ProjectModalReportProps extends CustomModalProps {
  data: ProjectStructure;
}

const ProjectModalReport: React.FC<ProjectModalReportProps> = ({
  data,
  isOpen,
  setIsOpen,
}) => {
  const { id } = useParams();

  const { getProcessData, checkProgress } = useAIGeneration();

  if (!id) return <></>;

  const [saveReport] = useSaveReportMutation();
  const [fetchProject] = useLazyFetchProjectQuery();
  const [updateProject] = useUpdateProjectMutation();
  const [generationTime, setGenerationTime] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [percent, setPercent] = useState(0);
  const reportRef = useRef<HTMLDivElement>(null!);
  const statusReport = useAppSelector((state) => getStatusReport(state, id));
  const [generatedImages, setGeneratedImages] = useState<string[]>([]);
  const projectData = useFetchProjectQuery(id!).data!;
  const { report } = data;
  const isReportAvailable = [
    ReportStatus.PROCESSING_DATA_COLLECTION,
    ReportStatus.PROCESSING_CREATE_REPORT,
  ].includes(statusReport);

  const generateReport = async () => {
    let taskIds = [];

    if (report.imageProcessIds?.length) {
      taskIds = report.imageProcessIds!;
    } else {
      taskIds = await getProcessData(
        [
          {
            side: Side.AI,
            targetGUID: data.buildings![0].guid,
            background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
          },
        ],
        [
          'public building, city centers, squares, urban landscape, cars, people, streets, reality, day time, summer',
          'public building, city centers, squares, urban landscape, cars, people, streets, reality, night, summer',
          'public building, city centers, squares, urban landscape, cars, people, streets, reality, day time, winter',
          'public building, city centers, squares, urban landscape, cars, people, streets, reality, night, winter',
        ]
      ).then((data) =>
        data.map((process) => {
          if (!process.success) throw new Error(process.error_message);

          return process.data.task_id;
        })
      );
      await updateProject({
        id: Number(id),
        report: {
          ...report,
          imageProcessIds: taskIds,
        },
      }).unwrap();
    }

    if (!taskIds.length) throw new Error('No ID generated for AI cover');

    const images = await Promise.all(
      taskIds.map(async (taskId) => await checkProgress(taskId))
    );

    setGeneratedImages(images);

    await Promise.all(
      images.map(async (imgSrc) => {
        const img = new Image();
        img.src = imgSrc;
        await img.decode();
      })
    );
  };

  const handleSaveReport = async (blob: Blob, generationTime: string) => {
    const formData = new FormData();
    const date = moment().format('MM DD YYYY');
    const fileName = `Web Designer Report - ${projectData.name} - ${date}.pdf`;
    formData.append('file', blob, fileName);
    formData.append('dateTime', generationTime);

    await saveReport({
      id: Number(id),
      data: formData,
    }).unwrap();
    await fetchProject(data.id.toString()).unwrap();
  };

  const createReport = async () => {
    const doc = new jsPDF({
      format: [914, 610.01],
      unit: 'px',
      orientation: 'landscape',
      compress: true,
    });

    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = doc.internal.pageSize.getHeight();
    // First page is canvas with generated images
    const children = Array.from(reportRef.current.children).slice(
      1
    ) as HTMLDivElement[];

    for (let i = 0; i < children.length; i++) {
      const canvas = await html2canvas(children[i], {
        useCORS: true,
        scale: 3,
        ignoreElements: (element) => {
          return !(
            element.contains(children[i]) ||
            children[i].contains(element) ||
            children[i] === element ||
            element.nodeName == 'HEAD' ||
            element.nodeName == 'STYLE' ||
            element.nodeName == 'META' ||
            element.nodeName == 'LINK'
          );
        },
      });

      const imgData = canvas.toDataURL('image/png', 1);

      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      if (i !== children.length - 1) doc.addPage();
      const nextPercent = Math.round(((i + 1) / children.length) * 100);

      setPercent(nextPercent);
    }

    setPercent(100);

    return doc.output('blob');
  };

  const toggleProjectStatus = async (isLocked: boolean) => {
    try {
      await updateProject({
        id: Number(id),
        locked: isLocked,
      }).unwrap();
    } catch (error) {
      console.error('Failed to lock project:', error);
    }
  };

  const handleSetStatus = (status: ReportStatus) => {
    dispatch(
      setStatusReport({
        id,
        status,
      })
    );
  };

  const handleReset = () => {
    setPercent(0);
    toggleProjectStatus(false);
  };

  useEffect(() => {
    if (!data.locked) return handleSetStatus(ReportStatus.DEFAULT);

    const status = report.fileUrl
      ? ReportStatus.READY
      : report.imageProcessIds?.length
        ? ReportStatus.PROCESSING_AI_GENERATION
        : ReportStatus.ERROR;

    handleSetStatus(status);
  }, []);

  useEffect(() => {
    switch (statusReport) {
      case ReportStatus.DEFAULT:
      case ReportStatus.ERROR:
        handleReset();
        break;
      case ReportStatus.READY:
        setPercent(0);
        break;
      case ReportStatus.PROCESSING_AI_GENERATION:
        !data.locked && toggleProjectStatus(true);
        setIsOpen(true);
        setPercent(4);
        generateReport()
          .then(() => {
            handleSetStatus(ReportStatus.PROCESSING_DATA_COLLECTION);
          })
          .catch((error) => {
            dispatch(
              setErrorMessageReport({
                id,
                errorMessage: error.error_message ?? error.message,
              })
            );
            handleSetStatus(ReportStatus.ERROR);
          });
        break;
      case ReportStatus.PROCESSING_DATA_COLLECTION:
        !data.locked && toggleProjectStatus(true);
        break;
      case ReportStatus.PROCESSING_CREATE_REPORT:
        !data.locked && toggleProjectStatus(true);
        createReport()
          .then((blob) => {
            const generationTime = new Date().toISOString();
            setGenerationTime(generationTime);
            return handleSaveReport(blob, generationTime);
          })
          .then(() => handleSetStatus(ReportStatus.READY))
          .catch((error) => {
            dispatch(
              setErrorMessageReport({
                id,
                errorMessage: error.error_message ?? error.message,
              })
            );
            handleSetStatus(ReportStatus.ERROR);
          });
        break;
      default:
        break;
    }
  }, [statusReport]);

  return (
    <>
      {isOpen && (
        <>
          <WrapperModalContent
            generationTime={generationTime}
            data={data}
            percent={percent}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          {isReportAvailable && (
            <Report generatedImages={generatedImages} ref={reportRef} />
          )}
        </>
      )}
    </>
  );
};

export default ProjectModalReport;
