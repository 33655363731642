import { useEffect } from 'react';
import * as THREE from 'three';
import {
  CanvasActionsModes,
  DistanceInput,
  EditModes,
  FlatVector3,
} from '@/models';
import {
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { setEditedNode } from '@/store/slices/canvasBuildingSlice';
import { setEditMode, setMode } from '@/store/slices/canvasModesSlice';
import { subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__ESCAPE,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { getExtendedVector } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { MIN_WALL_DISTANCE } from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutTool';
import { round } from 'mathjs';

export const useSplitCutDirectionalInput = ({
  facePoints,
  setMousePosition,
  mousePosition,
  isAllowed,
}: {
  facePoints?: FlatVector3[];
  setMousePosition: (position: THREE.Vector3) => void;
  mousePosition: THREE.Vector3 | null;
  isAllowed: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const processingEntity = useAppSelector(getProcessingEntity);

  const handleInputLeftEdgeDistance = (distance: string) => {
    const firstPoint = convertFlatVector3ToVector(facePoints![1]);
    const secondPoint = convertFlatVector3ToVector(facePoints![0]);
    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = round(
      Number(convertMetersToMillimeters(maxDistance / multiplyRate)),
      2
    );

    const secondInputValue = (
      maxDistanceInMillimeters - Number(distance)
    ).toString();

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: distance,
          min: 1,
          max: maxDistanceInMillimeters,
          validationMessage: 'Invalid value',
        },
        {
          type: DistanceInput.RightEdgeDistance,
          value: secondInputValue,
        },
      ])
    );

    let leftDistance = convertMillimetersToMeters(distance) * multiplyRate;

    if (leftDistance > maxDistance) {
      leftDistance = maxDistance - MIN_WALL_DISTANCE;
    }
    if (leftDistance < 0) {
      leftDistance = 0;
    }

    const extendedVector = getExtendedVector(
      firstPoint,
      secondPoint,
      leftDistance
    );

    setMousePosition(extendedVector);
  };
  const handleInputRightEdgeDistance = (distance: string) => {
    const firstPoint = convertFlatVector3ToVector(facePoints![0]);
    const secondPoint = convertFlatVector3ToVector(facePoints![1]);

    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = round(
      Number(convertMetersToMillimeters(maxDistance / multiplyRate)),
      2
    );

    const secondInputValue = (
      maxDistanceInMillimeters - Number(distance)
    ).toString();

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: distance,
          min: 1,
          max: maxDistanceInMillimeters,
          validationMessage: 'Invalid value',
        },
        {
          type: DistanceInput.LeftEdgeDistance,
          value: secondInputValue,
        },
      ])
    );

    let rightDistance = convertMillimetersToMeters(distance) * multiplyRate;

    if (rightDistance > maxDistance) {
      rightDistance = maxDistance - MIN_WALL_DISTANCE;
    }
    if (rightDistance < 0) {
      rightDistance = 0;
    }

    const extendedVector = getExtendedVector(
      firstPoint,
      secondPoint,
      rightDistance
    );

    setMousePosition(extendedVector);
  };

  const onInputUpdate = (evt: CustomEvent) => {
    if (!facePoints || !mousePosition) return;

    switch (processingEntity?.type) {
      case DistanceInput.LeftEdgeDistance: {
        handleInputLeftEdgeDistance(evt.detail);
        break;
      }
      case DistanceInput.RightEdgeDistance: {
        handleInputRightEdgeDistance(evt.detail);
        break;
      }
      default:
        break;
    }
  };

  const onEscape = () => {
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          processing: true,
        },
        {
          type: DistanceInput.RightEdgeDistance,
          processing: true,
        },
      ])
    );
  };

  const updateDirectionalInputValues = (
    leftDistance: number,
    rightDistance: number
  ) => {
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          processing: true,
          value: convertMetersToMillimeters(
            leftDistance / multiplyRate
          ).toString(),
        },
        {
          type: DistanceInput.RightEdgeDistance,
          processing: true,
          value: convertMetersToMillimeters(
            rightDistance / multiplyRate
          ).toString(),
        },
      ])
    );
  };

  useEffect(() => {
    if (processingEntity?.active) return;

    if (!mousePosition || !facePoints) return;

    if (!isAllowed) {
      updateDirectionalInputValues(0, 0);
      return;
    }

    const rightDistance = convertFlatVector3ToVector([
      facePoints[0][0],
      0,
      facePoints[0][2],
    ]).distanceTo(mousePosition.clone().setY(0));

    const leftDistance = convertFlatVector3ToVector([
      facePoints[1][0],
      0,
      facePoints[1][2],
    ]).distanceTo(mousePosition.clone().setY(0));

    updateDirectionalInputValues(leftDistance, rightDistance);
  }, [mousePosition, facePoints, isAllowed]);

  const resetMode = () => {
    dispatch(setEditedNode(undefined));
    dispatch(setEditMode(EditModes.Unset));
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(resetExternalElementsState());
    dispatch(
      setDirectionalInputValues([
        { type: DistanceInput.Distance, processing: true },
      ])
    );
  };

  useEffect(() => {
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    subscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
      unsubscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    };
  }, [processingEntity, mousePosition]);

  useEffect(() => {
    dispatch(setShowDirectionalInput({ isShow: true }));
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.LeftEdgeDistance,
          display: true,
          processing: true,
          value: '0',
        },
        {
          type: DistanceInput.RightEdgeDistance,
          display: true,
          processing: true,
          value: '0',
        },
      ])
    );
    return () => {
      resetMode();
    };
  }, []);
};
