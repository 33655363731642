import React from 'react';

import FacadeDesignerGridLine from '@/components/FacadeDesigner/elements/FacadeDesignerGridLine';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { UnitSystemTypes } from '@/models';
import {
  setSelectedGridLines,
  getSelectedGridlines,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { round } from 'mathjs';

interface FacadeDesignerGridLinesProps {
  scale: number;
  unitSystem: UnitSystemTypes;
  wallHeight: number;
  wallWidth: number;
  wallData: WallSearchResults;
  xWallOffset: number;
  reportView?: boolean;
}

const FacadeDesignerGridLines = ({
  scale,
  unitSystem,
  wallHeight,
  wallWidth,
  wallData,
  xWallOffset,
  reportView,
}: FacadeDesignerGridLinesProps) => {
  const dispatch = useAppDispatch();
  const { updateBuildingPlacements } = useUpdateUserBuildingData();
  const { findDataForWall } = useFindNodeData();
  const selectedGridLines = useAppSelector(getSelectedGridlines);
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data!.buildings!;

  const buildingGUID = userBuildings[0]!.guid;
  const handleMeasurementSubmit = () => {
    dispatch(setSelectedGridLines([]));
    updateBuildingPlacements({
      buildingGUID,
      placementData: selectedGridLines.map((selectedGridLine) => {
        const relatedWallData = findDataForWall(selectedGridLine.wallGUID)!;
        return {
          wallGUID: relatedWallData.guid,
          wallPanels: [],
        };
      }),
    });
  };
  const handleMeasurementChange = (offsetDiff: number) => {
    updateBuildingPlacements({
      buildingGUID,
      placementData: selectedGridLines.map((selectedGridLine) => {
        const relatedWallData = findDataForWall(selectedGridLine.wallGUID)!;
        return {
          wallGUID: relatedWallData.guid,
          wallPanels: relatedWallData.wallPanels,
          gridLines: relatedWallData.gridLines.map((wallGridLine) => {
            return wallGridLine.guid === selectedGridLine.guid
              ? {
                  ...wallGridLine,
                  offsetFromLeftEdge: round(
                    selectedGridLine.offsetFromLeftEdge + offsetDiff,
                    2
                  ),
                }
              : wallGridLine;
          }),
        };
      }),
      triggerBEUpdate: false,
    });
  };
  const handleMeasurementEscape = () => {
    dispatch(setSelectedGridLines([]));
    updateBuildingPlacements({
      buildingGUID: userBuildings[0].guid,
      placementData: selectedGridLines.map((selectedGridLine) => {
        const relatedWallData = findDataForWall(selectedGridLine.wallGUID)!;
        return {
          wallGUID: relatedWallData.guid,
          wallPanels: relatedWallData.wallPanels,
          wallPanelErrors: relatedWallData.wallPanelErrors,
          gridLines: relatedWallData.gridLines.map((wallGridLine) => {
            return {
              guid: wallGridLine.guid,
              cornerAlign: wallGridLine.cornerAlign,
              offsetFromLeftEdge:
                wallGridLine.guid === selectedGridLine.guid
                  ? selectedGridLine.offsetFromLeftEdge
                  : wallGridLine.offsetFromLeftEdge,
            };
          }),
        };
      }),
      triggerBEUpdate: false,
    });
  };
  return (
    <>
      {wallData.gridLines.map((gridLine, idx) => (
        <FacadeDesignerGridLine
          key={idx}
          gridLine={gridLine}
          scale={scale}
          unitSystem={unitSystem}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          wallOffset={xWallOffset}
          wallData={wallData}
          onMeasurementSubmit={handleMeasurementSubmit}
          onMeasurementEscape={handleMeasurementEscape}
          onMeasurementChange={handleMeasurementChange}
          reportView={reportView}
        />
      ))}
    </>
  );
};

export default FacadeDesignerGridLines;
