import React, { useMemo } from 'react';
import { DockRow } from '@/shared/icons/DockRow';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { calculateCommonArea } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import IguConfiguratorView from '@/shared/elements/IguConfiguratorView';
import { compact } from 'lodash';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes } from '@/models';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';

interface WindowsMetricsProps {
  windowIds: number[];
}

const WindowsMetrics: React.FC<WindowsMetricsProps> = ({ windowIds }) => {
  const { id } = useParams();
  const windowsInLibrary = useFetchWindowsQuery(id!).data!;

  const isImperialUnits =
    useAppSelector(getProjectUnits(id!)) === UnitSystemTypes.Imperial;

  const { getAreaUnit } = useFrameProperties();

  const windowsData = useMemo(
    () =>
      compact(
        windowIds.map((id) =>
          windowsInLibrary.find((window) => window.id === id)
        )
      ),
    [windowsInLibrary, windowIds]
  );

  return (
    <div className="text-xs text-dark-gray-100 pb-3 border border-l-0 border-r-0 border-b-0 border-solid border-light-gray-20 border-box">
      <div className="flex gap-1 mb-2 px-3 pt-3">
        <DockRow />
        <div className="font-medium leading-5">WINDOWS</div>
      </div>
      <PropertyList
        removeBottomMargin
        properties={[
          {
            name: 'Count',
            value: windowsData.length.toString(),
            isDisabled: false,
            isEditable: false,
          },
          {
            name: 'IGU area',
            value: calculateCommonArea({
              nodes: windowsData,
              isImperialUnits,
              calculateAreaFunction: (window) =>
                window.innerFrames.reduce(
                  (sum, window) => (window?.iguArea ?? 0) + sum,
                  0
                ),
            }),
            isDisabled: false,
            isEditable: false,
            units: getAreaUnit(),
          },
        ]}
      />
      <IguConfiguratorView
        iguIds={compact(
          windowsData.flatMap((window) =>
            window.innerFrames?.flatMap((innerWindow) => innerWindow.iguId)
          )
        )}
      />
    </div>
  );
};

export default WindowsMetrics;
