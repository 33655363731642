import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { NodeType, UserBuildingWall } from '@/models';
import Konva from 'konva';
import { FACADE_VIEW } from '@/shared/constants';
import FacadeDesignerContainer from '../FacadeDesigner/FacadeDesignerContainer';
import { sleep } from '@/shared/helpers/sleep';
import { useAppDispatch } from '@/store/hooks';
import {
  setQuoteRequestStatus,
  setQuoteRequestErrorMessage,
} from '@/store/slices/quoteSlice';
import { QuoteRequestData, QuoteStatus } from '@/models/quote.model';
import { useSendDataForQuoteMutation } from '@/store/apis/blueScreenApi';
import { Side } from '@/models/camera.model';
import { Color } from 'three';
import { useAIGeneration } from '@/shared/hooks/useAIGeneration';
import { blobToBase64 } from '@/shared/helpers/format-data';
import axios from 'axios';
import {
  COVER_HEIGHT,
  COVER_WIDTH,
  REPORT_BACKGROUND_COLOR,
} from '@/models/report';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { chunk } from 'lodash';
import QuoteWrapper from './Pages/QuoteWrapper';
import QuoteFacades from './Pages/QuoteFacades/QuoteFacades';

interface QuoteProps {
  setPercent: (value: number) => void;
}

interface FacadeSnapshot {
  url: string;
  index: number;
}

export const Quote: React.FC<QuoteProps> = ({ setPercent }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { data: fetchedPanels } = useGetAllPanelsQuery(id!);
  const projectData = useFetchProjectQuery(id!).data!;
  const [sendDataForQuote] = useSendDataForQuoteMutation();
  const { facadesData } = useFacadeData();
  const [showPanelCellNumbers, setShowPanelCellNumbers] = useState(false);

  const [facadeData, setFacadeData] = useState<UserBuildingWall[]>(
    facadesData[0]
  );
  const [konvaStage, setKonvaStage] = useState<Konva.Stage>(null!);
  const [chunksSnapshot, setChunksSnapshot] = useState<FacadeSnapshot[][]>([]);

  const { getProcessData, checkProgress } = useAIGeneration();

  const handleStageInit = (stage: Konva.Stage) => setKonvaStage(stage);

  const reportRef = useRef<HTMLDivElement>(null!);

  const generateAiImages = async () => {
    const taskIds = await getProcessData(
      [
        {
          side: Side.AI,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
      ],
      [
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, day time, summer',
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, night, summer',
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, day time, winter',
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, night, winter',
      ]
    ).then((data) =>
      data.map((process) => {
        if (!process.success) throw new Error(process.error_message);

        return process.data.task_id;
      })
    );

    if (!taskIds.length) throw new Error('No ID generated for AI cover');

    const images = await Promise.all(
      taskIds.map(async (taskId) => await checkProgress(taskId))
    );

    return await Promise.all(
      images.map((imageUrl) =>
        fetch(imageUrl)
          .then((res) => res.blob())
          .then(blobToBase64)
      )
    );
  };

  const createReport = async () => {
    const doc = new jsPDF({
      format: [914, 610.01],
      unit: 'px',
      orientation: 'landscape',
      compress: true,
    });

    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = doc.internal.pageSize.getHeight();

    const children = Array.from(reportRef.current.children).slice(
      1
    ) as HTMLDivElement[];

    for (let i = 0; i < children.length; i++) {
      const canvas = await html2canvas(children[i], {
        useCORS: true,
        scale: 3,
        ignoreElements: (element) => {
          return !(
            element.contains(children[i]) ||
            children[i].contains(element) ||
            children[i] === element ||
            element.nodeName == 'HEAD' ||
            element.nodeName == 'STYLE' ||
            element.nodeName == 'META' ||
            element.nodeName == 'LINK'
          );
        },
      });

      const imgData = canvas.toDataURL('image/png', 1);

      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      if (i !== children.length - 1) doc.addPage();
    }

    return doc.output('blob');
  };

  const makeSnapshots = async () => {
    setPercent(10);
    try {
      if (!konvaStage || !facadesData) return;
      const panelSnapshots: { [panelId: string]: string } = {};
      const facadeSnapshots: FacadeSnapshot[] = [];

      for (let i = 0; i < facadesData.length; i++) {
        setPercent(15 + i * 3);

        const facadeHasPanels = facadesData[i].filter(
          (wall) => wall.wallPanels.length > 0
        );

        if (!facadeHasPanels.length) {
          continue;
        }

        setFacadeData(facadeHasPanels);
        setShowPanelCellNumbers(true);

        await sleep(100);

        const targetNode = konvaStage.find(`#${FACADE_VIEW}`)[0];
        targetNode &&
          (await targetNode.toImage({
            pixelRatio: 2,
            callback: (img) => {
              facadeSnapshots.push({
                url: img.src,
                index: facadeSnapshots.length + 1,
              });
            },
          }));

        setShowPanelCellNumbers(true);
        await sleep(100);

        for (const panel of fetchedPanels!) {
          if (panelSnapshots[panel.id]) continue;
          const panelNode = konvaStage.find(`#panel-${panel.id}`)[0];
          panelNode &&
            (await panelNode.toImage({
              pixelRatio: 5,
              callback: (img) => {
                panelSnapshots[panel.id] = img.src;
              },
            }));
        }
      }

      setChunksSnapshot(chunk(facadeSnapshots, 4));

      const generatedAiImages = (await generateAiImages()) as string[];
      const cover = await cropCover(generatedAiImages[0]);

      setPercent(15 + facadesData.length * 3 + 5);

      const takeoff = (await createReport().then((blob) =>
        blobToBase64(blob)
      )) as string;

      setPercent(98);

      if (facadeSnapshots && panelSnapshots) {
        const data: QuoteRequestData = {
          panelImages: panelSnapshots,
          buildingImages: generatedAiImages,
          coverImage: cover,
          takeoff,
        };
        const draftId = projectData.draftId!.toString();

        await sendDataForQuote({ draftId, data }).unwrap();
      }

      await sleep(1000);

      dispatch(setQuoteRequestStatus(QuoteStatus.READY));
    } catch (error) {
      const errorMessage =
        (axios.isAxiosError(error) &&
          (error.response?.data?.error_message || error.message)) ||
        (error instanceof Error && error.message) ||
        (error as { error?: string }).error;

      dispatch(setQuoteRequestErrorMessage(errorMessage));
      dispatch(setQuoteRequestStatus(QuoteStatus.ERROR));
    }
  };

  const cropCover = async (img: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const imgElement = document.createElement('img');
      imgElement.src = img;

      imgElement.onload = () => {
        const targetWidth = COVER_WIDTH;
        const targetHeight = COVER_HEIGHT;

        const imgWidth = imgElement.width;
        const imgHeight = imgElement.height;

        const scale = Math.max(
          targetWidth / imgWidth,
          targetHeight / imgHeight
        );

        const scaledWidth = imgWidth * scale;
        const scaledHeight = imgHeight * scale;

        const offsetX = (targetWidth - scaledWidth) / 2;
        const offsetY = (targetHeight - scaledHeight) / 2;

        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        const ctx = canvas.getContext('2d')!;
        ctx.fillStyle = REPORT_BACKGROUND_COLOR;
        ctx.fillRect(0, 0, targetWidth, targetHeight);

        ctx.drawImage(imgElement, offsetX, offsetY, scaledWidth, scaledHeight);

        const imageURL = canvas.toDataURL('image/png', 1);

        canvas.remove();
        imgElement.remove();

        resolve(imageURL);
      };

      imgElement.onerror = () => {
        reject(new Error('Error when cropping the image'));
      };
    });
  };

  useEffect(() => {
    konvaStage && makeSnapshots();
  }, [konvaStage]);

  return createPortal(
    <div
      className={`left-0 right-0 top-0 mr-auto ml-auto w-fit z-[-9999] fixed bg-light-gray-20`}
      ref={reportRef}
    >
      <div className="absolute h-full w-full -z-10  hidden">
        {facadeData.length && (
          <FacadeDesignerContainer
            buildingGUID={projectData.buildings![0].guid}
            selectedWalls={facadeData.map((wall) => ({
              guid: wall.guid,
              type: NodeType.Wall,
            }))}
            placementHeight={window.innerHeight}
            placementWidth={window.innerWidth}
            onKonvaInit={handleStageInit}
            reportView
            showPanelCellNumbers={showPanelCellNumbers}
          />
        )}
      </div>
      {chunksSnapshot.map((snapshots, index) => (
        <QuoteWrapper key={index}>
          <QuoteFacades snapshots={snapshots} />
        </QuoteWrapper>
      ))}
    </div>,
    document.body
  );
};
