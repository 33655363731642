import React, { useMemo } from 'react';

import {
  MetricLimits,
  NodeType,
  UserBuildingBlock,
  UserBuildingStorey,
} from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useUpdateUserBuildingBlockMutation } from '@/store/apis/projectsApi';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useAppSelector } from '@/store/hooks';
import { getIsEditToolsAvailable } from '@/store/slices/canvasBuildingSlice';
import PanelMetrics from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/PanelMetrics';
import WindowsMetrics from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/WindowsMetrics';

interface StoreyFrameProps {
  storeysGUID: string[];
  isProjectLocked: boolean;
}

const StoreyFrame: React.FC<StoreyFrameProps> = ({
  storeysGUID,
  isProjectLocked,
}) => {
  const { findDataForStorey, findDataForBlock } = useFindNodeData();
  const [updateUserBuildingBlock] = useUpdateUserBuildingBlockMutation();

  const selectedStoreysData = storeysGUID.map(
    (storeyGUID) => findDataForStorey(storeyGUID)!
  );

  const buildingGUID = selectedStoreysData[0]?.parentNodes.find(
    (node) => node.type === NodeType.Building
  )?.guid;

  const isEditToolsAvailable = useAppSelector(
    getIsEditToolsAvailable(buildingGUID || '')
  );
  const isEditable = !isProjectLocked && isEditToolsAvailable;

  const userBuildingBlocks = Array.from(
    new Set(
      selectedStoreysData.map(
        (storey) =>
          findDataForBlock(
            storey!.parentNodes.find((node) => node.type === NodeType.Block)!
              .guid
          )!
      )
    )
  );

  const userBuildingUtils = useUpdateUserBuildingData();

  const {
    getFloorHeightMetric,
    getFacadesAreaMetricForStoreys,
    getGrossInternalAreaMetricForStoreys,
    updateFloorHeightForSelectedStoreyInBlock,
  } = useFrameProperties();

  const getStoreyName = (storey: UserBuildingStorey) => {
    return storey?.name ?? `Floor ${storey?.storeyNumber}`;
  };

  const changeFloorHeightMetric = (value: string) => {
    userBuildingBlocks.forEach((userBuildingBlock) => {
      let updatedBlock: UserBuildingBlock = userBuildingBlock;
      selectedStoreysData.forEach((storey) => {
        updatedBlock = updateFloorHeightForSelectedStoreyInBlock(
          value,
          storey.storeyNumber,
          updatedBlock
        );
      });
      userBuildingUtils.updateUserBuildingBlockStoreys({
        blockGUID: userBuildingBlock.guid,
        updatedBlock: updatedBlock,
      });
    });
  };

  const handleSubmit = () => {
    userBuildingBlocks.forEach((userBuildingBlock) => {
      updateUserBuildingBlock({
        data: userBuildingBlock,
      });
    });
  };

  const panelsPlacementData = useMemo(
    () =>
      selectedStoreysData.flatMap((storey) =>
        storey.walls.flatMap((wall) => wall.wallPanels)
      ),
    [selectedStoreysData]
  );

  const windowPlacementIds = useMemo(
    () =>
      selectedStoreysData.flatMap((storey) =>
        storey.walls.flatMap((wall) =>
          wall.windowPlacements.flatMap((window) => window.windowId)
        )
      ),
    [selectedStoreysData]
  );
  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box border-solid border border-t-0 border-x-0 border-light-gray-20">
        <span className="whitespace-nowrap text-ellipsis w-full">
          {selectedStoreysData.length > 1
            ? `Floor (${selectedStoreysData.length})`
            : getStoreyName(selectedStoreysData[0])}
        </span>
      </div>
      <div className={'overflow-y-auto'}>
        <div
          className={
            'flex flex-col text-xs border border-x-0 border-t-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
          }
        >
          <MetricsHeader />
          <PropertyList
            key={storeysGUID.join()}
            alignValueLeft
            properties={[
              {
                name: 'Height',
                value: getFloorHeightMetric(selectedStoreysData),
                isEditable: isEditable,
                onEdit: changeFloorHeightMetric,
                onSubmit: handleSubmit,
                min: MetricLimits.FloorHeightMin,
                max: MetricLimits.FloorHeightMax,
              },
            ]}
          />
        </div>

        <TotalSurface
          facadesArea={getFacadesAreaMetricForStoreys(selectedStoreysData)}
          grossInternalArea={getGrossInternalAreaMetricForStoreys(
            selectedStoreysData
          )}
        />

        {panelsPlacementData.length > 0 && (
          <PanelMetrics panelsPlacementData={panelsPlacementData} />
        )}
        {windowPlacementIds.length > 0 && (
          <WindowsMetrics windowIds={windowPlacementIds} />
        )}
      </div>
    </>
  );
};

export default StoreyFrame;
