import React, { ReactNode } from 'react';
import {
  OperationType,
  OperationTypeDescription,
  PanelMaterial,
  WindowColor,
} from '@/models/window-configurator.model';
import FixedOperationTypeIcon from '../icons/OperationTypes/FixedIcon';
import DualActionRightTopIcon from '../icons/OperationTypes/DualActionRightTopIcon';
import DualActionLeftTopIcon from '../icons/OperationTypes/DualActionLeftTopIcon';
import CasementLeftIcon from '../icons/OperationTypes/CasementLeftIcon';
import CasementRightIcon from '../icons/OperationTypes/CasementRightIcon';
import AwningIcon from '../icons/OperationTypes/AwningIcon';
import HopperIcon from '../icons/OperationTypes/Hopper';
import aluminumMaterial from '@/images/aluminiumMaterial.svg';
import enamelGlassMaterial from '@/images/enamelGlass.svg';
import glassMaterial from '@/images/glassMaterial.svg';

export const getHexByName = (colors: WindowColor[], name: string) => {
  return colors.find((color) => color.name === name)?.hex;
};

export const getColorDescriptionByName = (
  colors: WindowColor[],
  name: string
) => {
  return colors.find((color) => color.name === name)?.description;
};

export const getOperationTypeData = (
  operationType: OperationType,
  operationTypes: OperationTypeDescription[]
) => {
  return operationTypes.find((operation) => operation.name === operationType);
};

export const getOperationTypeIcon = (
  operationType: OperationType,
  size: string = '40'
) => {
  switch (operationType) {
    case OperationType.Fixed:
      return <FixedOperationTypeIcon size={size} />;
    case OperationType.DualActionRightTop:
      return <DualActionRightTopIcon size={size} />;
    case OperationType.DualActionLeftTop:
      return <DualActionLeftTopIcon size={size} />;
    case OperationType.CasementLeft:
      return <CasementLeftIcon size={size} />;
    case OperationType.CasementRight:
      return <CasementRightIcon size={size} />;
    case OperationType.Awning:
      return <AwningIcon size={size} />;
    case OperationType.Hopper:
      return <HopperIcon size={size} />;
  }
};

export const getMaterialAdditionalInfo = (
  materialName: string,
  materials: PanelMaterial[]
) => {
  return materials.find((material) => material.name === materialName)
    ?.additionalInfo;
};

export const getColorData = (colorId: string, colors: WindowColor[]) => {
  return colors.find((color) => color.name === colorId);
};

export const getMaterialImage = (materialName: string): ReactNode => {
  switch (materialName) {
    case 'Aluminium':
      return <img src={aluminumMaterial} alt="Aluminium" />;
    case 'EnamelGlass':
      return <img src={enamelGlassMaterial} alt="Enamel Glass" />;
    case 'Glass':
      return <img src={glassMaterial} alt="Glass" />;

    default:
      return <img src={aluminumMaterial} alt="Aluminium" />;
  }
};

export const getMaterialDescription = (
  materialName: string,
  materials: PanelMaterial[]
): string | undefined => {
  return materials.find((material) => material.name === materialName)
    ?.description;
};
