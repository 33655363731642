import React, { useEffect, useMemo, useState } from 'react';
import IntusModal from '../IntusModal/IntusModal';
import IntusSearch from '../Search/Search';
import IntusButton from '../Button/Button';
import {
  useFetchIguQuery,
  useLazyFilterIguQuery,
} from '@/store/apis/webCalcApi';
import { highlightTextAfterSearch } from '@/shared/helpers/format-data';
import {
  Coating,
  GlassFilter,
  GlassType,
  IGU,
  IGUFilterGroups,
  IguFilters,
  Spacer,
} from '@/models/webcalc';
import IntusInput from '../Input/Input';
import DownIcon from '@/shared/icons/DownIcon';
import { getIGUFilterLabel, getIGULabel } from '@/shared/helpers/Igu';
import IntusPopover from '../Popover/Popover';
import IntusCheckbox from '../Checkbox/IntusCheckbox';
import { inRange } from 'lodash';
import IntusLoader from '../IntusLoader/IntusLoader';
import UpIcon from '@/shared/icons/UpIcon';
import SearchEmptyIcon from '@/shared/icons/SearchEmptyIcon';

interface IguSelectProps {
  value?: number | null;
  onSelect: (value: number) => void;
  disabled?: boolean;
  isIguForPanel?: boolean;
  materialIsGlass?: boolean;
}

interface IguFiltersProps {
  filters: IguFilters;
  setFilters: (filters: IguFilters) => void;
  filterGroupName: IGUFilterGroups;
}

const IguSelect: React.FC<IguSelectProps> = ({
  value,
  onSelect,
  disabled,
  isIguForPanel,
  materialIsGlass,
}) => {
  const IGUData = useFetchIguQuery().data!;
  const [filterIGUItems, { data: IGUFilteredData, isLoading, isFetching }] =
    useLazyFilterIguQuery();

  const filterIGUData = (iguData: IGU[]) => {
    if (!isIguForPanel) return iguData;

    return iguData.filter((val) =>
      materialIsGlass ? !val.name.includes('_EN') : val.name.includes('_EN')
    );
  };

  const IGUOptions = useMemo(() => {
    const filteredData = filterIGUData(IGUData);

    return filteredData.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [IGUData, materialIsGlass, isIguForPanel]);

  const [availableItems, setAvailableItems] = useState(IGUOptions);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const initialFiltersState = {
    spacers: {
      [Spacer.PHI]: false,
      [Spacer.PHIUS]: false,
      [Spacer.Warm]: false,
    },
    coatings: {
      [Coating.NoCoating]: false,
      [Coating.AvisafeSuncool7040]: false,
      [Coating.Bird1stEtch]: false,
      [Coating.Bird1stUVSNX5123]: false,
      [Coating.Bird1stUVSNX6272]: false,
      [Coating.CG]: false,
      [Coating.NU]: false,
      [Coating.Prem]: false,
      [Coating.SN51]: false,
    },
    glassTypes: {
      [GlassType.AN]: false,
      [GlassType.HS]: false,
      [GlassType.HST]: false,
      [GlassType.T]: false,
    },
    glassFilters: {
      [GlassFilter.BirdSafe]: false,
      [GlassFilter.Enamel]: false,
      [GlassFilter.Regular]: false,
    },
  };
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState<IguFilters>(initialFiltersState);
  const [activeItem, setActiveItem] = useState(value ?? IGUOptions[0]?.value);

  const label = disabled
    ? '-'
    : IGUOptions?.find(
        (option) => option.value === (value ?? IGUOptions[0]?.value)
      )?.label;

  const getActiveFilters = () => {
    const activeFilters: Record<string, string[]> = {};

    Object.entries(filters).forEach(([key, value]) => {
      const activeKeys = Object.keys(value).filter(
        (activeKey) => value[activeKey]
      );
      if (activeKeys.length) activeFilters[key] = activeKeys;
    });
    return activeFilters;
  };

  const isSomeFilterIsApplied = useMemo(() => {
    const activeFilters = getActiveFilters();
    return !!Object.values(activeFilters).flat().length;
  }, [filters]);

  const handleSearch = () => {
    const formattedSearchValue = searchValue.toLowerCase().trim();
    let searchSource = IGUOptions;
    if (isSomeFilterIsApplied && IGUFilteredData) {
      searchSource = filterIGUData(IGUFilteredData).map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }

    if (formattedSearchValue === '') {
      setAvailableItems(searchSource);
      return;
    }

    const filteredItems = searchSource.filter(
      (item) =>
        item.label.toLowerCase().includes(formattedSearchValue) ||
        item.value.toString().toLowerCase().includes(formattedSearchValue)
    );

    setAvailableItems(filteredItems);
  };

  useEffect(() => {
    handleSearch();
  }, [searchValue, isSomeFilterIsApplied, IGUFilteredData]);

  useEffect(() => {
    const fetchFilteredItems = async () => {
      const activeFilters = getActiveFilters();
      const iguData = await filterIGUItems(activeFilters).unwrap();
      setAvailableItems(
        filterIGUData(iguData).map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    };

    isSomeFilterIsApplied
      ? fetchFilteredItems()
      : setAvailableItems(IGUOptions);
  }, [filters, isSomeFilterIsApplied, IGUOptions, filterIGUItems]);

  const applyChanges = () => {
    onSelect(activeItem);
    setModalIsOpen(false);
  };

  const openIguModal = () => {
    if (disabled) return;
    setModalIsOpen(true);
  };

  const resetIguConfigurations = () => {
    setSearchValue('');
    setFilters(initialFiltersState);
    setAvailableItems(IGUOptions);
  };

  return (
    <>
      <div
        onClick={openIguModal}
        className={`py-1 px-3 text-xs leading-5 hover:bg-light-gray-10 ${
          disabled ? 'opacity-50' : 'opacity-100'
        }
        ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
        `}
      >
        {label ?? '-'}
      </div>
      <IntusModal
        styles={{ body: { padding: 0 } }}
        footer={null}
        open={modalIsOpen}
        centered
        closable={true}
        onCancel={() => setModalIsOpen(false)}
        keyboard={false}
        width={900}
        destroyOnClose
        afterClose={resetIguConfigurations}
      >
        <IntusLoader loading={isLoading || isFetching}>
          <div className="h-[600px] relative select-none">
            <div className="h-[46px] box-border pl-8 py-3 text-sm font-medium border-0 border-b border-solid border-light-gray-20">
              IGU configuration
            </div>
            <div className="px-8 h-20 pt-6 box-border flex gap-6">
              <div className="flex-shrink-0">
                <IntusSearch
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-1 items-end">
                <div className="relative flex-1 flex gap-2">
                  <IGUFilter
                    filters={filters}
                    setFilters={setFilters}
                    filterGroupName={IGUFilterGroups.Spacers}
                  />
                  <IGUFilter
                    filters={filters}
                    setFilters={setFilters}
                    filterGroupName={IGUFilterGroups.Coatings}
                  />
                  <IGUFilter
                    filters={filters}
                    setFilters={setFilters}
                    filterGroupName={IGUFilterGroups.GlassTypes}
                  />
                  <IGUFilter
                    filters={filters}
                    setFilters={setFilters}
                    filterGroupName={IGUFilterGroups.GlassFilters}
                  />
                </div>

                <IntusButton
                  id="IGUSelect_clearFilters"
                  type="text"
                  className={` text-dark-green-100 hover:!text-dark-green-100 border-0 !leading-5 ${
                    // With display none the popover is shifted to the bottom
                    isSomeFilterIsApplied
                      ? 'opacity-100'
                      : 'opacity-0 pointer-events-none'
                  }`}
                  onClick={resetIguConfigurations}
                >
                  Clear filters
                </IntusButton>
              </div>
            </div>
            {availableItems.length > 0 ? (
              <>
                <div className="px-8 h-8 flex items-center text-xs border-0 border-b border-solid border-light-gray-20  text-dark-gray-100 font-light leading-5">
                  <div className="px-2 w-14 box-border uppercase">ID</div>
                  <div className="flex-1 px-2">Name</div>
                </div>
                <div className="h-[386px] overflow-y-auto border-0 border-b border-solid border-light-gray-20">
                  {availableItems?.map((option) => {
                    return (
                      <div
                        onClick={() => setActiveItem(option.value)}
                        className={`box-border ${activeItem === option.value ? 'bg-[#E8F5E580]' : ''} flex py-3 px-8 h-[69px] text-sm leading-[22px] cursor-pointer hover:bg-light-gray-10 border-t border-0 border-solid border-light-gray-10`}
                        key={option.value}
                      >
                        <div className="w-14 px-2">
                          {highlightTextAfterSearch(
                            option.value.toString(),
                            searchValue
                          )}
                        </div>
                        <div className="flex justify-start flex-1">
                          {highlightTextAfterSearch(option.label, searchValue)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="h-[418px] w-full flex flex-col gap-6 justify-center items-center border-0 border-b border-solid border-light-gray-20">
                <SearchEmptyIcon />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-1 text-xs text-center leading-[21px]">
                    <div className="font-medium">No results found</div>
                    <div className="font-normal">
                      Try to adjust your filters
                    </div>
                  </div>
                  <div className="w-full flex justify-center">
                    <IntusButton
                      id="IGUSelect_clearFilters_noResults"
                      extraStyles="rounded-lg px-3 py-[5px] leading-[22px]"
                      type="default"
                      borderless={false}
                      outline={true}
                      onClick={resetIguConfigurations}
                    >
                      Clear filters
                    </IntusButton>
                  </div>
                </div>
              </div>
            )}
            <div className="border-0 flex justify-end py-3 px-8">
              <IntusButton
                id="IGUSelect_applyChanges"
                onClick={applyChanges}
                className="text-white"
                disabled={!activeItem}
              >
                Apply
              </IntusButton>
            </div>
          </div>
        </IntusLoader>
      </IntusModal>
    </>
  );
};

const IGUFilter: React.FC<IguFiltersProps> = ({
  filters,
  setFilters,
  filterGroupName,
}) => {
  const [inputValue, setInputValue] = useState('');
  const label = getIGUFilterLabel(filterGroupName);
  const filterOptions = filters[filterGroupName];
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const { totalFilters, activeFilters, indeterminate } = useMemo(() => {
    const filterValues = Object.values(filterOptions);
    const activeFilters = filterValues.filter(Boolean).length;
    return {
      totalFilters: filterValues.length,
      activeFilters,
      indeterminate: inRange(activeFilters, 1, filterValues.length),
    };
  }, [filterOptions]);

  const updateAllFilters = (checked: boolean) => {
    setFilters({
      ...filters,
      [filterGroupName]: Object.fromEntries(
        Object.keys(filterOptions).map((key) => [key, checked])
      ),
    });
  };

  const updateSingleFilter = (key: string, checked: boolean) => {
    setFilters({
      ...filters,
      [filterGroupName]: {
        ...filterOptions,
        [key]: checked,
      },
    });
  };

  useEffect(() => {
    setInputValue(activeFilters > 0 ? `${label} (${activeFilters})` : '');
  }, [activeFilters, label]);

  const popoverContent = (
    <div className="py-1 flex flex-col min-w-[201px]">
      <div className="h-8 box-border py-[5px] px-3">
        <IntusCheckbox
          checked={totalFilters === activeFilters}
          indeterminate={indeterminate}
          onChange={(e) => updateAllFilters(e.target.checked)}
        >
          <span className="select-none leading-[22px] text-sm font-medium">
            All
          </span>
        </IntusCheckbox>
      </div>
      {Object.entries(filterOptions)
        .sort(([keyA], [keyB]) => {
          const labelA = getIGULabel(filterGroupName, Number(keyA));
          const labelB = getIGULabel(filterGroupName, Number(keyB));

          if (labelA === 'No Coating') return -1;
          if (labelB === 'No Coating') return 1;

          return labelA.localeCompare(labelB);
        })
        .map(([key, isChecked]) => (
          <div className="h-8 box-border py-[5px] px-3" key={key}>
            <IntusCheckbox
              checked={isChecked}
              onChange={(e) => updateSingleFilter(key, e.target.checked)}
            >
              <span className="select-none leading-[22px] text-sm">
                {getIGULabel(filterGroupName, Number(key))}
              </span>
            </IntusCheckbox>
          </div>
        ))}
    </div>
  );

  return (
    <IntusPopover
      content={popoverContent}
      arrow={false}
      placement="bottom"
      trigger="click"
      onOpenChange={(visible) => setPopoverIsOpen(visible)}
    >
      <div className="flex-1">
        <IntusInput
          suffix={popoverIsOpen ? <UpIcon /> : <DownIcon />}
          value={inputValue}
          readOnly
          placeholder={label}
          className="intus-input-clickable"
        />
      </div>
    </IntusPopover>
  );
};

export default IguSelect;
